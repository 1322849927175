@use 'includes/colors';

.svg-skill-process {
  $animation-iteration-count: infinite;
  $animation-duration: 3s;

  .cards-small-list {
    .card-small-1,
    .card-small-2,
    .card-small-3 {
      animation-iteration-count: $animation-iteration-count;
      animation-duration: $animation-duration;
    }

    .card-small-1 {
      opacity: 1;
      filter: grayscale(0%);
      animation-name: card-small-blink;
    }

    .card-small-2 {
      transform: translateY(-29px);
      opacity: 0;
      animation-name: card-small-2;
    }

    .card-small-3 {
      transform: translateY(-58px);
      opacity: 0;
      animation-name: card-small-3;
    }

    @keyframes card-small-blink {
      9% {
        opacity: 1;
        filter: grayscale(0%);
      }

      12% {
        opacity: 0.6;
        filter: grayscale(80%);
      }

      14%,
      29% {
        opacity: 1;
        filter: grayscale(0%);
      }

      32% {
        opacity: 0.6;
        filter: grayscale(80%);
      }

      34%,
      49% {
        opacity: 1;
        filter: grayscale(0%);
      }

      52% {
        opacity: 0.6;
        filter: grayscale(80%);
      }

      54%,
      75% {
        opacity: 1;
        filter: grayscale(0%);
      }

      80% {
        opacity: 0.6;
      }

      100% {
        opacity: 1;
      }
    }

    @keyframes card-small-2 {
      8% {
        transform: translateY(-29px);
        opacity: 0;
      }

      20%,
      33% {
        transform: translateY(0);
        opacity: 1;
      }

      43%,
      53% {
        transform: translateY(29px);
        opacity: 1;
      }

      73% {
        transform: translateY(-29px);
        opacity: 0;
      }
    }

    @keyframes card-small-3 {
      33% {
        transform: translateY(-58px);
        opacity: 0;
      }

      43%,
      53% {
        transform: translateY(-29px);
        opacity: 1;
      }

      73% {
        transform: translateY(-58px);
        opacity: 0;
      }
    }
  }

  .spreadsheet {
    .spreadsheet-top-card {
      .spreadsheet-top-card-1,
      .spreadsheet-top-card-2,
      .spreadsheet-top-card-3 {
        animation-iteration-count: $animation-iteration-count;
        animation-duration: $animation-duration;
      }

      .spreadsheet-top-card-1 {
        animation-name: spreadsheet-top-card-1;
      }

      .spreadsheet-top-card-2 {
        animation-name: spreadsheet-top-card-2;
      }

      .spreadsheet-top-card-3 {
        fill: colors.$color-silver;
        animation-name: spreadsheet-top-card-3;
      }

      @keyframes spreadsheet-top-card-1 {
        0% {
          fill: colors.$color-grey;
        }

        20%,
        75% {
          fill: colors.$color-grey-active;
        }

        100% {
          fill: colors.$color-grey;
        }
      }

      @keyframes spreadsheet-top-card-2 {
        25% {
          fill: colors.$color-grey;
        }

        50%,
        75% {
          fill: colors.$color-grey-active;
        }

        100% {
          fill: colors.$color-grey;
        }
      }

      @keyframes spreadsheet-top-card-3 {
        0%,
        25% {
          fill: colors.$color-silver;
        }

        35%,
        70% {
          fill: colors.$color-grey;
        }

        100% {
          fill: colors.$color-silver;
        }
      }
    }

    .spreadsheet-center-cards {
      .spreadsheet-center-card-1,
      .spreadsheet-center-card-2,
      .spreadsheet-center-card-3 {
        .spreadsheet-center-card-active {
          animation-iteration-count: $animation-iteration-count;
          animation-duration: $animation-duration;
          opacity: 0;
        }
      }

      .spreadsheet-center-card-1 {
        .spreadsheet-center-card-active {
          opacity: 1;
          animation-name: spreadsheet-center-card-1-active;
        }
      }

      .spreadsheet-center-card-2 {
        .spreadsheet-center-card-active {
          animation-name: spreadsheet-center-card-2-active;
        }
      }

      .spreadsheet-center-card-3 {
        .spreadsheet-center-card-active {
          animation-name: spreadsheet-center-card-3-active;
        }
      }

      @keyframes spreadsheet-center-card-1-active {
        0%,
        10% {
          opacity: 1;
        }

        20%,
        80% {
          opacity: 0;
        }

        90%,
        100% {
          opacity: 1;
        }
      }

      @keyframes spreadsheet-center-card-2-active {
        10% {
          opacity: 0;
        }

        20%,
        30% {
          opacity: 1;
        }

        40% {
          opacity: 0;
        }
      }

      @keyframes spreadsheet-center-card-3-active {
        30% {
          opacity: 0;
        }

        40%,
        80% {
          opacity: 1;
        }

        90% {
          opacity: 0;
        }
      }
    }
  }

  .circle-progress {
    transform: rotate(220deg);
    transform-box: fill-box;
    transform-origin: center;
    animation-iteration-count: $animation-iteration-count;
    animation-duration: $animation-duration;
    animation-name: circle-progress;

    @keyframes circle-progress {
      0% {
        transform: rotate(220deg);
      }

      24%,
      25% {
        transform: rotate(283deg);
      }

      49%,
      50% {
        transform: rotate(330deg);
      }

      74%,
      75% {
        transform: rotate(380deg);
      }

      100% {
        transform: rotate(580deg);
      }
    }
  }

  .line-vertical-progress {
    path {
      fill: colors.$color-active-light;
    }

    .line-vertical-progress-1,
    .line-vertical-progress-2,
    .line-vertical-progress-3,
    .line-vertical-progress-4,
    .line-vertical-progress-5 {
      transform: scaleY(1) translate(0, 0);
      transform-box: fill-box;
      transform-origin: bottom;
      animation-iteration-count: $animation-iteration-count;
      animation-duration: $animation-duration;
    }

    .line-vertical-progress-1 {
      fill: colors.$color-active;
      animation-name: line-vertical-progress-1;
    }

    .line-vertical-progress-2 {
      animation-name: line-vertical-progress-2;
    }

    .line-vertical-progress-3 {
      animation-name: line-vertical-progress-3;
    }

    .line-vertical-progress-4 {
      animation-name: line-vertical-progress-4;
    }

    .line-vertical-progress-5 {
      animation-name: line-vertical-progress-5;
    }

    @keyframes line-vertical-progress-1 {
      0% {
        fill: colors.$color-active;
      }

      25% {
        fill: colors.$color-active-light;
      }

      50% {
        transform: scaleY(1);
      }

      75% {
        fill: colors.$color-active-light;
        transform: scaleY(1.6);
      }

      100% {
        fill: colors.$color-active;
        transform: scaleY(1);
      }
    }

    @keyframes line-vertical-progress-2 {
      0% {
        fill: colors.$color-active-light;
      }

      25% {
        fill: colors.$color-active;
        transform: scaleY(1);
      }

      50% {
        fill: colors.$color-active-light;
        transform: scaleY(1.2);
      }

      75% {
        transform: scaleY(0.2);
      }

      100% {
        transform: scaleY(1);
      }
    }

    @keyframes line-vertical-progress-3 {
      0% {
        transform: scaleY(1);
      }

      25% {
        fill: colors.$color-active-light;
        transform: scaleY(1.4);
      }

      50% {
        fill: colors.$color-active;
        transform: scaleY(1);
      }

      75% {
        fill: colors.$color-active-light;
        transform: scaleY(1.2);
      }

      100% {
        transform: scaleY(1);
      }
    }

    @keyframes line-vertical-progress-4 {
      25% {
        transform: scaleY(1);
      }

      50% {
        transform: scaleY(0.6);
      }

      100% {
        transform: scaleY(1);
      }
    }

    @keyframes line-vertical-progress-5 {
      0% {
        fill: colors.$color-active;
      }

      25% {
        fill: colors.$color-active-light;
        transform: scaleY(0.3);
      }

      50% {
        transform: scaleY(1);
      }

      75% {
        fill: colors.$color-active-light;
        transform: scaleY(0.8);
      }

      100% {
        fill: colors.$color-active;
        transform: scaleY(1);
      }
    }
  }
}
