@use 'includes/colors';
@use 'includes/variables';

%close {
  display: block;
  position: absolute;
  top: variables.$gap_s;
  right: variables.$gap_s;
  width: 20px;
  height: 20px;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    width: 100%;
    height: 2px;
    border-radius: variables.$radius_tiny;
    background-color: colors.$color_navigation;
    transition: 0.2s;
    transform: translateY(-50%) rotate(45deg);
  }

  &:before {
    transform: translateY(-50%) rotate(-45deg);
  }

  &:hover {
    &:before,
    &:after {
      background-color: colors.$color_action;
    }
  }
}
