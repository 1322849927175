@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.progress {
  &_label,
  &_status {
    display: block;
    margin-bottom: variables.$gap_xxxs;
    color: colors.$color_info;
    font-size: variables.$size_normal;
    font-weight: 600;
    line-height: 1.5;
    text-align: right;
  }

  &_status {
    margin-bottom: 0;
    font-size: variables.$size_small;
    font-weight: 500;
    text-align: left;
  }

  &_bar {
    display: block;
    position: relative;
    overflow: hidden;
    height: 8px;
    border-radius: variables.$radius_small;
    background-color: colors.$color_gray;

    @include mixins.night {
      background-color: colors.$color-dark-2;
    }

    span {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-radius: variables.$radius_small;
      background: colors.$color_info;
    }

    & + .progress_status {
      margin-top: 2px;
    }
  }

  &_number {
    display: block;
    position: relative;
    color: colors.$color_info;
    font-size: 56px;
    font-weight: 600;
    line-height: 1.25;
    text-align: center;

    @include mixins.desktop_small {
      font-size: 42px;
    }
  }

  &_diagram {
    display: block;
    position: relative;
    width: 120px;
    margin: 0 auto;

    & + .progress_stats {
      margin-top: variables.$gap_s;
    }

    svg {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      z-index: 1;
    }

    span {
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      color: colors.$color_text_1;
      font-size: variables.$size_big;
      font-weight: 700;
      line-height: 1.5;
      text-align: center;
      z-index: 2;
    }
  }

  &_stats {
    display: flex;
    position: relative;
    overflow: hidden;
    align-items: stretch;
    margin: 0 -#{variables.$gap_xs};
  }

  &_stat {
    position: relative;
    left: -1px;
    flex-basis: 0;
    flex-grow: 1;
    box-sizing: border-box;
    padding: 0 variables.$gap_xs;
    border-left: 1px solid colors.$color_gray;
    font-size: variables.$size_normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;

    span {
      display: block;
      color: colors.$color_info;
      font-weight: 700;
    }
  }
}
