@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.card_box {
  display: block;
  padding: variables.$gap;
  border-radius: variables.$card-radius;
  background-color: colors.$color_white;
  box-shadow: variables.$shadow_regular;
  box-sizing: border-box;
  cursor: default;

  @include mixins.night {
    background: colors.$color-mirage;
    box-shadow: variables.$shadow_regular_night;
  }

  &_title {
    font-size: variables.$size_big;

    &_holder {
      display: flex;
      align-items: center;
    }
  }

  & > section:last-child {
    margin-bottom: 0;
  }

  @include mixins.phone_large {
    padding: variables.$gap_s;
  }

  &_blue {
    margin-bottom: variables.$gap_s;
    padding: variables.$gap_s;
    border-radius: variables.$card-radius;
    background-color: colors.$color-support-5;
    box-sizing: border-box;
    color: colors.$color_primary;

    @include mixins.night {
      background-color: colors.$color-dark-2;
      color: colors.$night_primary;
    }
  }
}

.card-box {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: variables.$card-radius;
  background-color: colors.$color_white;
  margin-bottom: variables.$spacer;
  box-sizing: border-box;

  @include mixins.default-box-shadow();

  @include mixins.night {
    background: colors.$color-mirage;
  }

  &.half {
    height: calc(50% - #{variables.$spacer * 0.5});
  }

  &.disabled {
    opacity: 0.2;
  }

  .card {
    position: relative;
    padding: 20px;
    flex-grow: 1;
    border-radius: variables.$card-radius variables.$card-radius 0 0;
    margin-bottom: 0;
    height: auto;

    & + .card {
      padding-top: 0;
    }

    & > :last-child {
      margin-bottom: 0;
    }

    .item {
      font-size: variables.$size_normal;
    }
  }

  &.case-study,
  &.premium-template {
    align-items: flex-start;

    .label {
      display: inline-block;
      font-weight: bold;
      font-size: variables.$size_normal;
      line-height: 20px;
      padding: 5px 20px;
      color: colors.$color_white;
      border-radius: 15px;
      background-color: colors.$color-accent-2;
      text-transform: uppercase;
      text-align: center;
      position: absolute;
      margin-left: variables.$gap_xs;
      margin-top: variables.$gap_xs;
    }

    &.without-image {
      .label {
        position: relative;
      }
    }

    .content-short {
      + .content-full {
        display: none;
      }
    }
  }

  .heading {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-top: -2px;
    margin-bottom: variables.$gap;
    font-family: variables.$font_title;
    font-size: variables.$size_small;
    line-height: 1.6;
    font-weight: 700;
    color: colors.$color_primary;

    @include mixins.night {
      color: colors.$night_primary;
    }

    &.large {
      font-size: variables.$size_big;
      line-height: 1.4;
      margin-bottom: 20px;
    }
  }

  .icon {
    display: block;
    width: 25px;
    height: 25px;
    background: url(/assets/images/ico_checkmark.svg) 50% 50% no-repeat;
    background-size: contain;
    position: relative;
  }

  .action {
    display: inline-block;
    color: colors.$color-support-3;
    font-size: variables.$size_normal;
    line-height: 24px;
    margin: auto 0 0;
    padding: 10px 20px;
    flex-grow: 0;
    font-weight: bold;
    margin-bottom: 10px;

    &::after {
      content: '\f054';
      display: inline-block;
      margin-left: 25px;
      font-family: 'Font Awesome 5 Pro', sans-serif;
    }
  }

  .thumbnail,
  .attachment-post-thumbnail {
    display: block;
    width: 100%;
    height: auto;
  }
}

.cards-title {
  position: relative;
  width: calc(100% - 2rem);
  font-weight: bold;
  font-size: variables.$size_large;
  line-height: 25px;
  margin: variables.$gap variables.$gap_xs 20px;

  &.first-title {
    margin-top: 0;
  }

  @include mixins.dark() {
    color: colors.$color-neutral-7;
  }
}
