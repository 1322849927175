@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

%card {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: nowrap;
  align-items: stretch;
  padding: variables.$gap;
  border-radius: variables.$card-radius;
  background-color: colors.$color_white;
  cursor: default;

  @include mixins.shadow;

  &.auto {
    flex-grow: 0;
  }

  &.compact {
    padding: variables.$gap_s;
  }

  & > div:last-child,
  & > a:last-child,
  & > form:last-child {
    margin-bottom: 0;
  }

  img {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
  }

  @include mixins.desktop_small {
    padding: variables.$gap_xs;

    &.compact {
      padding: variables.$gap_xs;
    }
  }

  @include mixins.night {
    background: colors.$color-mirage;
  }
}

[class*='_card'] {
  margin-bottom: variables.$gap;

  @include mixins.desktop_small {
    margin-bottom: variables.$gap_xs;
  }
}
