@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.filebox {
  display: block;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    .filebox_icon {
      background-image: url(/assets/images/ico_file_hover.svg);
    }

    .filebox_icon.icon_gdrive {
      background-image: url(/assets/images/ico_gdrive_hover.svg);
    }
  }

  .filezone {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 160px;
    padding: variables.$gap_s;
    border: 1px solid colors.$color_gray;
    border-radius: variables.$radius_regular;
    color: colors.$color-neutral-7;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    z-index: 2;

    & > :last-child {
      margin-bottom: 0;
    }

    @include mixins.night {
      color: colors.$night_main;
    }
  }

  .filebox_title {
    display: block;
    margin-bottom: 6px;
    font-size: variables.$size_large;
    line-height: 1.33;

    @include mixins.phone_small {
      font-size: variables.$size_normal;
    }
  }

  .filebox_text {
    display: block;
    margin-bottom: 6px;
    font-size: 16px;
  }

  .filebox_tip {
    display: block;
    margin-bottom: 14px;
    font-size: 12px;
    font-weight: 500;
  }

  .filebox_icon {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
    background: url(/assets/images/ico_file.svg) 50% 50% no-repeat;
    background-size: contain;
    transition: 0.2s;

    &.icon_gdrive {
      background-image: url(/assets/images/ico_gdrive.svg);
    }
  }

  .filebox_buttons {
    margin-bottom: -2px;

    &:last-child {
      margin-bottom: -16px;
    }

    @include mixins.phone_small {
      .button {
        white-space: unset;
      }
    }
  }

  input[type='file'] {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    opacity: 0;
    z-index: 10;
  }

  .progress_bar {
    display: none;
    margin-top: variables.$gap_xs;

    &.is_visible {
      display: block;
    }
  }

  @include mixins.tablet_small {
    .filezone {
      height: auto;
      font-size: 14px;
    }
  }
}
