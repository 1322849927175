@use 'sass:math';
@use 'sass:map';
@use 'includes/variables';
@use 'includes/mixins';

$grid-columns: 12;
$grid-gutter-width: variables.$gap;
$grid-breakpoints: (
  xs: 0,
  sm: #{variables.$tablet_small}px,
  md: #{variables.$tablet_large}px,
  lg: #{variables.$desktop_small}px,
  xl: #{variables.$desktop_large}px,
) !default;

@mixin make-container($gutter: $grid-gutter-width) {
  width: 100%;
  padding-right: $gutter;
  padding-left: $gutter;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
}

@mixin make-row($gutter: $grid-gutter-width) {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -#{$gutter * 0.5} -#{$gutter};
}

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  %grid-column {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    box-sizing: border-box;
    width: 100%;
    padding-right: $gutter * 0.5;
    padding-left: $gutter * 0.5;
    margin-bottom: $gutter;
  }

  @each $breakpoint in map.keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @for $i from 1 through $columns {
      .col#{$infix}-#{$i} {
        @extend %grid-column !optional;
      }
    }

    .col#{$infix},
    .col#{$infix}-auto {
      @extend %grid-column !optional;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      .col#{$infix} {
        box-sizing: border-box;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }

      .col#{$infix}-auto {
        box-sizing: border-box;
        flex-basis: 0;
        flex-grow: 1;
        width: auto;
        max-width: 100%;
      }

      @for $i from 1 through $columns {
        .col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }

      .order#{$infix}-first {
        order: -1;
      }

      .order#{$infix}-last {
        order: $columns + 1;
      }

      @for $i from 0 through $columns {
        .order#{$infix}-#{$i} {
          order: $i;
        }
      }

      // `$columns - 1` because offsetting by the width of an entire row isn't possible
      @for $i from 0 through ($columns - 1) {
        @if not($infix == '' and $i == 0) {
          // Avoid emitting useless .offset-0
          .offset#{$infix}-#{$i} {
            @include make-col-offset($i, $columns);
          }
        }
      }
    }
  }
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 math.percentage(math.div($size, $columns));
  max-width: math.percentage(math.div($size, $columns));
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: math.div($size, $columns);
  margin-left: if($num == 0, 0, math.percentage($num));
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, '', '-#{$name}');
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map.get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

.container,
.container-fluid {
  @include make-container();

  .container,
  .container-fluid {
    width: auto;
    padding: 0;
    margin-right: 0;
    margin-left: 0;
  }
}

.container {
  max-width: variables.$max-content-width;

  & > section:last-child {
    margin-bottom: 0;
  }
}

.row {
  @include make-row();

  section {
    margin-bottom: 0;
  }
}

@include make-grid-columns();

@include mixins.desktop_small {
  .container,
  .container-fluid {
    padding-right: $grid-gutter-width * 0.5;
    padding-left: $grid-gutter-width * 0.5;
  }

  .row {
    margin: 0 -#{$grid-gutter-width * 0.25} -#{$grid-gutter-width * 0.5};
  }

  .col,
  [class*='col-'] {
    padding-right: $grid-gutter-width * 0.25;
    padding-left: $grid-gutter-width * 0.25;
    margin-bottom: $grid-gutter-width * 0.5;
  }
}
