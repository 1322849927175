@use 'sass:map';
@use '@angular/material' as mat;
@use '@angular/cdk' as cdk;
@use 'includes/mixins';

@mixin mat-menu-theme($theme) {
  $accent: map.get($theme, accent);
  $warn: map.get($theme, warn);
  $primary: map.get($theme, primary);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);

  @include mixins.app-root() {
    .mat-mdc-menu-item {
      .mdc-list-item__primary-text {
        width: 100%;
      }
    }
  }
}
