@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

%tabs {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  overflow: hidden;
  align-items: flex-end;
  justify-content: flex-start;
  transition: all 0.2s;
  z-index: 3;

  & > .tab {
    display: block;
    position: relative;
    padding: variables.$gap_xs;
    color: colors.$color_text_2;
    font-size: variables.$size_small;
    font-weight: 700;
    line-height: 1.6;
    text-align: center;
    white-space: nowrap;
    transition: 0.3s;
    cursor: pointer;

    @include mixins.night {
      color: colors.$night_primary;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 4px;
      background-color: colors.$color_gray;
      transition: 0.3s;
    }

    &:hover,
    &.active {
      text-decoration: none;
      color: colors.$color_primary;

      @include mixins.night {
        color: colors.$blue-400;
      }

      &:after {
        background-color: colors.$color_primary;

        @include mixins.night {
          background-color: colors.$blue-400;
        }
      }
    }

    &:focus-visible {
      outline: none !important;
      border-radius: 0;
      color: colors.$color_navigation;

      @include mixins.night {
        color: colors.$blue-400;
      }

      &:after {
        background-color: colors.$color_navigation;

        @include mixins.night {
          background-color: colors.$blue-400;
        }
      }
    }

    &:first-child {
      padding-left: 0;

      @include mixins.tablet_large {
        margin-left: variables.$gap_s;
      }
    }

    &:last-child {
      @include mixins.tablet_large {
        margin-right: variables.$gap_s;
      }
    }
  }

  @include mixins.tablet_large {
    overflow-x: auto;
  }
}

%tabs_full-width {
  display: flex;
  position: relative;
  overflow: hidden;
  align-items: stretch;
  justify-content: stretch;
  margin-bottom: variables.$gap_l;
  border-top: 1px solid colors.$color-neutral-8;
  border-bottom: 1px solid colors.$color-neutral-8;
  overflow: auto;
  z-index: 3;

  @include mixins.desktop_small {
    margin-bottom: variables.$gap;
  }

  @include mixins.tablet_large {
    margin-bottom: variables.$gap_s;
  }

  & > .tab {
    flex-grow: 1;
    display: block;
    position: relative;
    padding: 20px variables.$gap_xs 22px;
    background-color: colors.$color-neutral-1;
    border-right: 1px solid colors.$color-neutral-8;
    color: colors.$neutral-700;
    font-size: variables.$size_normal;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    white-space: nowrap;
    transition: 0.3s;
    cursor: pointer;

    @include mixins.night {
      background: colors.$night_background;
      color: colors.$night_primary;
    }

    &:hover,
    &.active {
      background-color: colors.$color-neutral-8;
      text-decoration: none;
      color: colors.$color-biscay;

      @include mixins.night {
        background: colors.$blue-900;
        color: colors.$blue-400;
      }
    }

    &:focus-visible {
      outline: variables.$gap_xxxs solid colors.$blue-400;
      outline-offset: -#{variables.$gap_xxxs};
    }

    &:last-of-type {
      border-right: none;
    }
  }
}
