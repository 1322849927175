@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.upload_box {
  display: flex;
  position: relative;
  align-items: stretch;
  width: 100%;
  min-height: 280px;
  border: 1px solid colors.$color_gray;
  border-radius: variables.$card-radius;
  background-color: colors.$color_light;
  font-size: variables.$size_small;
  font-weight: 400;
  line-height: 1.429;
  text-align: left;
  margin-bottom: variables.$gap_s;
  box-sizing: border-box;
  overflow: hidden;

  @include mixins.night {
    background: colors.$night_background;
    border: none;
  }

  .upload_tabs {
    display: block;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    z-index: 2;
    border-right: variables.$radius_small solid colors.$color_gray;

    @include mixins.night {
      border-right: 2px solid colors.$color_black;
    }
  }

  .upload_tab {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 60px;
    height: 48px;
    border-bottom: variables.$radius_small solid colors.$color_gray;
    font-size: variables.$size_huge;
    line-height: 1;
    text-align: center;
    transition: 0.3s;
    cursor: pointer;

    &:last-child {
      border-bottom-width: 0;
    }

    &:hover {
      background-color: colors.$color_light;
      color: colors.$color_navigation;

      @include mixins.night {
        background-color: colors.$color_black;
        color: colors.$night_primary;
      }

      .upload_label {
        transform: scaleX(1);
        visibility: visible;
      }
    }

    &.active {
      background-color: colors.$color_light;
      position: relative;

      &:after {
        content: '';
        top: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        width: 8px;
        background: colors.$color_navigation;
      }

      @include mixins.night {
        background: colors.$color_black;
      }
    }

    &:focus-visible {
      outline: variables.$gap_xxxs solid colors.$blue-400;
      outline-offset: -#{variables.$gap_xxxs};
      border-radius: variables.$radius_small;
    }
  }

  .upload_label {
    display: flex;
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 100%;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    box-sizing: border-box;
    max-width: 200px;
    height: 46px;
    padding: 0 variables.$gap_xs 0 0;
    border: 1px solid colors.$color_gray;
    border-left-width: 0;
    border-radius: 0 variables.$radius_small variables.$radius_small 0;
    background-color: inherit;
    color: colors.$color_main;
    font-size: variables.$size_small;
    font-weight: 700;
    line-height: 1.571;
    text-align: left;
    white-space: nowrap;
    transition: transform 0.3s;
    transform: scaleX(0);
    transform-origin: 0 50%;
    visibility: hidden;

    @include mixins.night {
      border-color: colors.$color_black;
      color: colors.$night_primary;
    }
  }

  .upload_link {
    margin-left: 12px;
  }

  .upload_contents {
    display: block;
    position: relative;
    flex-grow: 1;
    max-width: calc(100% - 64px); // 64px - width of the "upload_tabs" block
    z-index: 1;

    &:has(.focus-visible) {
      &:focus-within {
        outline: variables.$gap_xxxs solid colors.$blue-400;
        outline-offset: -#{variables.$gap_xxxs};
        border-radius: variables.$radius_small;
      }
    }
  }

  .upload_content {
    display: none;
    position: relative;

    &.active {
      display: block;
      height: 100%;
    }
  }

  .filezone,
  .filelist {
    height: 270px;
    border-width: 0;
    border-radius: 0;
    background: transparent;

    @include mixins.phone_small {
      height: 380px;
    }
  }

  .rounded_search_input_holder ~ ul {
    max-height: 235px;
    margin-bottom: -#{variables.$gap_s};
    overflow-y: auto;
  }

  .preview {
    padding: variables.$gap_xs;

    img {
      margin: 0 auto;
    }
  }

  app-upload-file {
    .label {
      display: none;
    }
  }
}
