@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';
@use 'elements/controls';
@use 'elements/menu';
@use 'elements/status';
@use 'elements/table';

.table_box {
  .contents {
    min-width: 100%;
    border-radius: variables.$card-radius;
    background-color: colors.$color_white;
    box-shadow: variables.$shadow_regular;

    display: inline-flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    @include mixins.tablet_large {
      background: unset !important;
      box-shadow: unset !important;
    }

    @include mixins.night {
      background: colors.$color-mirage;
      box-shadow: variables.$shadow_regular_night;
    }

    &.scrolled {
      overflow-x: auto;
      overflow-y: hidden;

      @include mixins.tablet_large {
        overflow: unset;
      }
    }
  }

  .controls {
    @extend %controls !optional;
  }

  .table {
    @extend %table !optional;

    tr {
      &:first-child {
        th,
        td {
          &:first-child {
            border-top-left-radius: variables.$card-radius;
          }

          &:last-child {
            border-top-right-radius: variables.$card-radius;
          }
        }
      }

      &:last-child {
        td {
          border-bottom: none;

          &:first-child {
            border-bottom-left-radius: variables.$card-radius;
          }

          &:last-child {
            border-bottom-right-radius: variables.$card-radius;
          }
        }
      }
    }

    .number,
    .id {
      width: 32px;
      color: colors.$color_primary;
      text-align: left;

      @include mixins.night {
        color: colors.$night_primary;
      }
    }

    .actions {
      width: 80px;
      text-align: center;
    }

    .image {
      width: 100px + variables.$gap_s;
    }

    .info {
      max-width: 130px;
    }

    .lms-admin & {
      font-size: variables.$size_small;

      .button {
        &.link {
          font-size: variables.$size_small;
        }
      }
    }
  }

  .text {
    display: block;
    margin-top: 3px;
    font-size: variables.$size_small;

    &:first-child {
      margin-top: 0;
    }
  }

  .instructors {
    display: block;
    position: relative;
    margin-top: 5px;
    font-size: variables.$size_small;

    &:first-child {
      margin-top: 0;
    }
  }

  .subtitle {
    display: block;
    position: relative;
    margin-top: 5px;
    color: colors.$neutral-800;
    font-size: variables.$size_small;

    &:first-child {
      margin-top: 0;
    }
  }

  .menu {
    @extend %menu !optional;
  }

  .status {
    @extend %status !optional;
  }

  .info {
    .badge {
      min-width: 94px;
    }
  }

  .image {
    img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: variables.$radius_regular;
      background: colors.$neutral-300;

      &.no-image {
        height: 58px;
        object-fit: none;
      }
    }
  }

  @include mixins.desktop_small {
    .info {
      .badge {
        min-width: 80px;
      }
    }
  }

  @include mixins.tablet_large {
    .table {
      .actions {
        width: 36px;
        margin-left: auto;
      }

      .image {
        order: 1;
        width: 116px;
      }

      .details {
        order: 2;
        width: calc(100% - 116px);
      }

      .info {
        max-width: calc(100% - 80px);
      }

      .number,
      .id {
        width: auto;

        &:before {
          content: '#';
          display: inline-block;
          margin-right: 5px;
          color: colors.$color_primary;
          font-weight: 600;
        }
      }

      .id:before {
        content: 'ID:';
      }
    }
  }

  .empty-row {
    margin: 0 !important;
    padding: 0 !important;
    font-size: variables.$size_normal !important;

    @include mixins.tablet_large {
      justify-content: center;
    }

    td {
      text-align: center;
      margin: 0 !important;
      padding: variables.$gap_xs !important;
    }
  }

  &_full_height {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: auto;
    min-height: 100%;
    margin-bottom: 0;
  }
}
