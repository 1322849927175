@use 'includes/colors';
@use 'includes/variables';

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-and-drop-list.cdk-drop-list-dragging .drag-and-drop-element:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-and-drop-placeholder {
  min-height: 10px;
  padding: variables.$gap_xs;
  margin-bottom: variables.$gap_xs;
  border: 2px dotted colors.$color_gray;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-handle {
  display: block;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  background: url(/assets/images/ico_drag.svg) 50% 50% no-repeat;
  background-size: 24px auto;
  cursor: move;
  &.hidden {
    display: none;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;

  .div-table-col {
    &.image {
      width: 100px + variables.$gap_s;

      img {
        max-width: 100%;
      }
    }
  }

  &.lesson {
    display: flex;
    align-items: center;
    padding: (variables.$gap_xs - variables.$gap_xxxs);
    border: 1px solid colors.$color_gray;
    border-radius: variables.$radius_small;

    .caption {
      display: block;
      flex-grow: 1;
      min-width: 0;
      color: inherit;
      font-weight: 600;

      &:hover {
        color: colors.$color_navigation;
      }
    }
  }
}

// Workoround for table tr grag and drop. Draggable table should be replaced to divs or list in the future
// because cdk drag and drop doesn't support tables tr
app-course-lesson-content {
  .table {
    .name {
      width: 480px;
    }
    .content-type {
      width: 95px;
    }
    .drag-and-drop-column {
      width: 60px;
      padding-left: 0;
    }
  }
}
tr.cdk-drag-preview {
  .drag-and-drop-placeholder {
    display: none;
  }
  td {
    padding: 16px 12px;
    text-align: center;
    overflow: hidden;
    &.drag-and-drop-column {
      width: 40px;
      padding-left: 24px;
    }
    &.content-type {
      width: 65px;
      text-align: left;
      padding-left: 0;
    }
    &.name {
      font-weight: 600;
      width: 480px;
      text-align: left;
      overflow: hidden;
    }
    .menu {
      display: none;
    }
  }
}
