@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.login_box {
  .container {
    max-width: 760px + variables.$gap * 3;

    .fast-enroll_wrapper & {
      @include mixins.desktop_large {
        max-width: unset;
        padding-left: variables.$gap;
        padding-right: variables.$gap;
      }

      @include mixins.tablet_large {
        padding-left: variables.$gap_s;
        padding-right: variables.$gap_s;
      }
    }
  }

  h1 {
    margin-bottom: variables.$gap;
    text-align: center;

    @include mixins.desktop_small {
      margin-bottom: variables.$gap_s;
    }

    & + .caption {
      margin-top: -#{variables.$gap_xs};
      max-width: 545px;
      margin-left: auto;
      margin-right: auto;

      @include mixins.desktop_small {
        margin-top: -#{variables.$gap_xs};
      }

      @include mixins.phone_large {
        margin-top: 0;
      }
    }
  }

  .caption {
    margin-bottom: variables.$gap;
    text-align: center;

    @include mixins.desktop_small {
      margin-bottom: variables.$gap_s;
    }

    &.is_left {
      &-tablet {
        @include mixins.tablet_large {
          text-align: left;
        }
      }

      &-phone {
        @include mixins.phone_large {
          text-align: left;
        }
      }
    }
  }
  .subtitle {
    margin-bottom: variables.$gap_xs;
  }
}
