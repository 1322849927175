@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

html,
body {
  height: 100%;
}

.auth-wrapper {
  .header_wrapper {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    min-height: variables.$height_header;
    padding: 0 variables.$gap;
    background: colors.$color_white;
    z-index: 22;
    box-shadow: variables.$shadow_long;
    width: 100%;
    box-sizing: border-box;
    font-size: variables.$size_small;
    line-height: 1.429;

    @include mixins.tablet_large {
      flex-wrap: wrap;
      padding: 0 variables.$gap_s;
    }

    @include mixins.night {
      background: colors.$night_dark;
    }

    &:after {
      content: '';
      flex: 1;
    }

    .lms-admin & {
      background-color: colors.$color_primary;
    }
  }

  .container {
    padding-top: 80px;

    @include mixins.tablet_large {
      padding-left: variables.$gap_s;
      padding-right: variables.$gap_s;
    }
  }

  &.has-enroll-progress {
    .container {
      padding-top: 80px;

      @include mixins.tablet_large {
        padding-top: 140px;
      }

      @include mixins.phone_large {
        padding-top: 168px;
      }
    }
  }

  .form_box {
    &.card {
      max-width: unset;
      padding: variables.$gap_l;

      @include mixins.desktop_small {
        padding: variables.$gap_s;
      }

      @include mixins.night {
        background: colors.$color-mirage;
        box-shadow: variables.$shadow_regular_night;
      }
    }

    .row {
      @include mixins.desktop_small {
        margin-bottom: 0;
      }

      @include mixins.tablet_large {
        .item {
          &:nth-of-type(1) {
            order: 1;
          }

          &:nth-of-type(2) {
            order: 2;
          }

          &:nth-of-type(3) {
            order: 4;
          }

          &:nth-of-type(4) {
            order: 5;
          }

          &:nth-of-type(5) {
            order: 6;
          }

          &:nth-of-type(6) {
            order: 7;
          }

          &:nth-of-type(7) {
            order: 8;
          }

          &:nth-of-type(8) {
            order: 9;
          }

          &:nth-of-type(9) {
            order: 10;
          }
        }
      }
    }

    label {
      text-align: left;
    }
  }

  section {
    margin-bottom: 0;
  }

  .logo {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    .night_mode_active {
      display: none;

      @include mixins.night {
        display: inline-block;
      }
    }

    :not(.night_mode_active) {
      display: inline-block;

      @include mixins.night {
        display: none;
      }
    }

    & > img {
      vertical-align: middle;
    }

    &_wrapper {
      display: flex;
      align-items: center;
      padding: #{variables.$gap_s * 0.5} 0;
      flex: 1;
      white-space: nowrap;

      @include mixins.tablet_large {
        flex: 1 0 100%;
      }
    }
  }

  .badge {
    margin-left: variables.$gap_s;
  }

  &.main_box {
    background: colors.$color-neutral-1;

    @include mixins.night {
      background: colors.$night_background;
    }
  }

  .buttons:not(.social-buttons) {
    .button {
      padding: #{variables.$gap_s * 0.5} variables.$gap_xs;

      @include mixins.phone_large {
        margin: 0 0 25px;
        width: calc(100% - #{variables.$gap_xs});

        &.dashboard-button {
          margin: 0 0 30px;
        }
      }

      &:not(.button_social) {
        margin-bottom: variables.$gap;

        @include mixins.desktop_small {
          font-size: variables.$size_large;
        }
      }
    }
  }

  .caption-bottom {
    padding: variables.$gap_l 0 0;
    font-size: variables.$size_tiny;
    text-align: left;

    @include mixins.phone_large {
      padding: 10px 0 0;
    }

    p {
      margin-bottom: 15px;
    }
  }

  .form_box {
    .heading {
      .label {
        margin-right: variables.$gap_xxs;
      }
    }
  }

  .checkbox {
    label {
      color: colors.$color-disabled;

      @include mixins.night {
        color: colors.$night_main;
      }

      &.small {
        font-size: variables.$size_small;
      }
    }
  }

  [class*='col-'] {
    @include mixins.desktop_small {
      margin-bottom: variables.$gap_s;
    }

    @include mixins.desktop_small {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .reset-password {
    margin-left: auto;
  }

  @include mixins.tablet_large {
    .lms-admin &,
    .lms-dashboard & {
      text-align: center;
    }
  }

  @include mixins.phone_large {
    .reset-password {
      margin-top: variables.$gap_xxs;
      margin-left: unset;
      text-align: left;

      .lms-admin & {
        margin-top: variables.$gap_xs;
      }
      .lms-dashboard & {
        margin-right: -#{variables.$gap_xs};
      }
    }
  }

  .button {
    &.white {
      background-color: colors.$color-neutral-1;
      color: colors.$color-neutral-7;

      @include mixins.tablet_small {
        padding: 10px;
      }

      @include mixins.phone_small {
        padding: variables.$gap_xxxs;
      }

      @include mixins.night {
        background: colors.$color-dark-2;
        color: colors.$night_main;
      }

      &:focus,
      &:active {
        box-shadow: variables.$shadow_small;
      }
    }

    &_social {
      flex-grow: unset;
      max-width: calc(50% - 16px);

      @include mixins.phone_large {
        max-width: unset;
        width: unset;
        margin-bottom: 15px;

        & > i {
          margin-right: 0;
        }
      }
    }
  }

  .social-buttons {
    justify-content: center;

    @include mixins.phone_large {
      & > .button {
        flex-grow: 0;
      }
    }
  }
}

.hidden {
  &-small-phone {
    @include mixins.phone_large {
      display: none;
    }
  }
}

.visible {
  &-phone {
    display: none;

    @include mixins.phone_large {
      display: inline;
    }
  }
}
