@use 'colors';

$font_main:
  'Inter',
  -apple-system,
  BlinkMacSystemFont,
  'Segoe UI',
  Roboto,
  Helvetica,
  Arial,
  sans-serif,
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol'; // Default font

$font_title: 'Utopia Std', serif;

$shadow_none: none; // Text, illustration, icons

@function shadow($color, $type: 'regular') {
  $result: 'none';

  @if ($type == 'small') {
    $result:
      0px 1px 3px rgba($color, 0.05),
      0 1px 2px rgba($color, 0.1);
  } @else if ($type == 'large') {
    $result: 0px 8px 30px 0px rgba($color, 0.2);
  } @else if ($type == 'large-night') {
    $result: 0px 0px 0px 2px $color;
  } @else if ($type == 'regular') {
    $result: 0px 8px 24px 0px rgba($color, 0.2);
  } @else if ($type == 'regular-night') {
    $result: 0px 0px 0px 1px $color;
  } @else if ($type == 'regular-inverted') {
    $result:
      0px 10px 20px rgba($color, 0.06),
      0px 3px 6px rgba($color, 0.04);
  } @else if ($type == 'light') {
    $result: 0 0 15px rgba($color, 0.15);
  } @else if ($type == 'ultralight') {
    $result:
      15px 10px 22px rgba($color, 0.04),
      0px 10px 20px rgba($color, 0.05);
  } @else if ($type == 'active') {
    $result: inset 0px 0px 0px 2px rgba($color, 1);
  } @else if ($type == 'bold') {
    $result:
      0px 3px 6px rgba($color, 0.1),
      0px 10px 20px rgba($color, 0.15);
  } @else if ($type == 'long') {
    $result: 14px 10px 22px rgba($color, 0.05);
  }

  @return $result;
}

$shadow_small: shadow(colors.$color_black, 'small'); // When cards/buttons are pressed
$shadow_regular: shadow(colors.$color-regent-gray, 'regular'); // Buttons, images (when overlapping), cards
$shadow_regular_night: shadow(colors.$color-mako, 'regular-night'); // Buttons, images (when overlapping), cards
$shadow_regular_inverted: shadow(colors.$color_black, 'regular-inverted'); // Blocks
$shadow_large: shadow(colors.$color-mid-gray, 'large'); // Hover effects
$shadow_large_night: shadow(colors.$color-tuna, 'large-night'); // Hover effects
$shadow_light: shadow(colors.$color_black, 'light');
$shadow_ultralight: shadow(colors.$color_black, 'ultralight');
$shadow_active: shadow(colors.$color-support-3, 'active');
$shadow_bold: shadow(colors.$color-support-3, 'bold');
$shadow_long: shadow(colors.$color_black, 'long');

$size_extra_small: 10px;
$size_tiny: 12px;
$size_small: 14px;
$size_normal: 16px;
$size_large: 18px;
$size_huge: 20px;
$size_big: 24px;

$gap_xxxs: 4px;
$gap_xxs: 8px;
$gap_xs: 16px;
$gap_s: 24px;
$gap: 32px;
$gap_l: 48px;
$gap_xl: 64px;
$gap_xxl: 80px;
$gap_xxxl: 120px;

$radius_tiny: 2px;
$radius_small: 4px;
$radius_regular: 8px;
$radius_extra_large: 48px;

$height_header: 60px;
$height_search_panel: 87px;

$width_panel: 275px;
$width_course_player_sidebar: 350px;

$desktop_large: 1200;
$desktop_small: 992;

$tablet_large: 768;
$tablet_small: 576;

$phone_large: 480;
$phone_small: 420;

$card-radius: $radius_small;
$spacer: 2rem;

$max-content-width: 1170px + $gap * 2;
