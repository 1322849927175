@use 'includes/colors';
@use 'includes/variables';

.jwplayer.jw-skin-cfi {
  .jw-controls-backdrop {
    // background: linear-gradient(to bottom, transparent, rgba(0,0,0,0.2) 100%, rgba(0,0,0,0.2) 100%) 100% 100% / 100% 90px no-repeat transparent;
    background: none;
  }
  &.jw-state-idle {
    .jw-controls {
      background: none;
    }
  }
  &.jw-state-complete {
    .jw-title {
      .jw-title-primary,
      .jw-title-secondary {
        display: none;
      }
    }
  }
  .jw-controls {
    border: 1px solid colors.$color-cod-gray;
  }
}

/* Round, white play & restart buttons with black icons */
.jwplayer.jw-skin-cfi .jw-display-controls .jw-display-icon-container {
  background-color: colors.$color_white;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}

.jwplayer.jw-skin-cfi .jw-display-controls .jw-display-icon-container .jw-icon {
  color: colors.$color-cod-gray;
  width: 60px;
  height: 60px;
  padding: 20px;
}

/* Change play button colors on idle state, handle hover transitions */
.jwplayer.jw-skin-cfi.jw-state-idle .jw-display-controls .jw-display-icon-container {
  background-color: colors.$color_navigation;
  height: 80px;
  width: 80px;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
}

.jwplayer.jw-skin-cfi.jw-state-idle .jw-display-controls .jw-display-icon-container .jw-icon {
  color: colors.$color_white;
  height: 40px;
  width: 40px;
  padding: 20px;
}

/* Shrink play button on smaller breakpoints */
.jwplayer.jw-skin-cfi.jw-state-idle.jw-breakpoint-3 .jw-display-controls .jw-display-icon-container,
.jwplayer.jw-skin-cfi.jw-state-idle.jw-breakpoint-2 .jw-display-controls .jw-display-icon-container {
  height: 60px;
  width: 60px;
}

.jwplayer.jw-skin-cfi.jw-state-idle.jw-breakpoint-1 .jw-display-controls .jw-display-icon-container,
.jwplayer.jw-skin-cfi.jw-state-idle.jw-breakpoint-0 .jw-display-controls .jw-display-icon-container {
  height: 40px;
  width: 40px;
}

.jwplayer.jw-skin-cfi.jw-state-idle.jw-breakpoint-3 .jw-display-controls .jw-display-icon-container .jw-icon,
.jwplayer.jw-skin-cfi.jw-state-idle.jw-breakpoint-2 .jw-display-controls .jw-display-icon-container .jw-icon {
  height: 40px;
  width: 40px;
  padding: 10px;
}

.jwplayer.jw-skin-cfi.jw-state-idle.jw-breakpoint-1 .jw-display-controls .jw-display-icon-container .jw-icon,
.jwplayer.jw-skin-cfi.jw-state-idle.jw-breakpoint-0 .jw-display-controls .jw-display-icon-container .jw-icon {
  height: 20px;
  width: 20px;
  padding: 10px;
}

.jwplayer.jw-skin-cfi.jw-state-idle .jw-display-controls .jw-display-icon-container:hover {
  background-color: colors.$cyan-600;
}

.jwplayer.jw-skin-cfi.jw-state-idle .jw-display-controls .jw-display-icon-container .jw-icon:hover {
  color: colors.$color_white;
}

/* Position play icon above title */
.jwplayer.jw-skin-cfi.jw-state-idle .jw-display {
  height: 100%;
}

.jwplayer.jw-skin-cfi.jw-state-idle .jw-display-container {
  vertical-align: bottom;
}

.jwplayer.jw-skin-cfi .jw-display-controls .jw-display-icon-container .jw-icon:hover {
  color: colors.$cyan-600;
}

.jwplayer.jw-skin-cfi.jw-state-paused .jw-controls {
  background: none;
}

/* Customize font style and positioning for titles */
.jwplayer.jw-skin-cfi.jw-state-idle .jw-title {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/* Apply new styles to video title and description, handle transitions */
.jwplayer.jw-skin-cfi .jw-title-primary {
  font-size: 48px;
  padding: 30px;
  color: colors.$color-cod-gray;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
  padding-bottom: 15px;
  transition: transform 0.35s;
}

.jwplayer.jw-skin-cfi .jw-title-secondary {
  font-size: 18px;
  max-width: 70%;
  font-weight: 500;
  opacity: 0;
  margin: 0 auto;
  color: colors.$color-cod-gray;
  text-align: center;
  font-family: 'Merriweather', serif;
  white-space: normal;
  line-height: 1.3;
  transition:
    opacity 0.2s,
    transform 0.35s;
  transition-delay: 0.08s;
  transition-duration: 0.35s;
}

.jwplayer.jw-skin-cfi:hover .jw-title-secondary {
  opacity: 1;
}

.jwplayer.jw-skin-cfi .jw-title-primary,
.jwplayer.jw-skin-cfi .jw-title-secondary {
  transform: translate3d(0, 50px, 0);
}

/* Don't translate on tiny screens (when the description is hidden) */
.jwplayer.jw-skin-cfi:hover .jw-title-primary,
.jwplayer.jw-skin-cfi:hover .jw-title-secondary {
  transform: translate3d(0, 0, 0);
}

.jwplayer.jw-skin-cfi.jw-breakpoint-1 .jw-title-primary,
.jwplayer.jw-skin-cfi.jw-breakpoint-0 .jw-title-secondary {
  transform: translate3d(0, 10px, 0);
}

/* Decrease font size on medium-small breakpoints */
.jwplayer.jw-skin-cfi.jw-breakpoint-3 .jw-title-primary,
.jwplayer.jw-skin-cfi.jw-breakpoint-2 .jw-title-primary,
.jwplayer.jw-skin-cfi.jw-breakpoint-1 .jw-title-primary,
.jwplayer.jw-skin-cfi.jw-breakpoint-0 .jw-title-primary {
  font-size: 22px;
}

.jwplayer.jw-skin-cfi.jw-breakpoint-3 .jw-title-secondary,
.jwplayer.jw-skin-cfi.jw-breakpoint-2 .jw-title-secondary {
  font-size: variables.$size_small;
}

/* Hide the description on smallest breakpoints */
.jwplayer.jw-skin-cfi.jw-breakpoint-1 .jw-title-secondary,
.jwplayer.jw-skin-cfi.jw-breakpoint-0 .jw-title-secondary {
  display: none;
}

/* Change colors and remove gradient shadow for control bar, change icon color on pause */
.jwplayer.jw-skin-cfi .jw-display {
  padding-bottom: 45px;
}

.jwplayer.jw-skin-cfi .jw-controls::after {
  background: none;
}

.jwplayer.jw-skin-cfi .jw-controlbar {
  background-color: colors.$color_white;
}

.jwplayer.jw-skin-cfi .jw-controlbar .jw-button-container .jw-button-color {
  color: colors.$color-cod-gray;
}

.jwplayer.jw-skin-cfi.jw-state-paused .jw-controlbar .jw-button-container .jw-button-color {
  color: colors.$color_primary;
}

.jwplayer.jw-skin-cfi .jw-controlbar .jw-button-container .jw-button-color:hover {
  color: colors.$cyan-600;
}

/* Move tooltips closer to control bar with inline timeslider, change colors, round corners, remove shadow */
.jwplayer.jw-skin-cfi .jw-controlbar .jw-tooltip {
  bottom: 70%;
  box-shadow: none;
}

.jwplayer.jw-skin-cfi .jw-controlbar .jw-tooltip.jw-open {
  border-radius: 5px;
  color: colors.$cyan-600;
}

.jwplayer.jw-skin-cfi .jw-controlbar .jw-tooltip .jw-text {
  background-color: colors.$cyan-600;
  border-radius: 5px;
  border: 0;
  color: colors.$color_white;
}

.jwplayer.jw-skin-cfi .jw-controlbar .jw-text {
  color: colors.$color-cod-gray;
}

.jwplayer.jw-skin-cfi .jw-volume-tip,
.jwplayer.jw-skin-cfi .jw-time-tip {
  padding-bottom: 0;
}

/* Customize colors of volume control knob */
.jwplayer.jw-skin-cfi .jw-overlay .jw-slider-volume .jw-slider-container .jw-knob {
  background-color: colors.$color_primary;
}

/* Customize colors of timeslider */
.jwplayer.jw-skin-cfi .jw-controlbar .jw-slider-time .jw-slider-container .jw-rail {
  background-color: colors.$color-cod-gray;
}

.jwplayer.jw-skin-cfi .jw-controlbar .jw-slider-time .jw-slider-container .jw-buffer {
  background-color: colors.$cyan-600;
}

.jwplayer.jw-skin-cfi .jw-controlbar .jw-slider-time .jw-slider-container .jw-progress {
  background-color: colors.$color_primary;
}

.jwplayer.jw-skin-cfi .jw-controlbar .jw-slider-time .jw-slider-container .jw-knob {
  background-color: colors.$color_primary;
}

/* Customize colors/opacity of the settings menu */
.jwplayer.jw-skin-cfi .jw-settings-menu {
  background-color: transparent;
}

.jwplayer.jw-skin-cfi .jw-settings-menu .jw-settings-topbar {
  background-color: colors.$cyan-600;
}

.jwplayer.jw-skin-cfi .jw-settings-menu .jw-settings-topbar .jw-icon:hover {
  color: colors.$color-cod-gray;
}

.jwplayer.jw-skin-cfi .jw-settings-menu .jw-settings-submenu {
  background-color: rgba(colors.$color_white, 0.65);
}

.jwplayer.jw-skin-cfi .jw-settings-menu .jw-settings-content-item {
  color: colors.$color-cod-gray;
}

.jwplayer.jw-skin-cfi .jw-settings-menu .jw-settings-content-item:hover,
.jwplayer.jw-skin-cfi .jw-settings-menu .jw-settings-item-active {
  color: colors.$color_primary;
  font-weight: bold;
}
