@use 'includes/variables';

.error_box {
  margin-bottom: 0;

  .container {
    max-width: 640px + variables.$gap * 2;
  }

  h1 {
    margin-bottom: variables.$gap_s;
    text-align: center;
  }

  .error-link {
    cursor: pointer;
  }
}
