@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.badge {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  padding: 0 variables.$gap_xxs;
  border-radius: variables.$radius_tiny;
  background-color: colors.$color-honey-flower;
  color: colors.$color_white;
  font-family: variables.$font_main;
  font-size: variables.$size_tiny;
  font-weight: 400;
  line-height: 1.6;
  text-align: center;
  vertical-align: middle;
  text-transform: none;
  transition: 0.3s;

  &.rounded {
    border-radius: variables.$gap_xs;
  }

  &.dashed {
    border: 1px dashed colors.$color-support-3;
    color: colors.$blue-500;
    background: colors.$color-support-5;
  }

  &.small {
    padding: 1px #{variables.$gap_xxs - 2px};
    font-size: 11px;
    line-height: 12px;
  }

  &.large {
    padding: 6px #{variables.$gap_xxs + 4px};
    font-size: 16px;
    line-height: 18px;
  }

  &.info {
    background-color: colors.$color_info;

    &.outlined {
      border-color: colors.$color_info;
      color: colors.$color_info;
    }
  }

  &.danger {
    background-color: colors.$color_danger;

    &.outlined {
      border-color: colors.$color_danger;
      color: colors.$color_danger;
    }
  }

  &.warning {
    background-color: colors.$color_warning;
    color: colors.$color_black;

    &.outlined {
      border-color: colors.$color_warning;
      color: colors.$yellow-800;
    }
  }

  &.success {
    background-color: colors.$color_success;

    &.outlined {
      border-color: colors.$color_success;
      color: colors.$color_success;
    }
  }

  &.disabled {
    background-color: colors.$color_gray;
    color: colors.$color_text_1;

    &.outlined {
      border-color: colors.$color_text_2;
      color: colors.$color_text_1;
    }
  }

  &.outlined {
    border-color: colors.$color_navigation;
    background-color: transparent;
    color: colors.$color_navigation;
  }

  @include mixins.desktop_small {
    font-size: 11px;
  }

  .status-cell & {
    display: inline-flex;
    align-items: center;
    padding: variables.$gap_xxs;
    border-radius: variables.$gap_s;

    app-svg-icon {
      margin-right: 10px;
    }

    &.published {
      padding: variables.$gap_xxs 10px variables.$gap_xxs variables.$gap_xxs;
      background-color: colors.$green-100;
      color: colors.$green-700;

      app-svg-icon {
        position: relative;
        width: 20px;
        height: 20px;
        fill: colors.$green-700;
      }
    }

    &.warning {
      background-color: colors.$color-support-8;
      color: colors.$color-support-9;

      app-svg-icon {
        width: 24px;
        height: 24px;
        fill: colors.$color-support-9;
      }
    }
  }

  &.disabled {
    background-color: colors.$color-neutral-1;
    border: 1px dashed colors.$color-neutral-6;
    color: colors.$color-neutral-7;

    app-svg-icon {
      width: 20px;
      height: 20px;
      fill: colors.$color-neutral-7;
    }
  }
}

.badges {
  display: block;
  position: relative;
  margin: 0 -#{variables.$gap_xxs * 0.5};
  font-size: 0;
  line-height: 0;

  &:last-child {
    margin-bottom: -#{variables.$gap_xxs};
  }

  .badge {
    margin: 0 #{variables.$gap_xxs * 0.5} variables.$gap_xxs;
  }
}
