@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.mat-mdc-dialog-container {
  padding: 0 !important;
  box-shadow: none !important;
  background: none !important;
  max-width: 900px;
  overflow: hidden;

  @include mixins.night {
    color: colors.$night_main;
  }

  .card_box {
    position: relative;
    color: colors.$color-neutral-7;

    @include mixins.night {
      color: colors.$night_main;
    }
  }

  .close-btn {
    position: absolute;
    top: variables.$gap_xs;
    right: variables.$gap_xs;
    font-size: 0;
    line-height: 0;
    z-index: 2;

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: variables.$gap_xxxs solid colors.$blue-400;
      outline-offset: variables.$gap_xxxs;
      border-radius: variables.$radius_small;
    }

    @include mixins.tablet_large {
      top: variables.$gap_xxxs;
      right: variables.$gap_xxxs;
    }
  }

  .full-screen-modal & {
    @include mixins.desktop_large {
      height: 100%;
      width: 100%;
      max-width: 100vw;
      min-width: 100vw;
      max-height: 100vh;
      border-radius: 0;
    }
  }

  .mdc-dialog__surface {
    background-color: transparent !important;
    box-shadow: none !important;

    .full-screen-modal & {
      @include mixins.tablet_small {
        border-radius: 0;
      }
    }
  }
}

.full-screen-modal {
  @include mixins.desktop_large {
    max-width: 100vw !important;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(colors.$color-neutral-7, 0.5) !important;
}
