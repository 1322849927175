@use 'includes/colors';
@use 'includes/variables';

%status {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  padding: 4px variables.$gap_xxs;
  border: 1px solid colors.$color_success;
  border-radius: variables.$radius_small;
  background-color: colors.$color_white;
  color: colors.$color_black;
  font-family: variables.$font_main;
  font-size: 11px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
  vertical-align: middle;
  letter-spacing: 0.05em;
  transition: 0.3s;
  cursor: default;

  &.fail {
    border-color: colors.$red-200;
    background-color: colors.$red-200;
  }

  &.pass {
    border-color: colors.$green-200;
    background-color: colors.$green-200;
  }
}
