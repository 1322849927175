.svg-process-success {
  $animation-iteration-count: infinite;
  $animation-duration: 1.5s;

  .cup,
  .flag,
  .without-circle,
  .circle-lines-bg-light,
  .bezier-curves-1,
  .bezier-curves-2,
  .bezier-curves-3,
  .bezier-curves-4,
  .dots-group-1,
  .dots-group-2,
  .dots-group-3,
  .dots-group-4 {
    animation-iteration-count: $animation-iteration-count;
    animation-duration: $animation-duration;
  }

  .cup,
  .flag {
    transform-box: fill-box;
    animation-name: rotate-element;
  }

  .cup {
    transform-origin: bottom;
  }

  .flag {
    transform-origin: center 94px;
  }

  .circle-lines-bg-light,
  .bezier-curves-1,
  .bezier-curves-2,
  .bezier-curves-3,
  .bezier-curves-4 {
    transform-origin: center;
    transform-box: fill-box;
  }

  .circle-lines-bg-light {
    animation-name: scaleUP-circle-lines;
  }

  .bezier-curves-1 {
    animation-name: scaleUp-1;
  }

  .bezier-curves-2 {
    animation-name: scaleUp-2;
  }

  .bezier-curves-3 {
    animation-name: scaleUp-3;
  }

  .bezier-curves-4 {
    animation-name: scaleUp-4;
  }

  .dots-group-1,
  .dots-group-2,
  .dots-group-3,
  .dots-group-4 {
    transform-box: fill-box;
    transform-origin: center;
  }

  .dots-group-1 {
    transform-origin: bottom;
    animation-name: rotate-dots-group-1;
  }

  .dots-group-2 {
    animation-name: rotate-dots-group-2;
  }

  .dots-group-3 {
    animation-name: rotate-dots-group-3;
  }

  .without-circle {
    opacity: .2;
    animation-name: fadeOut;
  }

  @keyframes fadeOut {
    40%,
    70% {
      opacity: 0;
    }
  }

  @keyframes rotate-dots-group-1 {
    50% {
      transform: rotate(-10deg) translate(12px, 6px);
    }
  }

  @keyframes rotate-dots-group-2 {
    50% {
      transform: rotate(10deg) translate(-3px, -10px);
    }
  }

  @keyframes rotate-dots-group-3 {
    50% {
      transform: translate(0px, 6px);
    }
  }

  @keyframes rotate-element {
    50% {
      transform: rotate(16deg);
    }
  }

  @keyframes scaleUP-circle-lines {
    50% {
      transform: scale(1.2);
    }
  }

  @keyframes scaleUp-1 {
    50% {
      transform: scale(0.6);
    }
  }

  @keyframes scaleUp-2 {
    50% {
      transform: scale(1.7) translateY(-8px) translateX(-1px);
    }
  }

  @keyframes scaleUp-3 {
    50% {
      transform: translateY(-8px) translateX(11px) rotate(34deg);
    }
  }
  
  @keyframes scaleUp-4 {
    50% {
      transform: scale(0.8) translateY(20px) translateX(-16px);
    }
  }
}
