@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.dropdowns {
  margin-right: -#{variables.$gap_xs};
  margin-bottom: -#{variables.$gap_xs};

  .dropdown,
  .button {
    display: inline-block;
    margin-right: variables.$gap_xs;
    margin-bottom: variables.$gap_xs;
  }
}

.dropdown {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  min-width: 150px;
  padding: 0;
  margin: 0;
  border-radius: variables.$radius_small;
  background: colors.$color_white;
  vertical-align: middle;
  transition: 0.3s;
  cursor: pointer;
  z-index: 5;

  &.small {
    .value {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &.white {
    .value {
      background-color: colors.$color_white;
    }
  }

  &.inline {
    border: none;
    box-shadow: none;

    &:hover {
      .value {
        color: colors.$color_info;
      }
    }

    .options {
      margin-top: 10px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: -12px;
        right: 0;
        left: 0;
        height: 12px;
      }
    }

    .value {
      padding: 0;
      border-width: 0;
      background-color: transparent;
      box-shadow: none;
      color: colors.$color_text_1;
      font-size: inherit;
      font-weight: 700;
      line-height: inherit;

      &:after {
        display: inline-block;
        position: relative;
        top: 2px;
        right: 0;
        margin-left: 8px;
        vertical-align: middle;
      }
    }
  }

  &:hover {
    z-index: 10;

    .options {
      transform: translateY(0);
      visibility: visible;
      opacity: 1;
    }

    .value {
      border-color: colors.$color_navigation;
      background-color: colors.$color_navigation;
      color: colors.$color_white;

      &:after {
        background-image: url(/assets/images/ico_arrow_white.svg);
      }
    }
  }

  .options {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 300px;
    padding: variables.$gap_xxxs 0;
    margin-top: 0;
    border-radius: 0 0 variables.$radius_small variables.$radius_small;
    background-color: colors.$color_white;
    box-shadow: variables.$shadow_regular;
    transition: 0.3s;
    transform: translateY(-50px);
    visibility: hidden;
    opacity: 0;
    z-index: 1;

    @include mixins.night_deep {
      box-shadow: variables.$shadow_regular_night;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      width: 6px;
      border-radius: 5px;
      background: colors.$color_gray;
      transition: 0.3s;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  .option {
    display: block;
    position: relative;
    padding: variables.$gap_xxs variables.$gap_xs;
    margin: 0;
    background-color: colors.$color_white;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    transition: 0.3s;
    cursor: pointer;
    z-index: 2;

    &.selected,
    &:hover {
      background-color: colors.$color-support-5;
      color: colors.$color_navigation;
    }

    &.selected {
      font-weight: 700;
    }
  }

  .value {
    display: block;
    position: relative;
    padding: 12px 40px 12px variables.$gap_xs;
    border: 1px solid transparent;
    border-radius: variables.$radius_small;
    background: colors.$color_field;
    box-shadow: variables.$shadow_small;
    color: colors.$color_main;
    font-size: 16px;
    line-height: 22px;
    transition: 0.2s;
    z-index: 3;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: variables.$gap_xs;
      width: 8px;
      height: 13px;
      margin-top: -6px;
      background: url(/assets/images/ico_arrow_hover.svg) 50% 50% no-repeat;
      transition: 0.3s;
      transform: rotate(90deg);
    }

    em {
      margin-left: 4px;
      font-weight: 600;
    }
  }
}
