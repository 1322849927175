@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

app-home-page {
  display: block;
  flex-grow: 1;
}

app-user-login-page,
app-user-2fa-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

app-notifier {
  display: block;
  width: 350px;
  position: fixed;
  bottom: variables.$gap;
  right: variables.$gap;
  z-index: 50;

  @include mixins.desktop_small {
    bottom: variables.$gap_xs;
    right: variables.$gap_xs;
    width: (320px - variables.$gap);
  }
}
