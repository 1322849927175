@charset "utf-8";
@use '@angular/material' as mat;
@use 'includes/colors' as *;
@use 'includes/variables' as *;
@use 'includes/mixins' as *;

/* Base */
@use 'base/animation' as *;
@use 'base/defaults' as *;
@use 'base/fields' as *;
@use 'base/fonts' as *;
@use 'base/grid' as *;
@use 'base/header' as *;
@use 'base/layout' as *;
@use 'base/sections' as *;
@use 'base/auth' as *;

/* Elements */
@use 'elements/badge' as *;
@use 'elements/button' as *;
@use 'elements/card' as *;
@use 'elements/close' as *;
@use 'elements/content' as *;
@use 'elements/controls' as *;
@use 'elements/drag-and-drop' as *;
@use 'elements/dropdown' as *;
@use 'elements/filebox' as *;
@use 'elements/filelist' as *;
@use 'elements/heading' as *;
@use 'elements/jodit' as *;
@use 'elements/loader' as *;
@use 'elements/mat-search' as *;
@use 'elements/menu' as *;
@use 'elements/modal' as *;
@use 'elements/progress' as *;
@use 'elements/rating' as *;
@use 'elements/search' as *;
@use 'elements/status' as *;
@use 'elements/step' as *;
@use 'elements/select' as *;
@use 'elements/table' as *;
@use 'elements/tabs' as *;
@use 'elements/title' as *;
@use 'elements/tooltip' as *;
@use 'elements/uploader-filelist' as *;

/* Blocks */
@use 'blocks/alert' as *;
@use 'blocks/bottom' as *;
@use 'blocks/card' as *;
@use 'blocks/container' as *;
@use 'blocks/curriculum' as *;
@use 'blocks/end-free-preview' as *;
@use 'blocks/error' as *;
@use 'blocks/form' as *;
@use 'blocks/image' as *;
@use 'blocks/list' as *;
@use 'blocks/login' as *;
@use 'blocks/modal' as *;
@use 'blocks/sidebar' as *;
@use 'blocks/tabs' as *;
@use 'blocks/table' as *;
@use 'blocks/text' as *;
@use 'blocks/title' as *;
@use 'blocks/tooltip' as *;
@use 'blocks/upload' as *;
@use 'blocks/user' as *;
@use 'blocks/external-webhook' as *;

/* Cards */
@use 'cards/chapter' as *;
@use 'cards/icon' as *;
@use 'cards/number' as *;
@use 'cards/text' as *;

/* Print */
@use 'includes/print' as *;

/* Animation */
@use 'animation/skills-assessment-start' as *;
@use 'animation/skills-assessment-success' as *;

/* Plugins */
@use 'plugins/jwplayer' as *;

/* Material theme */
@use 'mat-theme/imports' as *;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
