@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';
@use 'elements/card';

.number_card {
  flex-direction: row;
  align-items: center;
  justify-content: stretch;

  @extend %card !optional;

  & .icon-tile {
    border-radius: 50%;
    background-color: colors.$color-polar;
  }

  & .far,
  .fal {
    color: colors.$color-ming;
  }

  &.compact {
    .icon {
      margin-right: variables.$gap_s;
    }
  }

  .icon {
    display: block;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    height: variables.$gap_l;
    width: variables.$gap_l;
    margin-right: variables.$gap;

    &:empty {
      background: url(/assets/images/pic_card_icon_small.svg) 50% 50% no-repeat;
      background-size: contain;

      &:before {
        content: '';
        display: block;
        position: relative;
        padding-bottom: 100%;
      }
    }

    &.large {
      height: variables.$gap_xl;
      width: variables.$gap_xl;
    }

    &:last-child {
      margin-right: 0;
    }

    & i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .number {
    display: block;
    position: relative;
    margin-bottom: variables.$gap_xxs;
    color: colors.$color_info;
    font-size: variables.$size_big;
    font-weight: 700;
    line-height: 1;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .text {
    display: block;
    position: relative;
  }

  @include mixins.media(1490) {
    padding: variables.$gap_xs;

    .icon {
      margin-right: variables.$gap_xs;
    }

    .number {
      font-size: variables.$size_large;
    }
  }

  @include mixins.desktop_large {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: variables.$gap;

    .icon {
      height: variables.$gap_xl;
      width: variables.$gap_xl;
      margin-right: 0;
      margin-bottom: variables.$gap_xs;

      &.large {
        margin-right: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .number {
      font-size: variables.$size_big;
    }
  }
}
