@use 'includes/variables';
@use 'includes/mixins';

.text {
  &-small {
    font-size: variables.$size_small;
  }

  &-large {
    font-size: variables.$size_large;

    @include mixins.tablet_large {
      font-size: variables.$size_normal;
    }
  }
}
