@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.list_box {
  display: block;
  position: relative;
  margin-bottom: variables.$gap;
  font-size: variables.$size_normal;
  font-weight: 700;
  line-height: 1.5;

  &.icons {
    li {
      li {
        li {
          a {
            padding-left: 80px;
            font-weight: 400;
          }
        }

        a {
          padding-top: variables.$gap_xxs;
          padding-bottom: variables.$gap_xxs;
        }
      }
    }

    a {
      padding-left: 52px;
      color: inherit;
      user-select: none;
    }
  }

  .icon {
    display: flex;
    position: absolute;
    top: 50%;
    left: variables.$gap_s;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    font-size: variables.$size_large;
    font-weight: 300;
    line-height: 1;
    text-align: center;
  }

  .icon-svg {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: variables.$gap_xs;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    fill: currentColor;
    stroke: currentColor;
  }

  .dot {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    border: 2px solid colors.$color_white;
    border-radius: 50%;
    background-color: colors.$color_action;
  }

  .heading {
    display: none;
    position: relative;
    padding: 10px 40px 10px variables.$gap_xs;
    border: 1px solid colors.$color_gray;
    border-radius: variables.$radius_small;
    font-size: variables.$size_normal;
    font-weight: 700;
    line-height: 1.375;
    text-align: left;
    transition: 0.3s;
    cursor: pointer;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: variables.$gap_xs;
      width: 8px;
      height: 13px;
      margin-top: -6px;
      background: url(/assets/images/ico_arrow_white.svg) 50% 50% no-repeat;
      transition: 0.3s;
      transform: rotate(90deg);
    }

    &.is_open {
      border-color: colors.$color_navigation;
      background-color: colors.$color_navigation;
      color: colors.$color_white;

      &:after {
        transform: rotate(-90deg);
      }
    }
  }

  ul,
  li,
  a {
    display: block;
    position: relative;
  }

  ul {
    ul {
      display: none;
    }
  }

  li {
    &.active {
      & > ul {
        display: block;
      }

      & > a {
        &:before {
          opacity: 1;
        }
      }
    }

    &.submenu > a {
      padding-right: variables.$gap_s;
      cursor: pointer;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 3px;
        width: 6px;
        height: 13px;
        margin-top: -6px;
        background: url(/assets/images/ico_arrow_white.svg) 50% 50% no-repeat;
        background-size: contain;
        transition: 0.3s;
        transform: rotate(90deg);
      }

      &:hover:after {
        background-image: url(/assets/images/ico_arrow_warning.svg);
      }
    }

    &.expanded > a:after {
      transform: rotate(-90deg);
    }

    li {
      a {
        padding-left: variables.$gap_l;
        font-weight: 400;
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    padding: 14px 0 14px variables.$gap;
    color: colors.$color_primary;
    font-size: variables.$size_small;
    font-weight: 700;
    line-height: 20px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 8px;
      transition: 0.2s;
      opacity: 0;
    }

    &:hover {
      text-decoration: none;

      &:before {
        opacity: 1;
      }
    }
  }

  @include mixins.desktop_small {
    &.large {
      font-size: variables.$size_normal;
    }

    .heading {
      display: block;

      & + .menu {
        display: none;
        padding-top: variables.$gap_xs;
      }
    }

    .icon {
      left: 20px;
    }

    li {
      li {
        a {
          padding-left: variables.$gap;
        }
      }
    }

    a {
      padding-right: 0;
      padding-left: variables.$gap_s;
    }
  }
}

.list {
  &-check {
    li {
      display: flex;
      position: relative;
      margin-bottom: 18px;
      font-size: variables.$size_large;

      @include mixins.tablet_large {
        font-size: variables.$size_normal;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        content: '\f00c';
        margin: -3px 20px 0 0;
        font-family: 'Font Awesome 5 Pro', sans-serif;
        font-size: 22px;
        font-weight: 300;

        @include mixins.tablet_large {
          margin: -3px 15px 0 0;
        }

        .fmva & {
          color: colors.$red-800;
        }

        .cbca & {
          color: colors.$color-support-9;
        }

        .cmsa & {
          color: colors.$color-support-1;
        }

        .bida & {
          color: colors.$color-support-6;
        }

        .fpwm & {
          color: colors.$green-700;
        }

        .ftip & {
          color: colors.$color-orange-satisfaction;
        }
      }
    }
  }

  &-disc-italic {
    li {
      position: relative;
      margin-bottom: variables.$gap_s;
      padding-left: variables.$gap_s;
      font-style: italic;

      @include mixins.tablet_large {
        font-style: normal;
      }

      &::before {
        content: '';
        display: inline-block;
        height: 4px;
        width: 4px;
        position: absolute;
        top: 10px;
        left: 2px;
        border-radius: 50%;
        background-color: colors.$color-neutral-7;

        @include mixins.night {
          background-color: colors.$night_main;
        }
      }
    }
  }

  &-disc-colored {
    li {
      position: relative;
      margin-bottom: variables.$gap_s;
      padding-left: variables.$gap_s;

      &::before {
        content: '';
        display: inline-block;
        height: 4px;
        width: 4px;
        position: absolute;
        top: 10px;
        left: 2px;
        border-radius: 50%;
        background-color: colors.$color-support-3;

        @include mixins.night {
          background-color: colors.$blue-400;
        }
      }
    }
  }

  &-disc-gray {
    li {
      position: relative;
      margin-bottom: variables.$gap_s;
      padding-left: variables.$gap_s;

      &::before {
        content: '';
        display: inline-block;
        height: 4px;
        width: 4px;
        position: absolute;
        top: 10px;
        left: 2px;
        border-radius: 50%;
        background-color: colors.$color-neutral-7;

        @include mixins.night {
          background-color: colors.$night_main;
        }
      }
    }
  }

  &-dashed {
    li {
      position: relative;
      margin-bottom: variables.$gap_s;
      padding-left: variables.$gap_s;

      &::before {
        content: '';
        display: inline-block;
        height: 1px;
        width: 4px;
        position: absolute;
        top: 10px;
        left: 2px;
        background-color: colors.$color-neutral-7;

        @include mixins.night {
          background-color: colors.$night_main;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &-numeric-colored {
    counter-reset: numeric-counter;

    li {
      position: relative;
      margin-bottom: variables.$gap_s;
      padding-left: variables.$gap_s;
      counter-increment: numeric-counter;
      word-break: break-word;

      &:before {
        content: counter(numeric-counter) '. ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        text-align: left;
        font-weight: 600;
        color: colors.$color-support-3;

        @include mixins.night {
          color: colors.$blue-400;
        }
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &-min-space-inner {
    li {
      margin-bottom: variables.$gap_xxs !important;

      &:last-child {
        margin-bottom: variables.$gap_s !important;
      }
    }
  }

  &-min-space {
    li {
      margin-bottom: variables.$gap_xxs !important;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}
