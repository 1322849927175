@use 'includes/colors';
@use 'includes/variables';

@media print {
  .no-print {
    display: none !important;
  }

  body {
    background: transparent !important;
    color: colors.$color-neutral-7 !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: colors.$color_primary !important;
  }

  app-top-menu,
  app-sidebar-menu,
  app-footer-box {
    display: none;
  }

  app-my-invoices-page {
    .container_box {
      padding: 20px 0 0 0;

      .back {
        display: none;
      }

      .card_box {
        padding: 0;
        background: transparent;
        box-shadow: unset;
        border-radius: unset;

        .row {
          display: block;
          column-count: 2;
        }
      }
    }
  }

  table,
  th,
  td {
    border-collapse: collapse !important;
    border: 1px solid colors.$color_black !important;
    text-align: left;
  }

  .table_box {
    .contents {
      border-radius: 0;
      box-shadow: none;
      background-color: none;

      thead,
      tfoot,
      tbody {
        background-color: transparent;
      }
    }

    .table {
      th,
      td {
        &:first-child {
          padding-left: calc(variables.$gap_s * 0.5);
        }
      }
    }
  }

  .pagination {
    display: none;
  }
}
