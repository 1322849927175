$green-100: #f0fdf4;
$green-200: #c3f3db;
$green-300: #99eac1;
$green-400: #6fe2a7;
$green-500: #44d98d;
$green-600: #27c273;
$green-700: #166534;
$green-800: #166e41;
$green-900: #0e4428;

$teal-100: #ddf9f2;
$teal-200: #b2f0e1;
$teal-300: #87e8d0;
$teal-400: #5ce0bf;
$teal-500: #31d8ae;
$teal-600: #22b490;
$teal-700: #1a896d;
$teal-800: #125e4b;
$teal-900: #0a3329;

$cyan-100: #ddf5f8;
$cyan-200: #b3e9ef;
$cyan-300: #89dde6;
$cyan-400: #5fd1dd;
$cyan-500: #35c5d4;
$cyan-600: #25a2af;
$cyan-700: #1c7d87;
$cyan-800: #14565c;
$cyan-900: #0a2f33;

$blue-100: #d9e5f7;
$blue-200: #afc8ee;
$blue-300: #86abe4;
$blue-400: #93acfd;
$blue-500: #1e40af;
$blue-600: #255bac;
$blue-700: #1c4582;
$blue-800: #000c3f;
$blue-900: #0a182e;

$indigo-100: #e5def7;
$indigo-200: #c5b5ee;
$indigo-300: #a58be4;
$indigo-400: #8562da;
$indigo-500: #6538d1;
$indigo-600: #4f28ae;
$indigo-700: #3c1f84;
$indigo-800: #29155a;
$indigo-900: #150b2d;

$purple-100: #faf2fc;
$purple-200: #e7c8f3;
$purple-300: #d59fea;
$purple-400: #c375e1;
$purple-500: #b14bd8;
$purple-600: #9a2bc5;
$purple-700: #79229b;
$purple-800: #5a1973;
$purple-900: #380f47;

$pink-100: #fae6f0;
$pink-200: #f1bcd6;
$pink-300: #e892bc;
$pink-400: #df68a2;
$pink-500: #d63e89;
$pink-600: #b7276e;
$pink-700: #8f1e56;
$pink-800: #65163d;
$pink-900: #3b0d23;

$red-100: #fff0f0;
$red-200: #f3c4c4;
$red-300: #ea9999;
$red-400: #e26f6f;
$red-500: #d94545;
$red-600: #9f1239;
$red-700: #981f1f;
$red-800: #6e1616;
$red-900: #440e0e;

$orange-100: #feeee6;
$orange-200: #fdcbb4;
$orange-300: #fca982;
$orange-400: #fb8650;
$orange-500: #fa621c;
$orange-600: #e04a05;
$orange-700: #af3a04;
$orange-800: #7d2903;
$orange-900: #4b1902;

$yellow-100: #fefaec;
$yellow-200: #f9dbb9;
$yellow-300: #f5c38a;
$yellow-400: #f1ab5b;
$yellow-500: #f59e0b;
$yellow-600: #d37a12;
$yellow-700: #a45f0e;
$yellow-800: #75440a;
$yellow-900: #462906;

$neutral-100: #ffffff;
$neutral-200: #f9fafb;
$neutral-300: #e5e7eb;
$neutral-400: #d0d1d3;
$neutral-500: #c2c4c6;
$neutral-600: #a7a9ac;
$neutral-700: #6b7280;
$neutral-800: #374151;
$neutral-900: #3c3f43;

/*** Custom colors ***/

$light-blue: #e7f2ff;

$color_main: $neutral-800; // Default body color

$color_primary: $blue-800; // Headings, backgrounds, gradients
$color_info: $cyan-600; // Information text, icons, gradients
$color_action: $orange-500; // Primary buttons and links

$color_danger: $red-600; // Warning alerts
$color_success: $green-700; // Success alert, illustrations, gradients
$color_warning: $yellow-500; // Warning alert, illustrations
$color_gradient: $purple-800; // Gradients, illustration

$color_text_1: $neutral-800; // Paragraph text
$color_text_2: $neutral-700; // Non-important text
$color_gray: $neutral-300; // Disabled text / shapes / other
$color_light: $neutral-200; // Backgrounds
$color_field: #f1f2f3; // Background for inputs
$color_focused: #f7faff; // Background for focused inputs
$color_hover: $blue-400;

$color_white: $neutral-100; // Backgrounds
$color_black: #000000;
$color_background: $color_white;
$color_selected_background: $color_white;
$color_background_select_group: #f3f4f6;
$color_background_info_notif: #eff6ff;
$color_background_info_notif_night: #1c3869;

/** Colors */
$color-accent-1: #fa621c;
$color-accent-2: #25a2af;
$color-support-1: #5a1973;
$color-support-3: #1e40af;
$color-support-4: #1c7d87;
$color-support-5: #e7f2ff;
$color-support-6: #255bac;
$color-support-8: #fefaec;
$color-support-9: #a45f0e;
$color-danger: #e32a27;
$color-disabled: #676767;
$color-orange: #f59e0b;
$color-pearl: #fcf8c8;
$color-aluminium: #6c757d;
$color-script-white: #dcdddf;
$color-light-sea-green: #21b8c7;
$color-silver-chalice: #aaa;
$color-azuki-red: #692424;
$color-strawberry-wine: #c96969;
$color-maritime-outpost: #1c4582;
$color-sunglo: #e16f6f;
$color-spindle: #c0d3f1;
$color-jordy-blue: #99bdf3;
$color-lynch: #64728b;
$color-oslo-gray: #80848c;
$color-deep-cerulean: #0077b5;
$color-gallery: #ebebeb;
$color-blue-dianne: #264e58;
$color-grey: #aeb8c4;
$color-grey-active: #b2c3dd;
$color-silver: #c2cdd6;
$color-active: #81a8e4;
$color-active-light: #9abae9;
$color-alabaster: #f9f9f9;
$color-mercury: #e6e6e6;
$color-french-pass: #b8dbff;
$color-boulder: #777777;
$color-polar: #f0fafc;
$color-ming: #3d7b85;
$color-cod-gray: #1e1e1e;
$color_ship_cove: #6f91c6;
$color_emerald: #106268;
$color_green_pea: #25685c;
$color_lochinvar: #32a087;
$color_bottle_green: #0e4339;
$color_eden: #145e51;
$color_genoa: #197463;
$color_elm: #1d8673;
$color_jungle_green: #219681;
$color_acapulco: #75b0a2;
$color-enamel-antique-green: #408186;
$color-niagara: #069d93;
$color-orange-satisfaction: #da9d00;
$color-punch-of-yellow: #ebcf86;
$color-cream-can: #edc14f;
$color-regent-gray: #959da5;

$color-light-2: #eefafc;
$color-dark: #1e2939;
$color-dark-2: #121418;
$color-bright-gray: #eee;
$color-dixie: #e89c18;
$color-chablis: #fff1f2;
$color-mirage: #191c26;
$color-danube: #6184d5;
$color-honey-flower: #5a1975;
$color-congress-blue: #004993;
$color-potters-clay: #947131;
$color-biscay: #1b3e70;
$color-fruit-salad: #4d9560;
$color-parsley: #124222;
$color-athens-gray: #f1f2f4;
$color-mandy: #e0586e;
$color-persian-plum: #6d182b;
$color-saddle-brown: #583000;
$color-anzac: #e4a44a;
$color-mako: #454b54;
$color-mid-gray: #64646e;
$color-tuna: #333943;
$color-gray-chateau: #9ca3af;
$color-blue-ribbon: #1f6eef;
$color-rolling-stone: #727477;
$color-eucalyptus: #1f995b;
$color-blue-zodiac: #132e57;

$color-neutral-1: #f9fafb;
$color-neutral-2: #dee2e6;
$color-neutral-3: #adb5bd;
$color-neutral-4: #495057;
$color-neutral-5: #212429;
$color-neutral-6: #6b7280;
$color-neutral-7: #374151;
$color-neutral-8: #e5e7eb;

$color_navigation: $color-congress-blue; // Navigation, hello bar

/* Night Mode */
$night_main: #f3f3f3;
$night_primary: $color_white;
$night_dark: #27292d;
$night_background: linear-gradient(0deg, rgba($color_white, 0.05), rgba($color_white, 0.05)),
  linear-gradient(0deg, rgba($blue-800, 0.08), rgba($blue-800, 0.08)), #121212;

$gradient: linear-gradient(to right top, $blue-700, $blue-400);
