@use 'includes/colors';
@use 'includes/variables';

.tooltip {
  display: block;
  box-sizing: border-box;
  width: max-content;
  max-width: 240px;
  padding: 8px 12px;
  margin-bottom: variables.$gap_xs;
  border: 1px solid colors.$color_gray;
  border-radius: variables.$radius_regular;
  background-color: colors.$color_white;
  color: colors.$color_main;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: 0.3s;
  visibility: hidden;
  opacity: 0;
  z-index: 5;

  &:last-child {
    margin-bottom: 0;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    color: colors.$color_white;
    z-index: 3;
  }

  &:after {
    color: colors.$color_gray;
    z-index: 1;
  }

  &.dark {
    border-color: colors.$neutral-900;
    background-color: colors.$neutral-900;
    color: colors.$color_white;

    &:before {
      color: colors.$neutral-900;
    }

    &:after {
      display: none;
    }
  }

  &.primary {
    border-color: colors.$color_primary;
    background-color: colors.$color_primary;
    color: colors.$color_white;

    &:before {
      color: colors.$color_primary;
    }

    &:after {
      display: none;
    }
  }

  &.top,
  &.bottom {
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-bottom: variables.$gap_xxs;
    transform: translateX(-50%);
  }

  &.top {
    &:before,
    &:after {
      bottom: -7px;
      left: 50%;
      margin-left: -8px;
      border-top: 8px solid;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }

    &:after {
      bottom: -8px;
    }
  }

  &.bottom {
    top: 100%;
    bottom: auto;
    margin-top: variables.$gap_xxs;
    margin-bottom: 0;

    &:before,
    &:after {
      top: -7px;
      left: 50%;
      margin-left: -8px;
      border-right: 8px solid transparent;
      border-bottom: 8px solid;
      border-left: 8px solid transparent;
    }

    &:after {
      top: -8px;
    }
  }

  &.left,
  &.right {
    position: absolute;
    top: 50%;
    left: 100%;
    max-width: 160px;
    margin-left: variables.$gap_xxs;
    transform: translateY(-50%);
  }

  &.left {
    &:before,
    &:after {
      top: 50%;
      left: -7px;
      margin-top: -8px;
      border-top: 8px solid transparent;
      border-right: 8px solid;
      border-bottom: 8px solid transparent;
    }

    &:after {
      left: -8px;
    }
  }

  &.right {
    right: 100%;
    left: auto;
    margin-right: variables.$gap_xxs;
    margin-left: 0;

    &:before,
    &:after {
      top: 50%;
      right: -7px;
      margin-top: -8px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid;
    }

    &:after {
      right: -8px;
    }
  }

  &-wrapper {
    position: relative;
    cursor: pointer;

    &:not(.not-hover-able) {
      &:hover {
        .tooltip {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    &.open {
      .tooltip {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .font-size-tiny {
    font-size: variables.$size_tiny;
  }
}
