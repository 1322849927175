@use 'includes/variables';

.rating {
  display: flex;
  align-items: center;
  margin-right: -#{variables.$gap_xxs};
  margin-top: 3px;

  & > span.star {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: variables.$gap_xxs;
    background: url(/assets/images/ico_star.svg) 50% 50% no-repeat;
    background-size: cover;
    cursor: pointer;

    &.active {
      background-image: url(/assets/images/ico_star_active.svg);
    }

    &.half-active {
      background-image: url(/assets/images/ico_star_half_active.svg);
    }
  }
}
