@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

%heading {
  display: flex;
  align-items: center;
  margin-bottom: variables.$gap_s;

  &:last-child {
    margin-bottom: 0;
  }

  .title {
    display: block;
    flex-grow: 1;
    margin-right: variables.$gap_xs;
    color: colors.$color_primary;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.33;

    & > :last-child {
      margin-right: 0;
    }
  }

  .buttons {
    margin: 0 -4px -4px;
    text-align: right;
  }

  .button {
    margin: 0 4px 4px;
  }

  @include mixins.desktop_small {
    margin-bottom: variables.$gap_xs;

    .title {
      font-size: 18px;
    }
  }
}
