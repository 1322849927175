@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.container_box {
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 0 0 variables.$width_panel;
  margin: 0 auto;

  height: auto;
  min-height: calc(100% - #{variables.$height_header});
  display: flex;
  flex-direction: column;

  .lms-admin & {
    @include mixins.desktop_small {
      padding-left: 60px;
      padding-top: calc(#{variables.$height_header} + 20px);
    }

    @include mixins.phone_large {
      padding-left: 0;
    }

    &.no_sidebar {
      &.container_general {
        @include mixins.desktop_small {
          padding-left: 60px;
          padding-top: #{variables.$height_header};
        }

        @include mixins.phone_large {
          padding-left: 0;
        }
      }
    }
  }

  .lms-dashboard & {
    @include mixins.desktop_small {
      padding-left: 0;
    }
  }

  &.no_sidebar {
    padding-left: 0;
    padding-top: 0;
  }

  .container {
    padding-right: variables.$gap;
    padding-left: variables.$gap;

    .lms-admin & {
      max-width: none;
    }

    @include mixins.tablet_large {
      padding-right: variables.$gap_s;
      padding-left: variables.$gap_s;
    }

    .container {
      padding-right: 0;
      padding-left: 0;
    }

    &_full_height {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: auto;
      min-height: 100%;
    }

    &-no-bottom-space {
      margin-bottom: 0;
    }
  }

  & > section {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.lms-admin {
  &-content-wrapper {
    background: colors.$color-support-5;
    min-height: 100vh;
    overflow: hidden;
  }
}

.search-opened {
  .content-wrapper {
    overflow: hidden;
  }

  app-footer-box {
    display: none;
  }

  .header_wrapper {
    position: relative !important;
    box-shadow: none !important;
  }

  @include mixins.desktop_small {
    .search {
      margin: variables.$gap_s 0 0 !important;
    }

    .search-results {
      position: relative;
      overflow-x: auto;
      margin-left: -#{variables.$gap_xs};
      margin-right: -#{variables.$gap_xs};
      padding-left: variables.$gap_xs;
      padding-right: variables.$gap_xs;
      overflow-x: hidden;
    }

    .sidebar_box {
      .scrollbar {
        overflow: hidden;
      }
    }
  }
}

app-sidebar-menu ~ app-program-order-physical-certificate {
  display: block;
  margin: 0 auto;
  padding: 20px 0 0 variables.$width_panel;

  @include mixins.desktop_small {
    padding-left: variables.$gap_xs;
    padding-right: variables.$gap_xs;
  }

  @include mixins.tablet_large {
    padding-left: variables.$gap_xxs;
    padding-right: variables.$gap_xxs;
  }
}
