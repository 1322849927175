@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';
@use 'elements/close';

.modal_box {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  align-items: center;
  justify-content: stretch;
  padding: 0;
  background-color: rgba(colors.$color_main, 0.8);
  transition: 0.5s;
  visibility: hidden;
  opacity: 0;
  z-index: 10;

  @include mixins.night {
    background-color: rgba(colors.$color_black, 0.6);
  }

  &.is_visible {
    visibility: visible;
    opacity: 1;

    .inner {
      top: 0;
    }
  }

  .inner {
    position: relative;
    top: -600px;
    box-sizing: border-box;
    width: 100%;
    max-height: 100vh;
    padding: variables.$gap_s;
    transition: 0.5s;
  }

  .modal {
    display: block;
    position: relative;
    overflow: visible;
    box-sizing: border-box;
    width: 100%;
    max-width: 640px;
    padding: variables.$gap_l;
    margin: 0 auto;
    border-radius: variables.$radius_small;
    background-color: colors.$color_white;
    box-shadow: variables.$shadow_regular;

    &.wide {
      display: flex;
      align-items: stretch;
      max-width: 920px;
    }

    @include mixins.night {
      background: colors.$color-mirage;
      box-shadow: variables.$shadow_regular_night;
    }
  }

  .image,
  .contents {
    display: block;
    position: relative;
    flex-basis: 0;
    flex-grow: 1;
    z-index: 1;
  }

  .content {
    font-size: 18px;
    z-index: 1;
  }

  .image {
    left: -#{variables.$gap_l};
    margin: -#{variables.$gap_l} 0;
    background: colors.$neutral-200 50% 50% no-repeat;
    background-size: cover;
  }

  .form_box {
    margin-bottom: 0;
  }

  .close {
    top: variables.$gap_xs;
    right: variables.$gap_xs;
    z-index: 5;

    @extend %close !optional;
  }

  @include mixins.desktop_small {
    .modal {
      padding: variables.$gap_s;
    }

    .image {
      left: -#{variables.$gap_s};
      margin: -#{variables.$gap_s} 0;
    }

    .content {
      font-size: 16px;
    }
  }

  @include mixins.tablet_small {
    .image {
      display: none;
    }
  }
}
