@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.mat-mdc-tab-header {
  margin-bottom: variables.$gap_s;
}

.mat-mdc-tab-body {
  max-width: 100%;
}

.mat-mdc-tab-body,
.mat-mdc-tab-body-wrapper,
.mat-mdc-tab-body-content {
  overflow: visible !important;
}

.mdc-tab {
  min-width: unset !important;
  flex-grow: unset !important;
  position: relative;
  padding: variables.$gap_xs;
  text-align: center;
  white-space: nowrap;
  transition: 0.3s;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: colors.$color-neutral-8;
    transition: 0.3s;
  }

  &:first-of-type {
    padding-left: 0;

    &::after {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }
  }

  &:last-of-type {
    &::after {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  &:hover,
  &.mdc-tab--active {
    &::after {
      background-color: colors.$color_primary;
      border-radius: variables.$radius_tiny;

      @include mixins.night {
        background-color: colors.$blue-400;
      }
    }
  }

  &.cdk-focused {
    &::after {
      background-color: colors.$color-congress-blue;

      @include mixins.night {
        background-color: colors.$blue-400;
      }
    }
  }
}

.mdc-tab__text-label {
  font-size: variables.$size_normal;
  font-weight: 700;
  line-height: 1.25 !important;
  color: colors.$color-neutral-6 !important;

  @include mixins.night {
    color: colors.$color_white !important;
  }

  .mdc-tab:hover &,
  .mdc-tab--active & {
    color: colors.$color_primary !important;

    @include mixins.night {
      color: colors.$blue-400 !important;
    }
  }

  .mdc-tab:focus & {
    color: colors.$color-congress-blue !important;

    @include mixins.night {
      color: colors.$blue-400 !important;
    }
  }

  .cdk-focused & {
    color: colors.$color-congress-blue !important;

    @include mixins.night {
      color: colors.$blue-400 !important;
    }
  }
}

.mdc-tab__ripple,
.mat-mdc-tab-ripple,
.mdc-tab-indicator {
  display: none !important;
}

.mat-mdc-tab-header-pagination {
  position: absolute !important;
  top: 0;
  width: 32px;
  height: 32px;
  padding: 0 !important;
  border-radius: 50%;
  background: colors.$color_primary !important;
  transition: all 0.2s;

  &:hover {
    background: colors.$blue-400 !important;

    @include mixins.night {
      background: colors.$blue-400 !important;
    }
  }

  @include mixins.night {
    background: colors.$blue-400 !important;
  }

  &:after {
    content: '';
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    height: 24px;
    width: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-before {
    left: variables.$gap_xxs;

    &:after {
      background-image: url(/assets/images/dashboard/ico_prev.svg);
    }
  }

  &-after {
    right: variables.$gap_xxs;

    &:after {
      background-image: url(/assets/images/dashboard/ico_next.svg);
    }
  }

  &-chevron,
  &-disabled {
    display: none !important;
  }
}
