@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

%title {
  display: block;
  position: relative;
  margin: 0 0 #{variables.$gap_xs + variables.$gap_xxxs};
  color: colors.$color_primary;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.25;

  &:last-child {
    margin-right: 0;
  }

  @include mixins.desktop_small {
    font-size: 20px;
  }
}

.back {
  margin-bottom: 24px;
  display: inline-block;
  font-weight: bold;

  i,
  app-svg-icon {
    margin-right: 5px;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: variables.$gap_xxxs solid colors.$blue-400;
    border-radius: variables.$radius_small;
  }
}
