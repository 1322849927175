@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

%table {
  box-sizing: border-box;
  width: 100%;
  border-collapse: collapse;
  font-size: variables.$size_normal;
  font-weight: 400;
  line-height: 1.5;

  @include mixins.desktop_small {
    font-size: variables.$size_small;
  }

  @include mixins.tablet_large {
    display: block;
    position: relative;
    padding-top: 1px;
  }

  .sortable {
    display: inline-flex;
    align-items: center;
    color: inherit;

    &:hover {
      text-decoration: none;
      color: colors.$color_navigation;

      .arrow {
        fill: colors.$color-support-3;

        @include mixins.night {
          fill: colors.$color-support-3;
        }
      }
    }

    .arrow {
      fill: colors.$color_primary;

      @include mixins.night {
        fill: colors.$night_primary;
      }
    }
  }

  .arrow {
    position: relative;
    top: 1px;
    margin-left: variables.$gap_xxxs;
  }

  th,
  td {
    box-sizing: border-box;
    padding: variables.$gap_xs variables.$gap_s * 0.5;
    border-bottom: 1px solid rgba(colors.$color_gray, 0.5);
    text-align: left;
    vertical-align: middle;

    &:first-child {
      padding-left: variables.$gap_s;
    }

    &:last-child {
      padding-right: variables.$gap_s;
    }

    @include mixins.tablet_large {
      order: 5;
      padding: 0 variables.$gap_xxs;
      margin: 0 0 variables.$gap_xs;
      border: none;

      &:first-child {
        padding-left: variables.$gap_xxs;
      }

      &:last-child {
        padding-right: variables.$gap_xxs;
      }
    }
  }

  th {
    color: colors.$color_primary;
    font-weight: 700;
    white-space: nowrap;

    @include mixins.night {
      color: colors.$night_primary;
    }

    &.sorting_asc,
    &.sorting_desc {
      color: colors.$color_navigation;
    }
  }

  tr {
    &.hidden {
      display: none;
    }

    @include mixins.tablet_large {
      display: flex;
      position: relative;
      flex-wrap: wrap;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      padding: 0 variables.$gap_xxs;
      margin: 0 0 variables.$gap_xs;
      border-bottom: 1px solid rgba(colors.$color_gray, 0.5);

      &:first-child {
        margin-top: variables.$gap_xs;
      }

      &:last-child {
        margin-bottom: 0;
        border-bottom-width: 0;
      }
    }

    &:last-child {
      td {
        @include mixins.night {
          border-bottom: none;
        }
      }
    }
  }

  thead,
  tfoot {
    background-color: colors.$color_light;

    @include mixins.tablet_large {
      display: none;
    }

    @include mixins.night {
      background: colors.$color-dark-2;
    }
  }

  tbody {
    background-color: colors.$color_white;

    @include mixins.tablet_large {
      display: block;
      background-color: transparent;
    }

    @include mixins.night {
      background: colors.$night_dark;

      @include mixins.tablet_large {
        background: colors.$night_background;
      }
    }

    &.is_loading_app:after {
      background: rgba(colors.$color_white, 0.6) url(/assets/images/ico_loader.svg) 50% calc(100% - 12px) no-repeat;

      @include mixins.night {
        background: rgba(colors.$color_black, 0.6) url(/assets/images/ico_loader.svg) 50% calc(100% - 12px) no-repeat;
      }
    }

    &.is_loading_data:after {
      content: '';
      margin: 0 -#{variables.$gap};
      background: rgba(colors.$color_white, 0.6) url(/assets/images/ico_loader.svg) 50% 8px no-repeat;

      @include mixins.night {
        background: rgba(colors.$color_black, 0.6) url(/assets/images/ico_loader.svg) 50% 8px no-repeat;
      }
    }
  }

  td {
    &.name,
    .name {
      word-break: break-word;
    }
  }
}

.div-table {
  display: table;
  width: 100%;
  border-radius: variables.$radius_regular;

  &-body {
    display: table-row-group;
  }

  &-col {
    border-bottom: 1px solid rgba(colors.$color_gray, 0.5);
    display: table-cell;
    box-sizing: border-box;
    padding: variables.$gap_xs variables.$gap_s * 0.5;
    text-align: left;
    vertical-align: middle;

    &:first-child {
      padding-left: variables.$gap_s;
    }

    &:last-child {
      padding-right: variables.$gap_s;
    }

    &.cell-align-right {
      text-align: right;
    }

    &.content-type {
      width: 70px;
    }

    &.drag-handle-col {
      width: 50px;
    }
  }

  &-row {
    display: table-row;
    width: auto;
    clear: both;
  }

  &-th {
    background-color: colors.$color_light;

    .div-table-col {
      color: colors.$color_primary;
      font-weight: 700;

      &:first-child {
        border-top-left-radius: variables.$radius_regular;
      }

      &:last-child {
        border-top-right-radius: variables.$radius_regular;
      }
    }
  }
}

.cdk-drag-preview {
  background-color: colors.$color_white;
  width: 500px;

  .div-table-col {
    box-sizing: border-box;
    padding: variables.$gap_xs variables.$gap_s * 0.5;
    text-align: left;
    float: left;
    vertical-align: middle;

    &.actions {
      display: none;
    }
  }

  .content-type {
    width: 70px;
  }

  .drag-handle-col {
    width: 50px;
  }
}
