@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.header_box {
  display: block;
  position: relative;
  flex-grow: 0;
  height: variables.$height_header;
  margin-bottom: variables.$gap;
  font-size: variables.$size_small;
  font-weight: 400;
  line-height: 20px;
  z-index: 10;

  &.is_sidebar {
    .sidebar_btn {
      span {
        opacity: 0;
      }

      &:before,
      &:after {
        margin-top: 0;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &.is_expanded {
    .menu_btn {
      span {
        opacity: 0;
      }

      &:before,
      &:after {
        width: 30px;
        height: 1px;
        margin-top: 0;
        margin-left: -15px;
        border-radius: 0;
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  &.is_search {
    .search_btn {
      color: colors.$color_action;
    }
  }

  a:not(.button) {
    color: inherit;

    &:hover {
      color: colors.$color_action;
    }
  }

  .header {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    box-sizing: border-box;
    height: variables.$height_header;
    padding: 0;
    background-color: colors.$color_primary;
    box-shadow: variables.$shadow_ultralight;
    color: colors.$color_white;
    transition:
      height 0.4s,
      background-color 0.3s,
      box-shadow 0.3s;
    transition-delay: 0.2s;

    &_wrapper {
      @include mixins.night {
        background: colors.$night_dark;
      }
    }
  }

  .navigation {
    display: flex;
    position: relative;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: variables.$height_header;
    padding: 0 variables.$gap_s;
    background-color: colors.$color_primary;
    transition: 0.3s;
    z-index: 2;

    @include mixins.desktop_small {
      padding: 0 variables.$gap_xs;

      &:after {
        content: '';
        flex: 1;
      }
    }

    @include mixins.phone_large {
      &:after {
        content: none;
      }

      &:before {
        content: '';
        flex: 1;
      }
    }
  }

  .identity {
    display: flex;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    width: variables.$width_panel - variables.$gap_s;
    margin-right: variables.$gap_s;
    color: inherit;

    @include mixins.desktop_small {
      justify-content: center;
      margin-right: 0;
    }
  }

  .logo {
    display: block;
    position: relative;
    width: 108px;
    height: 32px;
    background: url(/assets/images/ico_logo_white.svg) 0 50% no-repeat;
    background-size: contain;
    cursor: pointer;
    z-index: 5;

    &:hover {
      opacity: 0.8;
    }
  }

  .badge {
    margin: 0 #{variables.$gap_s * 0.5};

    @include mixins.desktop_large {
      margin-left: variables.$gap_s;
    }

    @include mixins.desktop_small {
      margin-left: variables.$gap_xxs;
    }
  }

  .site-user {
    flex-shrink: 0;
    display: inline-block;
    margin: 0 0 0 31px;
    padding: 0 #{variables.$gap_s * 0.5};
    background-color: colors.$color-neutral-1;
    font-size: 15px;
    line-height: variables.$height_header;
    font-weight: bold;
    color: colors.$color_primary;

    @include mixins.desktop_large {
      margin: 0 0 0 39px;
    }

    @include mixins.desktop_small {
      display: none;
    }

    @include mixins.not_room {
      background-color: colors.$color-enamel-antique-green;
      color: colors.$color_white;
    }

    @include mixins.night {
      background-color: colors.$color-dark-2;
      color: colors.$night_main;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
  }

  .action {
    display: block;
    margin-left: variables.$gap_s;
    font-weight: 600;

    &:first-child {
      margin-left: 0;
    }

    i {
      display: inline-block;
      position: relative;
      top: -1px;
      margin-right: 2px;
      font-size: 20px;
      line-height: 1;
      vertical-align: middle;
    }
  }

  .button {
    padding: variables.$gap_xxs 15px;
    margin-left: variables.$gap_s;
    font-size: variables.$size_small;
    line-height: 20px;

    &:first-child {
      margin-left: 0;
    }

    i {
      font-size: variables.$size_normal;
    }
  }

  .search {
    display: block;
    position: relative;
    right: 0;
    left: 0;
    flex-grow: 1;
    max-width: 480px;
    margin: 0;

    .clear {
      display: block;
      position: absolute;
      top: 50%;
      right: 15px;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      border: none;
      border-radius: 50%;
      background: colors.$color_info url(/assets/images/ico_close.svg) 50% 50% no-repeat;
      transition: 0.2s;
      cursor: pointer;
      opacity: 0;
      z-index: 2;

      &:hover {
        background-color: colors.$color_action;
      }
    }

    input {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
      padding-right: variables.$gap_xs;
      padding-left: variables.$gap_l;
      border-radius: 24px;

      &:focus {
        & + .clear {
          opacity: 1;
        }

        & ~ button {
          color: colors.$color_info;
        }
      }

      @include mixins.not_room {
        background: rgba(colors.$color_white, 0.2);
        color: colors.$night_main;

        @include mixins.placeholder {
          color: colors.$color_white;
        }
      }
    }

    button {
      display: block;
      position: absolute;
      top: 50%;
      left: 15px;
      width: 20px;
      height: 20px;
      padding: 0;
      margin: -10px 0 0;
      border: none;
      border-radius: 0;
      background: none;
      box-shadow: none;
      color: colors.$color_primary;
      font-size: variables.$size_normal;
      font-weight: 400;
      line-height: 20px;
      transition: 0.3s;
      z-index: 3;
      -webkit-appearance: none;

      &:hover {
        color: colors.$color_info;
      }
    }

    .fa-search {
      @include mixins.not_room {
        color: colors.$color_white !important;
      }
    }
  }

  .search_btn {
    display: none;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    margin-right: variables.$gap;
    border-radius: 50%;
    background-color: colors.$color_light;
    color: colors.$color_navigation;
    font-size: variables.$size_normal;
    transition: 0.2s;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: colors.$color_action;
    }
  }

  .controls {
    display: flex;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: stretch;
    align-items: stretch;
    margin-left: auto;
  }

  .control {
    display: flex;
    position: relative;
    align-self: stretch;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-left: variables.$gap_s;
    text-align: center;

    &:hover {
      &:before {
        visibility: visible;
        opacity: 1;
      }

      &:after {
        display: block;
      }
    }

    &.login {
      padding-left: variables.$gap_s;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 40px;
        margin-top: -20px;
        background-color: colors.$color_gray;
      }
    }
  }

  .switcher {
    flex-grow: 0;
    flex-shrink: 0;
    margin-left: variables.$gap_s;
  }

  .notify {
    display: block;
    position: relative;
    margin-left: variables.$gap_s;
    font-size: variables.$size_huge;

    &.dot:before {
      content: '';
      display: block;
      position: absolute;
      top: -2px;
      right: -#{variables.$gap_xxxs};
      width: 8px;
      height: 8px;
      border: 2px solid colors.$color_white;
      border-radius: 50%;
      background-color: colors.$color_navigation;
      z-index: 2;
    }
  }

  .user {
    display: flex;
    position: relative;
    align-items: center;
    margin-left: variables.$gap_s;
    cursor: pointer;

    &:hover {
      .links,
      .account {
        visibility: visible;
        opacity: 1;
      }
    }

    .photo {
      display: flex;
      position: relative;
      overflow: hidden;
      flex-grow: 0;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      margin-left: variables.$gap_xs;
      border-radius: 50%;
      background: colors.$color_white 50% 50% no-repeat;
      box-shadow: variables.$shadow_regular;

      @include mixins.night_deep {
        box-shadow: variables.$shadow_regular_night;
      }
    }

    .name {
      display: block;
      overflow: hidden;
      max-width: 70px;
      font-weight: 600;
      white-space: nowrap;
      text-overflow: ellipsis;
      transition: 0.2s;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    a {
      color: colors.$color_primary;

      &:hover {
        color: colors.$color_action;
      }
    }
  }

  .links,
  .account {
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    box-sizing: border-box;
    width: 180px;
    padding: variables.$gap_xs;
    margin-top: variables.$gap_xs;
    border-radius: variables.$radius_small;
    background-color: colors.$color_white;
    box-shadow: variables.$shadow_regular;
    transition: 0.4s;
    visibility: hidden;
    opacity: 0;
    z-index: 5;

    @include mixins.night_deep {
      box-shadow: variables.$shadow_regular_night;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -8px;
      right: 8px;
      width: 0;
      height: 0;
      border-right: 12px solid transparent;
      border-bottom: 12px solid colors.$color_white;
      border-left: 12px solid transparent;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -30px;
      right: 0;
      left: 0;
      height: 24px;
      z-index: 1;
    }
  }

  .links {
    .link {
      display: block;
      position: relative;
      margin-bottom: variables.$gap_xs;
      font-weight: 600;
      transition: 0.2s;

      &:last-child {
        margin-bottom: 0;
      }

      i {
        display: inline-block;
        position: relative;
        margin-right: 5px;
        font-size: variables.$size_normal;
        vertical-align: middle;
      }
    }
  }

  .account {
    width: 250px;
    padding: 0;

    .section {
      display: block;
      position: relative;
      padding: variables.$gap_xs;
      border-bottom: 1px solid colors.$color_gray;

      &:last-child {
        border-bottom: none;
      }
    }

    .profile {
      display: flex;
      align-items: center;
    }

    .photo {
      margin-right: variables.$gap_xs;
      margin-left: 0;
    }

    .login {
      display: block;
      overflow: hidden;
      color: inherit;
      font-weight: 700;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .details {
      display: block;
      position: relative;
      overflow: hidden;
      flex-basis: 0;
      flex-grow: 1;
    }

    a {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: variables.$gap_xs;

      span {
        margin-left: auto;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .navigation-wrapper {
    @include mixins.desktop_small {
      flex: 1;
    }

    @include mixins.phone_large {
      order: 2;
    }
  }

  .sidebar_btn,
  .menu_btn {
    display: none;
    position: relative;
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:before,
    &:after,
    span {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 50%);
      width: 18px;
      height: 2px;
      background-color: colors.$color-support-3;
      transition: 0.2s;

      @include mixins.desktop_small {
        transform: translateX(-50%);
      }

      @include mixins.phone_large {
        left: unset;
        right: 0;
      }

      @include mixins.night {
        background: colors.$night_primary;
      }

      @include mixins.not_room {
        background: colors.$night_primary;
      }
    }

    &_white {
      &:before,
      &:after,
      span {
        background-color: colors.$color_white;
      }
    }

    &:before {
      margin-top: -7px;
    }

    &:after {
      margin-top: 7px;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: variables.$gap_xxxs solid colors.$blue-400;
      outline-offset: variables.$gap_xxxs;
      border-radius: variables.$radius_small;
    }

    @include mixins.desktop_small {
      display: table;
      margin-right: 0;
      min-width: 24px;
    }

    @include mixins.phone_large {
      order: 2;
      margin-right: 0;
    }
  }

  .menu_btn {
    margin-right: 0;
    margin-left: variables.$gap_xs;

    &:before,
    &:after,
    span {
      left: variables.$gap_xs;
      transform: translateX(-50%);
      width: 6px;
      height: 6px;
      margin-top: -3px;
      margin-left: -3px;
      border-radius: 50%;
      background-color: colors.$color_primary;

      @include mixins.phone_large {
        left: unset;
        right: 0;
      }
    }

    &:before {
      margin-top: -#{variables.$gap_s * 0.5};
    }

    &:after {
      margin-top: 6px;
    }
  }

  .sidebar_btn {
    @include mixins.phone_large {
      margin-left: auto;
    }
  }

  .lms-admin & {
    position: fixed;
    width: 100%;
  }

  @include mixins.desktop_small {
    margin-bottom: variables.$gap_s;

    &.is_expanded {
      .actions {
        transform: scaleY(1);
        visibility: visible;
        opacity: 1;
      }
    }

    &.is_search {
      .search {
        transform: scaleY(1);
        visibility: visible;
        opacity: 1;
      }
    }

    .control,
    .user,
    .action {
      margin-right: 0;
      margin-left: variables.$gap_s;
    }

    .control {
      &.login {
        padding-left: variables.$gap_xs;

        &:after {
          height: variables.$gap;
          margin-top: -#{variables.$gap * 0.5};
        }
      }
    }

    .actions {
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      padding: variables.$gap_xxs variables.$gap_xs;
      border-top: 1px solid colors.$color_field;
      border-bottom: 1px solid colors.$color_field;
      background-color: colors.$color_light;
      transition: 0.3s;
      transform: scaleY(0);
      transform-origin: 50% 0;
      visibility: hidden;
      opacity: 0;
    }

    .action {
      &:first-child {
        margin-left: 0;
      }
    }

    .switcher {
      margin-left: auto;

      input + label {
        width: 25px;
        height: 26px;
        padding: 0;
        background: url(/assets/images/ico_darkmode.svg) 0 0 no-repeat;
        color: transparent;
        font-size: 0;
        line-height: 0;
        transition: 0.2s;

        &:before,
        &:after {
          display: none;
        }
      }

      input:checked + label {
        background-image: url(/assets/images/ico_darkmode_active.svg);
      }
    }

    .links,
    .account {
      right: -46px;

      &:before {
        right: 54px;
      }
    }

    .search {
      display: none;
      position: absolute;
      top: 100%;
      max-width: none;
      padding: variables.$gap_xs;
      border-top: 1px solid colors.$color_field;
      border-bottom: 1px solid colors.$color_field;
      background-color: colors.$color_light;
      transition: 0.3s;
      transform: scaleY(0);
      transform-origin: 50% 0;
      visibility: hidden;
      opacity: 0;

      input {
        border-color: colors.$color_field;
        background-color: colors.$color_white;
      }

      button {
        left: variables.$gap;
      }

      .clear {
        right: 30px;
      }

      & + .search_btn {
        display: flex;
      }
    }

    .search_btn {
      width: 20px;
      height: 20px;
      margin-right: 0;
      border-radius: 0;
      background-color: transparent;
      font-size: variables.$size_huge;
    }
  }

  @include mixins.tablet_small {
    .identity {
      .logo {
        margin-right: 0;
      }

      .brand {
        display: none;
      }
    }

    .user {
      .name {
        display: none;
      }

      .photo {
        margin-left: 0;
      }
    }

    .action {
      line-height: 1.25;
      text-align: center;

      i {
        display: block;
        margin: 0 auto 2px;
      }
    }
  }

  @include mixins.phone_small {
    .action,
    .notify,
    .user {
      margin-left: variables.$gap_xs;
    }
  }
}

body[ju_orig_marg] .header_wrapper {
  top: 80px !important;
}
