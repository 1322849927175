@use 'includes/colors';
@use 'includes/variables';

@mixin shadow() {
  position: relative;
  box-shadow: variables.$shadow_regular;
  z-index: 1;

  &:hover {
    box-shadow: variables.$shadow_large;
    z-index: 3;
  }

  &:active {
    box-shadow: variables.$shadow_regular;
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: variables.$gap_xxxs solid colors.$blue-400;
  }

  @include night() {
    box-shadow: variables.$shadow_regular_night;

    &:hover {
      box-shadow: variables.$shadow_large_night;
    }
  }
}

@mixin night() {
  .is_night & {
    @content;
  }
}

@mixin night_deep() {
  .is_night :host ::ng-deep & {
    @content;
  }
}

@mixin not_room() {
  .is_not_room & {
    @content;
  }
}

@mixin not_room_deep() {
  .is_not_room :host ::ng-deep & {
    @content;
  }
}

@mixin button($color) {
  background: $color;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: variables.$gap_xxxs solid colors.$blue-400;
    border-radius: variables.$radius_small;
  }

  &.outlined {
    background-color: transparent;
    border-color: $color;
    color: $color;

    @include night {
      border-color: colors.$color-danube;
      color: colors.$color-danube;
    }

    &.white {
      @include night {
        background-color: transparent;
        border-color: colors.$color-mirage;
        color: colors.$color-mirage;
      }
    }
  }
}

@mixin clear() {
  &:after {
    display: table;
    content: '';
    clear: both;
  }
}

@mixin justify() {
  text-align: justify;
  line-height: 0;
  font-size: 1px;

  &:after {
    display: inline-block;
    content: '';
    width: 100%;
    height: 0;
    visibility: hidden;
    overflow: hidden;
  }
}

@mixin placeholder() {
  &:-ms-input-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }
}

@mixin media($width) {
  @media screen and (max-width: $width + px) {
    @content;
  }
}

@mixin desktop_large() {
  @media screen and (max-width: variables.$desktop_large + px) {
    @content;
  }
}

@mixin desktop_small() {
  @media screen and (max-width: variables.$desktop_small + px) {
    @content;
  }
}

@mixin tablet_large() {
  @media screen and (max-width: variables.$tablet_large + px) {
    @content;
  }
}

@mixin tablet_small() {
  @media screen and (max-width: variables.$tablet_small + px) {
    @content;
  }
}

@mixin phone_large() {
  @media screen and (max-width: variables.$phone_large + px) {
    @content;
  }
}

@mixin phone_small() {
  @media screen and (max-width: variables.$phone_small + px) {
    @content;
  }
}

@mixin dark() {
  .is-dark & {
    @content;
  }
}

@mixin default-box-shadow() {
  box-shadow: 0px 8px 24px 0px rgba(colors.$color-regent-gray, 0.2);

  &:focus,
  &:active {
    box-shadow: 0px 8px 24px 0px rgba(colors.$color-regent-gray, 0.2);
  }

  &:hover {
    box-shadow: 0px 8px 30px 0px rgba(colors.$color-mid-gray, 0.2);
  }

  @include night() {
    box-shadow: 0px 0px 0px 1px colors.$color-mako;

    &:hover {
      box-shadow: 0px 0px 0px 2px colors.$color-tuna;
    }
  }
}

@mixin soft-large-box-shadow() {
  box-shadow: 0px 8px 30px 0px rgba(colors.$color-mid-gray, 0.2);
  transition: box-shadow 0.2s linear;

  &:focus,
  &:active {
    box-shadow: 0px 8px 30px 0px rgba(colors.$color-mid-gray, 0.2);
  }

  &:hover {
    box-shadow: 0px 8px 30px 0px rgba(colors.$color-mid-gray, 0.2);
  }

  @include night_deep() {
    box-shadow: 0px 0px 0px 2px colors.$color-tuna;
  }
}

@mixin app-root() {
  .cfi-lms-theme {
    @content;
  }
}
