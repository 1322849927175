@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.ng-select {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  min-width: 120px;
  padding: 0;
  margin: 0;
  vertical-align: middle;
  transition: 0.3s;
  cursor: pointer;
  z-index: 5;

  &:hover {
    cursor: pointer;
  }

  &.focus-visible {
    &:focus-within {
      outline: variables.$gap_xxxs solid colors.$blue-400;
      border-radius: variables.$radius_small;
    }
  }

  .ng-select-container {
    &:hover {
      cursor: pointer;
    }
  }

  .ng-selected-item {
    max-width: 12ch;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: bottom;
  }

  &.ng-select-opened {
    z-index: 25;

    &.ng-select-bottom > .ng-select-container {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.ng-select-top > .ng-select-container {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    & > .ng-select-container {
      border-color: colors.$color_navigation;
      background-color: colors.$color_focused;

      @include mixins.night {
        border-color: colors.$blue-400;
      }

      .ng-arrow-wrapper {
        .ng-arrow {
          transform: rotate(180deg);
          transition: all 0.2s;
          background-image: url(/assets/images/ico_select_navigation.svg);
        }
      }
    }
  }

  &.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container {
      border-color: colors.$color_navigation;
      background-color: colors.$color_focused;

      @include mixins.night {
        background: colors.$night_dark;
      }
    }

    input {
      &:focus,
      &:focus-visible {
        outline: none !important;
        border-radius: 0 !important;
      }
    }
  }

  &.ng-select-disabled > .ng-select-container {
    background-color: colors.$color-alabaster;
  }

  &.ng-select-single {
    .ng-input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 12px variables.$gap_xs;
    }
  }

  &.ng-select-multiple {
    &.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
      border: 1px solid colors.$color-mercury;
      background-color: colors.$color-alabaster;
    }

    &.ng-select-disabled > .ng-select-container .ng-value-container .ng-value .ng-value-label {
      padding: 0 5px;
    }

    .ng-value-container {
      padding: variables.$gap_xxxs 12px 0px variables.$gap_xs;
    }

    .ng-placeholder {
      top: variables.$gap_xxxs;
      left: variables.$gap_xs;

      & + .ng-input {
        padding-left: 0;
      }
    }

    .ng-value {
      display: inline-flex;
      align-items: center;
      margin-right: variables.$gap_xxxs;
      margin-bottom: variables.$gap_xxxs;
      border-radius: 20px;
      background-color: colors.$color_white;
      color: colors.$color_black;
      font-size: variables.$size_small;
      font-weight: 600;
      line-height: 1.643;
      padding: 6px 12px;

      &.ng-value-disabled {
        background-color: colors.$color_gray;
      }

      &.ng-value-disabled .ng-value-label {
        padding-left: variables.$gap_xxxs;
      }

      .ng-value-label {
        display: inline-block;
        padding: 1px 6px;
      }

      .ng-value-icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        padding: 0;
        margin: 0 2px 0 10px;
        border-radius: 10px;
        background-color: colors.$color_navigation;
        color: colors.$color_white;
        font-size: variables.$size_small;
        font-weight: 400;
        line-height: 1.143;
        text-align: center;
        vertical-align: middle;
        transition: 0.3s;

        &:hover {
          background-color: colors.$color_action;
        }

        &.left {
          margin-left: -1px;
        }

        &.right {
          margin-right: -1px;
        }
      }
    }

    .ng-input {
      padding: 0 0 variables.$gap_xxxs variables.$gap_xxxs;
    }
  }

  &.ng-select-clearable {
    .ng-select-container {
      .ng-clear-wrapper {
        display: flex;
        justify-content: center;

        &:focus {
          outline: none;
        }

        &:focus-visible {
          outline: variables.$gap_xxxs solid colors.$blue-400;
          border-radius: variables.$radius_small;
        }
      }
    }
  }

  .ng-select-container {
    display: block;
    position: relative;
    align-items: center;
    box-sizing: border-box;
    height: auto;
    min-height: 48px;
    border: 1px solid transparent;
    border-radius: variables.$radius_small;
    background-color: colors.$color_field;
    color: colors.$neutral-900;
    font-family: variables.$font_main;
    font-size: variables.$size_normal;
    font-weight: 400;
    line-height: 1.375;
    transition: 0.2s;

    @include mixins.desktop_large {
      font-size: variables.$size_small;
    }

    @include mixins.tablet_large {
      font-size: variables.$size_normal;
    }

    @include mixins.night {
      background: colors.$night_dark;
      color: colors.$night_main;
    }

    .ng-arrow-wrapper {
      position: relative;
      width: 24px;
      padding-right: 11px;
      font-size: 0;
      line-height: 0;
      z-index: 2;

      &:hover .ng-arrow {
        background-image: url(/assets/images/ico_select_hover.svg);
      }

      .ng-arrow {
        display: inline-block;
        position: relative;
        width: 13px;
        height: 8px;
        border: none;
        background: url(/assets/images/ico_select.svg) 50% 50% no-repeat;
        transition: 0.3s;

        @include mixins.night {
          background: url(/assets/images/ico_select_white.svg) 50% 50% no-repeat;
        }
      }
    }
  }

  .ng-value-container {
    position: relative;
    align-items: center;
    padding: 12px variables.$gap_xs;
    z-index: 1;

    @include mixins.desktop_large {
      padding: calc(variables.$gap_s * 0.5);
    }

    @include mixins.tablet_large {
      padding: 12px variables.$gap_xs;
    }
  }

  .ng-clear-wrapper {
    margin-right: 5px;
    color: colors.$color_navigation;
    font-size: variables.$size_huge;
    line-height: 1.1;
    text-align: left;

    @include mixins.night {
      color: colors.$night_primary;
    }

    span.ng-clear {
      font-size: inherit;
      line-height: inherit;
    }

    &:hover .ng-clear {
      color: colors.$color_action;
    }
  }

  .ng-input {
    input {
      display: block;
      position: relative;
      height: auto;
      padding: 0;
      border-radius: 0;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;

      &:hover {
        cursor: pointer !important;
      }
    }
  }

  .ng-has-value .ng-placeholder {
    display: none;
  }

  .ng-spinner-zone {
    padding: 5px 5px 0 0;
  }
}

.ng-dropdown-panel {
  left: 0;
  min-width: 235px;
  border: 0;
  border-radius: 0 0 variables.$radius_small variables.$radius_small;
  background-color: colors.$color_white;
  box-shadow: variables.$shadow_regular;

  @include mixins.night {
    background: colors.$color-mirage;
    box-shadow: variables.$shadow_regular_night;
  }

  &.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: variables.$radius_small;
    border-bottom-left-radius: variables.$radius_small;

    .ng-dropdown-panel-items .ng-option:last-child {
      border-bottom-right-radius: variables.$radius_small;
      border-bottom-left-radius: variables.$radius_small;
    }
  }

  &.ng-select-top {
    bottom: 100%;
    border-top-left-radius: variables.$radius_small;
    border-top-right-radius: variables.$radius_small;

    .ng-dropdown-panel-items .ng-option:first-child {
      border-top-left-radius: variables.$radius_small;
      border-top-right-radius: variables.$radius_small;
    }
  }

  .ng-dropdown-header {
    padding: 5px 7px;
    border-bottom: 1px solid colors.$color_navigation;
  }

  .ng-dropdown-footer {
    padding: 5px 7px;
    border-top: 1px solid colors.$color_navigation;
  }

  .ng-dropdown-panel-items {
    .ng-optgroup {
      padding: variables.$gap_xxs variables.$gap_xs;
      color: colors.$color_main;
      font-weight: 500;
      cursor: pointer;
      user-select: none;

      &.ng-option-disabled {
        cursor: default;
      }

      &.ng-option-marked {
        background-color: colors.$color_navigation;
        color: colors.$color_white;

        @include mixins.night {
          background-color: colors.$blue-400 !important;
        }
      }

      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        background-color: colors.$color_navigation;
        color: colors.$color_white;

        @include mixins.night {
          background-color: colors.$blue-400 !important;
        }
      }
    }

    .ng-option {
      padding: variables.$gap_xxs variables.$gap_xs;
      font-size: variables.$size_small;
      line-height: 1.5;
      transition: 0.2s;

      &.ng-option-selected.ng-option-marked {
        background-color: colors.$color_navigation;
        color: colors.$color_white;

        @include mixins.night {
          background-color: colors.$blue-400 !important;
        }

        .ng-option-label {
          font-weight: 400;
        }
      }

      &.ng-option-marked {
        background-color: colors.$color_navigation;
        color: colors.$color_white;
        font-weight: 600;
      }

      &.ng-option-disabled {
        padding-top: variables.$gap_xs - variables.$gap_xxxs;
        padding-bottom: variables.$gap_xs - variables.$gap_xxxs;
        color: colors.$color_text_2;
      }

      &.ng-option-child {
        padding-left: 22px;
      }

      .ng-tag-label {
        padding-right: 5px;
        font-weight: 400;
      }
    }
  }
}

[dir='rtl'] {
  .ng-select {
    .ng-select-container .ng-value-container {
      padding-right: 10px;
      padding-left: 0;
    }

    &.ng-select-single .ng-select-container .ng-value-container .ng-input {
      padding-right: 10px;
      padding-left: 50px;
    }

    &.ng-select-multiple {
      .ng-value-container {
        padding-right: 7px;
        padding-left: 0;

        .ng-value-container {
          .ng-value {
            margin-right: 0;
            margin-left: 5px;

            &.ng-value-disabled .ng-value-label {
              padding-right: 5px;
              padding-left: 0;
            }

            .ng-value-icon {
              &.left {
                border-right: none;
                border-left: 1px solid colors.$color-french-pass;
              }

              &.right {
                border-right: 1px solid colors.$color-french-pass;
                border-left: 0;
              }
            }
          }

          .ng-input {
            padding: 0 3px 3px 0;
          }
        }
      }

      .ng-select-container .ng-value-container .ng-placeholder {
        padding-right: 3px;
        padding-left: 0;
      }
    }

    .ng-spinner-zone {
      padding: 5px 0 0 5px;
    }

    .ng-arrow-wrapper {
      padding-right: 0;
      padding-left: 5px;
    }
  }

  .ng-dropdown-panel {
    text-align: right;
    direction: rtl;

    .ng-dropdown-panel-items .ng-option {
      &.ng-option-child {
        padding-right: 22px;
        padding-left: 0;
      }

      .ng-tag-label {
        padding-right: 0;
        padding-left: 5px;
      }
    }
  }
}

.ng-select-default-empty-placeholder {
  .ng-placeholder {
    position: relative;
    padding-right: 30px;
    &:after {
      content: 'All';
      font-weight: 700;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
    }
  }

  .ng-select-container.ng-has-value {
    .ng-clear-wrapper {
      color: colors.$color-support-3;

      @include mixins.night {
        color: colors.$night_primary;
      }
    }
    .ng-arrow {
      background: url(/assets/images/ico_select.svg) 50% 50% no-repeat;
    }
  }

  .ng-select-container {
    &.ng-has-value {
      .ng-value-container {
        padding-right: variables.$gap_xxxs;
      }

      .ng-value {
        display: flex;

        .ng-selected-item {
          padding-left: variables.$gap_xxxs;

          @include mixins.tablet_large {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.ng-select-opened {
  .ng-select-container {
    .ng-clear-wrapper {
      color: colors.$color-support-3;

      @include mixins.night {
        color: colors.$night_primary;
      }
    }

    .ng-arrow-wrapper {
      .ng-arrow {
        background: url(/assets/images/ico_select.svg) 50% 50% no-repeat !important;

        @include mixins.night {
          background: url(/assets/images/ico_select_white.svg) 50% 50% no-repeat !important;
        }
      }
    }
  }
}

.ng-select-disabled {
  &.ng-select .ng-select-container .ng-value-container .ng-input > input,
  &.ng-select.ng-select-disabled .ng-arrow-wrapper {
    cursor: not-allowed;
  }

  .ng-value-container:hover,
  .ng-input > input {
    cursor: not-allowed;
    z-index: 2;
  }

  .ng-value-container {
    opacity: 0.5;
  }
}

.ng-select-custom-option {
  text-overflow: ellipsis;
  overflow: hidden;
}

.select-invalid {
  .ng-select-container {
    background-color: colors.$red-100;
  }
}

.ng-select.catalog-group {
  .ng-dropdown-panel {
    .ng-optgroup {
      color: colors.$color-neutral-7;
      background: colors.$color_background_select_group;
      font-weight: 700;
      font-size: variables.$size_small;
    }
  }
}
