@use 'includes/variables';
@use 'includes/mixins';

.end-free-preview {
  h3 {
    margin-bottom: variables.$gap;
    text-align: center;

    @include mixins.tablet_large {
      margin-bottom: variables.$gap_s;
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    @include mixins.desktop_small {
      flex-direction: column;
      align-items: unset;
    }

    @include mixins.tablet_large {
      flex-direction: row;
      align-items: center;
    }

    @include mixins.tablet_small {
      flex-direction: column;
      align-items: unset;
    }

    img {
      flex-shrink: 0;
      margin: 0 variables.$gap 0 0 !important;

      @include mixins.desktop_small {
        align-self: center;
        margin: 0 0 variables.$gap 0 !important;
      }

      @include mixins.tablet_large {
        align-self: unset;
        margin: 0 variables.$gap 0 0 !important;
      }

      @include mixins.tablet_small {
        align-self: center;
        margin: 0 0 variables.$gap 0 !important;
      }
    }

    p {
      margin-bottom: variables.$gap;

      @include mixins.tablet_large {
        margin-bottom: variables.$gap_s;
      }
    }

    .button {
      @include mixins.phone_small {
        width: 100%;
      }
    }
  }
}
