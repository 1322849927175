@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.search {
  .mat-mdc-form-field {
    width: 100%;

    @include mixins.desktop_large {
      margin: 0 variables.$gap_xs;
    }

    @include mixins.tablet_large {
      margin-left: unset;
    }
  }

  .mdc-text-field {
    padding: 0 !important;

    &--filled {
      &:not(.mdc-text-field--disabled) {
        background: transparent;
      }
    }
  }

  .mat-mdc-form-field-infix {
    width: 100% !important;
    min-height: unset !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border: none !important;

    .rounded_search_input {
      padding-right: variables.$gap_xs !important;
      padding-left: variables.$gap_l !important;
      border-radius: 24px !important;
      height: 40px !important;

      @include mixins.desktop_small {
        padding-right: 36px !important;
      }
    }
  }

  .mat-mdc-form-field-focus-overlay,
  .mat-mdc-form-field-subscript-wrapper,
  .mdc-line-ripple {
    display: none;
  }
}

.mat-mdc-autocomplete-panel {
  margin-top: 10px;
  padding: variables.$gap_xxs 0 !important;
  min-height: 496px;
  box-shadow: variables.$shadow_regular !important;
  border-radius: variables.$card-radius !important;

  @include mixins.desktop_small {
    margin-top: unset;
    padding: variables.$gap_xxxs 0 !important;
    min-height: unset;
  }

  @include mixins.night {
    background: colors.$night_background;
  }

  &.autocomplete-list {
    @include mixins.desktop_small {
      margin-left: -#{variables.$gap_s};
      max-height: unset;
      box-shadow: unset !important;
      border-radius: unset !important;
      height: calc(100vh - 125px);
    }
  }
  &.mat-mdc-autocomplete-hidden {
    display: none;
  }
}

.mat-mdc-option {
  @include mixins.desktop_small {
    min-height: 46px !important;
    border-bottom: 1px solid colors.$color-neutral-8;

    @include mixins.night {
      border-color: colors.$color_white;
    }
  }

  .mdc-list-item__primary-text {
    display: flex;
    align-items: center;

    app-svg-icon {
      display: none;
    }

    @include mixins.night {
      color: colors.$color_white;
    }

    @include mixins.desktop_small {
      app-svg-icon {
        display: inline-block;
        margin-right: variables.$gap_xs;
        width: 16px;
        height: 16px;

        &.ico_blue {
          fill: colors.$color-support-3;
          stroke: colors.$color-support-3;

          @include mixins.night {
            fill: colors.$blue-400;
            stroke: colors.$blue-400;
          }
        }
      }
    }
  }

  &:hover:not(.mdc-list-item--disabled),
  &:focus.mdc-list-item,
  &.mat-mdc-option-active.mdc-list-item,
  &.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
    background: colors.$color-support-5 !important;

    @include mixins.night {
      background: colors.$blue-900 !important;
    }

    .mdc-list-item__primary-text {
      color: colors.$color-support-3;

      @include mixins.night {
        color: colors.$blue-400;
      }
    }
  }
}
