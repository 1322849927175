@use 'includes/colors';
@use 'includes/variables';

.jodit {
  ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }

  ol {
    display: block;
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }

  &-wysiwyg {
    em {
      font-style: italic;
    }

    blockquote {
      margin: 0 0 variables.$gap_s variables.$gap_xs;
    }
  }
}
