@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

%menu {
  display: inline-block;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  color: colors.$color_navigation;
  font-size: 18px;
  line-height: 22px;
  vertical-align: middle;
  transition: 0.3s;
  cursor: pointer;

  @include mixins.night {
    color: colors.$blue-400;
  }

  &.button {
    display: inline-flex;
    overflow: visible;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 48px;
    height: auto;
    padding-right: variables.$gap_xxs;
    padding-left: variables.$gap_xxs;
    border: 1px solid;
    background: none;

    .options:before {
      right: 26px;
    }
  }

  &:hover,
  &.hovered-state {
    color: colors.$color_action;

    .options {
      transform: translateY(0);
      visibility: visible;
      opacity: 1;
    }
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: variables.$gap_xxxs solid colors.$blue-400;
    outline-offset: variables.$gap_xxxs;
    border-radius: variables.$radius_small;
  }

  .options {
    display: block;
    position: absolute;
    top: 100%;
    right: -10px;
    left: auto;
    width: max-content;
    max-width: 180px;
    padding: variables.$gap_xxxs 0;
    margin-top: variables.$gap_xxs;
    border-radius: variables.$radius_small;
    background-color: colors.$color_white;
    text-align: left;
    transition: 0.3s;
    transform: translateY(-20px);
    visibility: hidden;
    opacity: 0;
    z-index: 3;
    filter: drop-shadow(0 2px 4px rgba(colors.$color_black, 0.1));

    @include mixins.night {
      background-color: colors.$color-dark-2;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -20px;
      right: 0;
      left: 0;
      height: 20px;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: -6px;
      right: 12px;
      width: 0;
      height: 0;
      margin-left: -6px;
      border-right: 8px solid transparent;
      border-bottom: 6px solid colors.$color_white;
      border-left: 8px solid transparent;

      @include mixins.night {
        border-bottom: 6px solid colors.$color-dark-2;
      }
    }
  }

  .option {
    display: block;
    position: relative;
    padding: variables.$gap_xxxs variables.$gap_xs;
    margin: 0;
    background-color: colors.$color_white;
    color: colors.$color_main;
    font-size: variables.$size_small;
    font-weight: 400;
    line-height: 20px;
    text-decoration: none;
    transition: 0.3s;
    cursor: pointer;
    z-index: 2;

    @include mixins.night {
      background-color: transparent;
      color: colors.$night_main;
    }

    &.selected,
    &:hover {
      background-color: colors.$color_focused;
      color: colors.$color_navigation;

      .lms-admin & {
        background-color: colors.$color-support-5;
      }

      @include mixins.night {
        background-color: colors.$blue-400;
        color: colors.$night_main;

        .icon_alert {
          fill: colors.$night_main;
          stroke: colors.$night_main;
        }
      }
    }

    &:focus-visible {
      z-index: 3;
    }

    &.selected {
      font-weight: 700;
    }

    &.disabled {
      pointer-events: none;
    }

    &.separator {
      height: 1px;
      padding: 0;
      margin: variables.$gap_xxxs 0;
      background-color: colors.$color_gray;
      font-size: 0;
      line-height: 0;
    }

    &.red {
      display: inline-flex;
      align-items: center;
      color: colors.$red-600;

      .icon_alert {
        width: 16px;
        height: 16px;
        margin-right: variables.$gap_xxs;
        fill: colors.$red-600;
        stroke: colors.$red-600;
      }
    }
  }

  .icon {
    display: block;
    position: relative;
    width: 22px;
    height: 22px;
    text-align: center;
    z-index: 1;
  }
}
