@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.loader {
  display: block;
  position: relative;
  width: 24px;
  height: 24px;
  transform: rotate(-90deg);
  z-index: 1;

  circle,
  path {
    fill: none;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-opacity: 0.3;
    stroke-dasharray: 69.11; // (24 - 2) * 3.14 =  ;
  }

  .checkmark {
    transform: rotate(95deg);
    transform-origin: 50% 50%;
    stroke-opacity: 0;
  }

  .fill {
    stroke-opacity: 1;
    stroke-dashoffset: 0;
  }

  &.is_loaded {
    .checkmark {
      stroke-opacity: 1;
    }
  }
}

.is_loading,
.is_loading_clear {
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    border-radius: inherit;
    background: rgba(colors.$color_white, 0.6) url(/assets/images/ico_loader.svg) 50% 50% no-repeat;
    background-size: 64px 64px;
    z-index: 8;

    @include mixins.night {
      background: rgba(colors.$color_black, 0.6) url(/assets/images/ico_loader.svg) 50% 50% no-repeat;
      filter: none;
      z-index: 21;
    }
  }
}

.is_loading_clear {
  &:after {
    background: url(/assets/images/ico_loader.svg) 50% 50% no-repeat;
  }
}
