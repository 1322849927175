@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

/* Styles for the default WYSIWYG editor */

.content {
  display: block;
  position: relative;
  margin-bottom: variables.$gap;
  font-size: variables.$size_large;
  line-height: 1.5;

  @include mixins.desktop_small {
    margin-bottom: variables.$gap_s;
    font-size: variables.$size_normal;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.centered {
    text-align: center;
  }

  @include mixins.clear;

  p,
  & > table,
  .table_wrapper,
  img {
    margin-bottom: variables.$gap_s;

    @include mixins.desktop_small {
      margin-bottom: variables.$gap_xs;
    }
  }

  b,
  strong {
    font-weight: 700;
  }

  small,
  &.small {
    font-size: variables.$size_small;
  }

  .table_wrapper {
    overflow: auto;

    @include mixins.tablet_large {
      display: block;
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  ul,
  ol {
    display: block;
    position: relative;
    margin: 0 0 variables.$gap_s;

    ul,
    ol {
      display: block;
      padding-top: variables.$gap_s;
      margin: 0 0 variables.$gap_s;
    }
  }

  li {
    display: block;
    position: relative;
    padding-left: variables.$gap_s;
    margin-bottom: variables.$gap_xs;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul {
    & > li {
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 11px;
        left: 0;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: colors.$color_navigation;

        @include mixins.desktop_small {
          top: 9px;
        }
      }
    }
  }

  ol {
    counter-reset: list;

    & > li {
      counter-increment: list;

      &:before {
        content: counter(list) '.';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        color: colors.$color_navigation;
        font-weight: 600;
        text-align: left;
      }
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    border: 1px solid colors.$color_gray;
  }

  td,
  th {
    padding: variables.$gap_xxs variables.$gap_xs;
    border-bottom: 1px solid colors.$color_gray;
    text-align: left;
  }

  th {
    background-color: colors.$color_light;
    color: colors.$color_primary;
    font-size: variables.$size_normal;

    & + td {
      border-left: 1px solid colors.$color_gray;
    }

    @include mixins.desktop_small {
      font-size: variables.$size_normal;
    }
  }

  // Styles for blockquotes
  blockquote {
    display: block;
    overflow: hidden;
    padding: variables.$gap_xs variables.$gap_s;
    margin-bottom: variables.$gap_s;
    border-radius: variables.$radius_small;
    background: colors.$color_light;

    @include mixins.desktop_small {
      padding: variables.$gap_xs;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  // Styles for the default gallery shortcode
  .gallery {
    display: flex;
    position: relative;
    overflow: hidden;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 -#{variables.$gap_xs * 0.5} variables.$gap_xs;

    .gallery-item {
      display: inline-block;
      box-sizing: border-box;
      width: 33.33%;
      padding: 0 variables.$gap_xs * 0.5;
      margin: 0 0 variables.$gap_xs;
      vertical-align: top;
    }

    .gallery-icon {
      display: block;
      padding: variables.$gap_xs;
      margin: 0;
      background: colors.$color_light;

      a,
      img {
        display: block;
        width: 100%;
        height: auto;
        padding: 0;
        margin: 0;
      }
    }

    .gallery-caption {
      display: block;
      padding: 0 variables.$gap_xs variables.$gap_xs;
      background: colors.$color_light;
    }

    &-columns-1 .gallery-item {
      width: 100%;
    }

    &-columns-2 .gallery-item {
      width: 50%;
    }

    &-columns-4 .gallery-item {
      width: 25%;
    }

    &-columns-5 .gallery-item {
      width: 20%;
    }

    &-columns-6 .gallery-item {
      width: 16.66%;
    }

    &-columns-7 .gallery-item {
      width: 14.28%;
    }

    &-columns-8 .gallery-item {
      width: 12.5%;
    }

    &-columns-9 .gallery-item {
      width: 11.11%;
    }

    &-columns-6 .gallery-caption,
    &-columns-7 .gallery-caption,
    &-columns-8 .gallery-caption,
    &-columns-9 .gallery-caption {
      display: none;
    }

    @include mixins.tablet_large() {
      &-columns-2 .gallery-item {
        width: 100%;
      }

      &-columns-4 .gallery-item,
      &-columns-5 .gallery-item {
        width: 50%;
      }

      &-columns-6 .gallery-item,
      &-columns-7 .gallery-item {
        width: 33.33%;
      }

      &-columns-8 .gallery-item,
      &-columns-9 .gallery-item {
        width: 25%;
      }
    }

    @include mixins.phone_large() {
      &-columns-3 .gallery-item,
      &-columns-4 .gallery-item,
      &-columns-5 .gallery-item {
        width: 100%;
      }

      &-columns-6 .gallery-item,
      &-columns-7 .gallery-item {
        width: 50%;
      }

      &-columns-8 .gallery-item,
      &-columns-9 .gallery-item {
        width: 33.33%;
      }
    }
  }
}

.text {
  &-left {
    text-align: left !important;
  }
}
