@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

%controls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: content-box;
  min-height: 42px;
  padding: variables.$gap_xxs variables.$gap_s;
  border-bottom: 1px solid rgba(colors.$neutral-300, 0.5);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

  @include mixins.tablet_large {
    flex-wrap: wrap;
    padding-right: variables.$gap_xs;
    padding-left: variables.$gap_xs;
    border-bottom: 1px solid rgba(colors.$color_gray, 0.5);
  }

  &:last-child {
    border-bottom-width: 0;
  }

  &.separate {
    padding: 0;
    margin-bottom: variables.$gap;
    border-bottom-width: 0;

    @include mixins.desktop_small {
      margin-bottom: variables.$gap;
    }

    @include mixins.tablet_large {
      margin-bottom: variables.$gap_xs;
    }

    &:nth-child(2) {
      margin-top: -#{variables.$gap_xxs};

      @include mixins.desktop_small {
        margin-top: 0;
      }
    }

    &.is_loading:after {
      background-color: transparent;
    }

    input,
    textarea,
    select,
    .ng-select .ng-select-container {
      border-color: transparent;
      background-color: colors.$color_white;
      box-shadow: variables.$shadow_small;
      color: colors.$color-neutral-7;

      &.focus-visible {
        &:focus {
          border-color: colors.$color_navigation;
          background-color: colors.$color_focused;

          @include mixins.night {
            border-color: colors.$blue-400;
          }
        }
      }

      @include mixins.night {
        background: colors.$night_dark;
        color: colors.$night_main;
      }

      &:-webkit-autofill {
        @include mixins.night {
          -webkit-box-shadow: 0 0 0 30px colors.$night_dark inset;
        }
      }
    }

    .ng-select.ng-select-opened > .ng-select-container {
      border-color: colors.$color_navigation;
      background-color: colors.$color_focused;

      @include mixins.night {
        background: colors.$night_dark;
      }
    }

    .button-apply {
      display: none;

      @include mixins.tablet_large {
        display: block;
        margin: variables.$gap_xxs 0 variables.$gap_s;
        width: 100%;
      }
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='tel'],
  input[type='search'],
  input[type='number'],
  textarea,
  select {
    padding: variables.$gap_xxs variables.$gap_xs;

    @include mixins.desktop_large {
      font-size: variables.$size_small;
    }

    @include mixins.tablet_large {
      padding: #{variables.$gap_s * 0.5} variables.$gap_xs;
      font-size: variables.$size_normal;
    }
  }

  input,
  textarea,
  select {
    margin-right: variables.$gap_xs;

    &:last-child {
      margin-right: 0;
    }
  }

  .ng-select {
    margin-right: variables.$gap_xs;

    @include mixins.tablet_large {
      margin-right: 0;
    }

    .ng-select-container {
      min-height: 40px;
    }

    .ng-value-container,
    &.ng-select-single .ng-input {
      box-sizing: border-box;
      height: 40px;
      padding-top: 9px;
      padding-bottom: 9px;
    }

    .ng-input input {
      padding: 0;
      font-size: 14px;
    }
  }

  .label {
    display: inline-block;
    margin-right: variables.$gap_xxs;
  }

  .button {
    align-items: center;
    min-height: 40px;
    padding: 8px 16px;
    margin-right: variables.$gap_xs;
    font-size: 16px;
    line-height: 22px;

    &.link {
      padding: 0;
      font-weight: 500;

      &:focus-within {
        outline: variables.$gap_xxxs solid colors.$blue-400;
        border-radius: variables.$radius_small;
      }
    }

    &:last-child {
      margin-right: 0;
      white-space: nowrap;
    }

    &.small {
      flex-shrink: 0;
      font-size: variables.$size_small;
      white-space: nowrap;

      &.is_loading {
        &:after {
          background: transparent url(/assets/images/ico_loader.svg) 50% 50% no-repeat;
          filter: none;

          @include mixins.night {
            background: transparent url(/assets/images/ico_loader.svg) 50% 50% no-repeat;
          }
        }
      }
    }
  }

  .control {
    display: inline-flex;
    align-items: center;
    height: 32px;
    margin-right: variables.$gap_s;
    text-align: left;
    transition: 0.3s;
    cursor: pointer;

    &:hover,
    &.featured {
      color: colors.$color_navigation;

      i {
        color: colors.$color_navigation;
      }
    }

    &.featured:hover {
      color: colors.$color_action;

      i {
        color: colors.$color_action;
      }
    }

    i {
      display: block;
      margin-right: variables.$gap_xxs;
      color: colors.$color_gray;
      font-size: 20px;
      line-height: 1;
      transition: 0.3s;
    }
  }

  .checkbox {
    margin-right: variables.$gap_xs;
    padding: 9px variables.$gap_xs;
    background-color: colors.$color_white;
    box-shadow: variables.$shadow_small;
    border-radius: variables.$radius_small;
    color: colors.$color-neutral-7;

    @include mixins.desktop_large {
      font-size: variables.$size_small;
    }

    @include mixins.tablet_large {
      width: calc(100% - variables.$gap);
      margin-right: 0;
      font-size: variables.$size_normal;
    }

    @include mixins.night {
      background-color: colors.$night_dark;
      color: colors.$night_main;
    }

    label {
      line-height: 1.375;
    }
  }

  .search {
    display: flex;
    position: relative;
    flex-basis: 240px;
    align-items: stretch;
    margin-right: variables.$gap_xs;
    margin-left: auto;

    @include mixins.tablet_large {
      flex-basis: auto;
      width: 100%;
      margin: variables.$gap_xs 0;
    }

    &:first-child {
      margin-left: 0;

      @include mixins.tablet_large {
        margin: 0;
      }
    }

    &:last-child {
      margin-right: 0;

      @include mixins.tablet_large {
        margin-bottom: 0;
      }
    }

    &.has_button {
      input {
        padding-left: 40px;
        margin-right: 0;
        border-radius: 24px;
      }

      button {
        display: block;
        position: absolute;
        top: 50%;
        left: 12px;
        width: 20px;
        height: 20px;
        padding: 0;
        margin: -10px 0 0;
        border: none;
        border-radius: 0;
        background: none;
        box-shadow: none;
        color: colors.$color_navigation;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        transition: 0.3s;
        z-index: 3;

        &:hover {
          color: colors.$color_info;
        }

        &:focus {
          outline: variables.$gap_xxxs solid colors.$blue-400;
          border-radius: variables.$radius_small;
        }

        @include mixins.night {
          color: colors.$night_main;
        }
      }
    }

    &.has_filter {
      input {
        @include mixins.tablet_large {
          padding-right: variables.$gap_l;
        }
      }

      .filter {
        display: none;

        @include mixins.tablet_large {
          display: inline-block;
          left: unset;
          right: variables.$gap_xs;

          &:before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -#{variables.$gap_s * 0.5};
            width: 1px;
            height: 36px;
            background-color: colors.$color-neutral-8;
          }

          app-svg-icon {
            width: 16px;
            height: 16px;
            fill: none;
            stroke: colors.$color-support-3;
          }
        }
      }
    }
  }
}
