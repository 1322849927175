@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.alert_box {
  display: flex;
  position: relative;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: variables.$gap_xs;
  margin-bottom: variables.$gap_xs;
  border: 1px solid;
  border-radius: variables.$radius_small;
  background-color: colors.$color_white;
  color: colors.$orange-700;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  app-svg-icon {
    width: 24px;
    height: 24px;
    margin-right: variables.$gap_xxs;
  }

  @include mixins.desktop_small {
    padding: #{variables.$gap_xs - 4px} variables.$gap_xs;
    margin-bottom: variables.$gap_xs;
  }

  @include mixins.phone_large {
    display: none;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &.secondary {
    color: colors.$blue-700;
  }

  &.success {
    color: colors.$green-700;
  }

  &.danger {
    color: colors.$red-700;
  }

  &.warning {
    color: colors.$yellow-700;
  }

  &.info {
    color: colors.$neutral-800;
    background-color: colors.$color_background_info_notif;
    border-color: colors.$color-congress-blue;

    app-svg-icon {
      fill: colors.$color-congress-blue;
    }
  }

  &.light {
    background-color: colors.$neutral-200;
    color: colors.$neutral-800;
  }

  &.dark {
    background-color: colors.$neutral-900;
    color: colors.$neutral-100;

    &.secondary {
      background-color: colors.$blue-700;
      color: colors.$blue-100;
    }

    &.success {
      background-color: colors.$green-700;
      color: colors.$green-100;
    }

    &.danger {
      background-color: colors.$red-700;
      color: colors.$red-100;
    }

    &.warning {
      background-color: colors.$yellow-700;
      color: colors.$yellow-100;
    }

    &.info {
      background-color: colors.$color_background_info_notif_night;
      border-color: colors.$color-danube;
      color: colors.$color-athens-gray;

      app-svg-icon {
        fill: colors.$color-danube;
      }
    }

    &.light {
      background-color: colors.$neutral-800;
      color: colors.$neutral-200;
    }
  }

  & > i {
    align-self: center;
    margin-right: #{variables.$gap_xs - 4px};
    font-size: 20px;
  }

  .contents {
    display: block;
    position: relative;
    flex-grow: 1;
  }

  b,
  strong {
    font-weight: 700;
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.subscription-notification {
  margin-bottom: 0;

  app-inline-notification {
    .notification_holder {
      align-items: flex-start;
    }
  }
}
