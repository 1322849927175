@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.filelist {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  padding: 0;
  font-size: variables.$size_small;
  font-weight: 400;
  line-height: 20px;

  .filelist_navigation {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    padding: variables.$gap_xs;
    color: colors.$neutral-300;
    font-weight: 400;
  }

  .filelist_crumb {
    display: inline;

    i {
      display: inline-block;
      margin-right: 2px;
    }
  }

  .filelist_separator {
    display: inline-block;
    margin: 0 10px;
  }

  .filelist_items {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
    flex-grow: 1;
    padding: 18px variables.$gap_s;
  }

  .filelist_item {
    display: flex;
    align-items: center;
    padding: variables.$gap_xxs variables.$gap_xs;
    transition: 0.2s;
    cursor: pointer;
    background: colors.$color_white;
    border: 1px solid colors.$neutral-300;
    box-sizing: border-box;
    border-radius: variables.$radius_regular;
    margin-bottom: variables.$gap_xxs;

    &.selected {
      background-color: colors.$color_focused;
      color: colors.$color_navigation;
    }

    &:hover {
      color: colors.$color_navigation;
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: variables.$gap_xxxs solid colors.$blue-400;
      border-radius: variables.$radius_small;
    }

    @include mixins.night {
      background-color: colors.$color-dark-2;
      border-color: colors.$color-dark-2;
    }
  }

  .filelist_item_active {
    background-color: colors.$color-neutral-8 !important;

    @include mixins.night {
      background-color: colors.$color_black !important;
    }
  }

  .filelist_icon {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    min-width: 1em;
    margin-right: variables.$gap_xxs;
    font-size: variables.$size_normal;
    font-weight: 400;
  }

  .filelist_name {
    display: block;
    flex-grow: 1;
    min-width: 0;
  }

  .filelist_buttons {
    flex-grow: 0;
    flex-shrink: 0;
    padding: variables.$gap_xs 0 variables.$gap_xs variables.$gap_xs;
    margin: 0;
    border-top: 1px solid colors.$color_gray;
    font-size: 0;
    line-height: 0;
    text-align: right;
  }

  .button {
    margin: 0 variables.$gap_xs 0 0;
  }
}
