@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.form_box {
  display: block;
  position: relative;

  &.card {
    box-sizing: border-box;
    max-width: 640px;
    padding: variables.$gap;
    border-radius: variables.$radius_small;
    background-color: colors.$color_white;
    box-shadow: variables.$shadow_regular;

    @include mixins.night_deep {
      background-color: colors.$color-mirage;
      box-shadow: variables.$shadow_regular_night;
    }
  }

  &.is_centered,
  .is_centered {
    text-align: center;
  }

  .item {
    &:empty {
      margin-bottom: 0;
    }
  }

  .heading {
    display: flex;
    align-items: center;
    margin-bottom: variables.$gap_xxs;

    .label {
      margin-right: auto;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .label {
    @extend label !optional;
  }

  .field {
    position: relative;

    & + .field {
      margin-top: variables.$gap_xs;
    }

    &.row:last-child {
      margin-bottom: -#{variables.$gap_xs};
    }

    &.has_icon {
      input {
        padding-right: variables.$gap;
      }
    }

    .icons {
      position: absolute;
      top: 13px;
      right: 13px;
      justify-content: flex-end;
    }
  }

  .checkbox,
  .radiobox,
  .switcher {
    align-self: flex-start;
    margin-bottom: variables.$gap_xs;

    &:last-child {
      margin-bottom: 0;
    }

    & + .tipbox {
      margin-top: 0;
      margin-left: 28px;
    }
  }

  .icons {
    display: flex;
    position: relative;
    align-items: center;
    z-index: 5;

    .icon {
      top: 0;
    }

    app-svg-icon {
      display: none;
    }
  }

  .icon {
    display: inline-block;
    position: relative;
    top: -1px;
    height: 24px;
    line-height: 24px;
    vertical-align: middle;
    cursor: pointer;

    &:hover {
      & > i {
        color: colors.$color_navigation;
      }
    }

    & > i {
      font-size: variables.$size_large;
      line-height: 1.333;
    }
  }

  .tip {
    display: block;
    margin-top: variables.$gap_xxs;
    margin-bottom: variables.$gap_xs;
    font-size: variables.$size_small;
    font-weight: 400;
    line-height: 20px;
    word-break: break-word;

    &:first-child {
      margin-top: 0;
    }

    &-right {
      text-align: right;
      margin-bottom: 0;

      @include mixins.tablet_large {
        margin-bottom: variables.$gap_xxs;
      }
    }
  }

  &.form-submitted .error {
    display: block;
    color: colors.$color_danger;

    &:not(.hide) {
      &:first-child {
        margin-top: variables.$gap_xxs;
      }
    }
  }

  .tipbox {
    padding: #{variables.$gap_s * 0.5} variables.$gap_xs;
    margin-top: 10px;
    border-radius: variables.$radius_small;
    background: colors.$color_field;

    &:first-child {
      margin-top: 0;
    }

    .label {
      font-size: variables.$size_small;
    }

    .item {
      margin-top: 15px;
    }

    input:not(.button):not([type='file']),
    .ng-select-container {
      background-color: colors.$color_white;
    }

    p {
      margin-bottom: #{variables.$gap_s * 0.5};

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .content {
    margin-bottom: variables.$gap_s;
    font-size: variables.$gap_xs;

    p {
      margin-bottom: variables.$gap_xs;

      &:last-child,
      &:empty {
        margin-bottom: 0;
      }
    }
  }

  .separator {
    display: block;
    position: relative;
    overflow: hidden;
    margin: -#{variables.$gap_xxs} 0 -#{variables.$gap_s * 0.5};
    color: colors.$color-disabled;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    text-align: center;
    text-transform: uppercase;

    @include mixins.night {
      color: colors.$night_main;
    }

    span {
      display: inline-block;
      position: relative;

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 100%;
        width: 600px;
        height: 0;
        margin-left: variables.$gap_xs;
        border-top: 1px solid colors.$color_gray;

        @include mixins.night {
          border-color: colors.$night_main;
        }
      }

      &:after {
        right: 100%;
        left: auto;
        margin-right: variables.$gap_xs;
        margin-left: 0;
      }
    }
  }

  .preview {
    display: block;
    position: relative;
    margin-bottom: variables.$gap_xs;
    text-align: center;

    &.video {
      position: relative;
      width: 100%;
      overflow: hidden;

      &:before {
        content: '';
        display: block;
        position: relative;
        z-index: 1;
      }

      img,
      iframe {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &.avatar {
      max-width: 250px;
      max-height: 250px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    img {
      display: block;
      width: auto;
      max-width: 100%;
      height: auto;
      border-radius: variables.$radius_small;
    }
  }

  .country-select {
    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          &.ng-option-child {
            padding-left: variables.$gap_xs;
          }
          &:not(.ng-option-child) {
            .ng-option-label {
              font-weight: bold;
            }
          }
        }

        .ng-optgroup {
          &.ng-option-disabled {
            padding: 0;
            border-bottom: 1px solid rgba(colors.$color_gray, 0.5);
            line-height: 0;
            color: transparent;
          }
        }
      }
    }
  }

  @include mixins.desktop_small {
    &.card {
      padding: variables.$gap_s;
    }

    .field {
      &.row {
        margin-top: 0;
        margin-bottom: -#{variables.$gap_xs};
      }
    }

    .content {
      margin-bottom: variables.$gap_xs;
    }

    .buttons {
      margin-top: variables.$gap_xxs;
    }
  }

  @include mixins.tablet_small {
    .heading {
      flex-wrap: wrap;
    }

    .checkbox,
    .radiobox,
    .switcher {
      & + .tipbox {
        margin-left: 0;
      }
    }
  }

  @include mixins.phone_large {
    .separator {
      margin: 10px 0 5px;
    }
  }
}

// Validation Styles
form {
  .error {
    display: none;
  }

  .control-has-error,
  &.ng-submitted.ng-invalid,
  &.form-submitted {
    .error {
      display: block !important;
      text-align: left;
      color: colors.$color_danger;
      margin-top: variables.$gap_xxs;
      font-size: variables.$size_small;
      font-weight: 400;
      line-height: 20px;
      word-break: break-word;
    }

    ng-select.ng-invalid {
      .ng-select-container {
        background: colors.$red-100;
        border-color: colors.$color_danger;
        color: colors.$red-800;

        @include mixins.night {
          background: colors.$red-800;
          color: colors.$red-100;
        }
      }

      .ng-arrow-wrapper {
        padding-right: variables.$gap_xl;
      }
    }

    .ng-select {
      .ng-dropdown-panel.ng-select-bottom {
        box-shadow: variables.$shadow_regular_inverted;
        border-radius: variables.$radius_small !important;

        .ng-dropdown-panel-items .ng-option:last-child {
          border-radius: 0 !important;
        }
      }
    }
  }

  &.ng-submitted.ng-invalid .control-has-error,
  &.form-submitted .control-has-error {
    position: relative;

    &:after {
      content: '';
      display: inline-block;
      width: 25px;
      height: 24px;
      position: absolute;
      bottom: 12px;
      right: 16px;
      background-image: url('/assets/images/ico_alert_triangle.svg');
      z-index: 20;
    }

    textarea,
    textarea:focus,
    input,
    input:focus {
      &.ng-invalid {
        padding-right: variables.$gap_l;
        background: colors.$red-100;
        border-color: colors.$color_danger;
        color: colors.$red-800;

        @include mixins.placeholder {
          color: colors.$red-800;
        }

        @include mixins.night {
          background: colors.$red-800;
          color: colors.$red-100;

          @include mixins.placeholder {
            color: colors.$red-100;
          }
        }
      }
    }

    ng-select {
      .ng-select-container {
        background: colors.$red-100;
        border-color: colors.$color_danger;
        color: colors.$red-800;

        @include mixins.night {
          background: colors.$red-800;
          color: colors.$red-100;
        }
      }

      .ng-arrow-wrapper {
        padding-right: variables.$gap_xl;
      }
    }
  }

  .has_icon {
    .control-has-error {
      &:after {
        right: 40px;
      }
    }
  }

  .disable-control-has-error {
    .control-has-error {
      &:after {
        content: none;
      }
    }

    .ng-invalid {
      .button {
        background: colors.$red-100;
        border-color: colors.$color_danger;
        color: colors.$red-800;

        @include mixins.night {
          background: colors.$red-800;
          color: colors.$red-100;
        }
      }
    }
  }

  .errors-list {
    position: relative;
    background-color: colors.$red-100;
    border-radius: variables.$radius_small;
    text-align: left;
    padding-top: variables.$gap_xs;

    @include mixins.tablet_large {
      order: 3;
    }

    &.show-errors-list {
      margin: 0 variables.$gap_xs variables.$gap variables.$gap_xs;

      @include mixins.tablet_large {
        margin: 0 variables.$gap_xxs variables.$gap_s variables.$gap_xxs;
      }
    }

    &-title {
      display: none;

      &:not(:only-child) {
        display: inline-block;
        margin: 0 variables.$gap_xs variables.$gap_xxs;
        font-size: variables.$size_small;
        text-align: left;
        font-weight: normal;
        color: colors.$color-neutral-7;
      }
    }
  }

  .has-clear {
    position: relative;

    .clear-btn {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 14px;
      height: 14px;
      font-size: 0;
      line-height: 0;
      z-index: 2;
      cursor: pointer;

      &:focus-within {
        outline: variables.$gap_xxxs solid colors.$blue-400;
        outline-offset: variables.$gap_xxxs;
        border-radius: variables.$radius_small;
      }

      .ico_close {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 9px;
        height: 9px;

        &:focus {
          outline: none;
        }
      }

      &:hover {
        .ico_close {
          fill: colors.$color-accent-1;
        }
      }
    }
  }
}

.errors-block {
  margin-bottom: variables.$gap;
  padding: variables.$gap_xs;
  background-color: colors.$red-100;
  border-radius: variables.$radius_small;
  font-size: variables.$size_normal;
  font-weight: 700;
  color: colors.$red-600;
}

.errors-block {
  margin-bottom: variables.$gap;
  padding: variables.$gap_xs;
  background-color: colors.$red-100;
  border-radius: variables.$radius_small;
  font-size: variables.$size_normal;
  font-weight: 700;
  color: colors.$red-600;
}
