@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.image-card {
  .heading + .item {
    margin-top: variables.$gap;
  }

  .item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    &.disabled {
      .thumb {
        opacity: 0.4;
      }

      .title,
      .text {
        color: colors.$color-neutral-3;
      }
    }
  }

  .thumb {
    display: block;
    width: 52px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: variables.$gap_s;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .label {
    display: block;
    color: colors.$color-neutral-4;
    font-size: variables.$size_normal;
    line-height: 1.6;
    flex-grow: 1;
  }

  .info {
    display: block;
    position: relative;
    top: -2px;
  }

  .title {
    display: block;
    font-size: variables.$size_large;
    font-weight: 400;
    line-height: 1.25;
    color: colors.$color-neutral-4;
  }

  .text {
    display: block;
    font-size: variables.$size_tiny;
    margin-top: 2px;
  }

  @include mixins.tablet_small {
    .thumb {
      margin-right: 20px;
    }
  }
}
