@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.user_box {
  .user {
    display: flex;
    position: relative;
    align-items: center;
  }

  .photo {
    display: block;
    overflow: hidden;
    flex-grow: 0;
    flex-shrink: 0;
    width: 240px;
    height: 240px;
    margin-right: variables.$gap;
    border-radius: variables.$radius_small;
    color: colors.$color_white;
    font-size: 56px;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
    text-transform: uppercase;

    img {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      background: colors.$neutral-300;

      @supports (object-fit: cover) {
        height: 100%;
      }
    }
  }

  .info {
    display: block;
    position: relative;
    overflow: hidden;
    flex-grow: 1;

    & > :last-child {
      margin-bottom: 0;
    }
  }

  .name {
    display: block;
    margin-bottom: variables.$gap_xxs;
    color: colors.$color_primary;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.25;
  }

  .action {
    display: block;
    margin-bottom: variables.$gap_xs;
  }

  .content {
    font-size: variables.$size_normal;

    p {
      margin-bottom: variables.$gap_xxs;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .details {
    display: block;
    position: relative;
    margin-bottom: variables.$gap_xs;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .line {
    display: flex;
    align-items: flex-start;
    margin-bottom: variables.$gap_xs;

    &:last-child {
      margin-bottom: 0;
    }

    .label {
      display: block;
      flex-basis: 160px;
      margin-right: 10px;
      color: colors.$color_primary;
      font-weight: 700;
    }
  }

  @include mixins.desktop_small {
    .photo {
      margin-right: variables.$gap_s;
    }

    .name {
      font-size: 28px;
    }

    .action,
    .line {
      margin-bottom: variables.$gap_xxs;
    }
  }

  @include mixins.tablet_large {
    .user {
      display: block;
    }

    .photo {
      width: 240px;
      max-width: 100%;
      height: auto;
      margin: 0 0 variables.$gap_xs;
      border-radius: 0;

      img {
        @supports (object-fit: cover) {
          height: auto;
        }
      }
    }

    .info {
      text-align: center;
    }

    .details {
      text-align: left;
    }

    .line {
      display: block;

      .label {
        margin: 0 0 2px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
