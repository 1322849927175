@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';
@use 'elements/card';

.icon_card {
  align-items: center;
  justify-content: center;
  text-align: center;

  @extend %card !optional;

  .landscape {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .icon {
      flex-grow: 0;
      flex-shrink: 0;
      width: variables.$gap;
      margin-top: -2px;
      margin-right: variables.$gap_xs;
      margin-bottom: 0;
    }

    .title {
      text-align: left;
    }
  }

  .icon {
    display: block;
    position: relative;
    margin-bottom: variables.$gap_xs;
  }

  img {
    width: auto;
    max-width: 100%;
  }

  .title {
    display: block;
    margin-bottom: variables.$gap_xxs;
    color: colors.$color_navigation;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .text {
    display: block;
    margin-bottom: variables.$gap_xs;
    font-size: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include mixins.desktop_small {
    .title {
      font-size: variables.$size_huge;
    }
  }

  @include mixins.tablet_small {
    .title {
      font-size: variables.$size_large;
    }

    .text {
      font-size: 14px;
    }
  }
}

.text-icon {
  border-radius: 50%;
  background-color: colors.$color_navigation;
  color: colors.$color_white;
  width: 24px;
  height: 24px;
  font-size: variables.$size_extra_small;
  line-height: 1.3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon_round_bg {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: colors.$color_white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-level {
  margin-right: variables.$gap_xxs;
  width: 16px;
  height: 16px;

  &.light {
    stroke: colors.$color_white;
  }

  &.dark {
    stroke: colors.$color-neutral-7;

    @include mixins.night {
      stroke: colors.$color_white;
    }
  }
}
