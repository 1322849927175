@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.title_box {
  margin: 0 0 variables.$gap;

  h1,
  h2,
  .heading {
    flex-grow: 1;
    margin: 0;
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h1,
    h2 {
      display: inline;
      flex-grow: unset;
      margin-right: variables.$gap_xxs;
      margin-bottom: 0;
    }

    a {
      margin-right: variables.$gap_xxs;
    }
  }

  .title {
    display: flex;
    align-items: center;

    &.title-s {
      font-size: 24px;
      font-weight: 700;
    }

    &_info {
      margin-top: variables.$gap_s;
    }

    .lms-admin & {
      min-height: 48px;
    }
  }

  .back {
    display: inline-block;
    margin: variables.$gap * 0.5 0;
    font-size: variables.$size_normal;
    font-weight: 700;
    line-height: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .buttons {
    flex-grow: 0;
    flex-shrink: 0;
    text-align: right;

    &:first-child {
      margin-top: 0;
    }
  }

  .users {
    display: flex;
    align-items: center;
    margin-right: variables.$gap;
  }

  .user {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    margin-right: -10px;
    border-radius: 50%;
    background-color: colors.$color_primary;
    color: colors.$color_white;
    font-size: variables.$size_small;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;

    &.info {
      background-color: colors.$color_info;
    }

    &.navigation {
      background-color: colors.$color_navigation;
    }

    &.gray {
      background-color: colors.$color_gray;
      color: colors.$color_main;
    }
  }

  @include mixins.desktop_small {
    margin-bottom: variables.$gap_s;

    .title {
      flex-wrap: wrap;
    }

    .heading,
    h1,
    h2 {
      width: 100%;
      margin: 0;
    }

    .back {
      margin-bottom: variables.$gap_s;
    }

    .buttons,
    .users {
      margin-top: variables.$gap_xs;
      text-align: left;
    }
  }
}
