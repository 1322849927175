@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.uploader-filelist {
  overflow-y: auto;
}

.current-file-preview {
  background: colors.$color_white;
  border: 1px solid colors.$neutral-300;
  border-radius: variables.$radius_regular;
  padding: variables.$gap_xxs variables.$gap_s;
  margin-bottom: 20px;
  box-sizing: border-box;

  @include mixins.night {
    background: colors.$night_background;
    border: none;
  }
}

.current-file-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  font-weight: bold;
  min-height: 48px;

  .image {
    margin-right: 20px;
    padding: 14px;
  }

  .current-file-name {
    i {
      font-size: 25px;
      padding-right: #{variables.$gap_s * 0.5};
    }
  }

  .cdk-drag-handle {
    margin-right: 15px;
    margin-left: -10px;
  }

  .filename-holder {
    flex: 1;
    display: flex;
    align-items: center;
    min-width: 0;
    word-break: break-word;

    @include mixins.phone_large {
      flex-direction: column;
      align-items: flex-start;
    }

    .filename-content {
      width: 100%;
      min-width: 0;
      margin-right: 20px;
      display: flex;
      align-items: center;

      .contents {
        width: 100%;
        overflow: hidden;

        > div,
        > div > div {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .icon {
        margin-right: 20px;

        a {
          color: colors.$neutral-800;
        }
      }

      .caption {
        font-weight: 600;
        font-size: 13px;
      }
    }
  }

  .current-file-cell.buttons_holder {
    margin-left: auto;

    @include mixins.phone_large {
      margin-left: unset;
      margin-top: variables.$gap_xxs;
    }

    .buttons {
      white-space: nowrap;
    }
  }

  .icon-downloadable {
    &:hover {
      &:before {
        content: '\f33d';
      }
    }
  }

  .content-icon {
    margin-top: 2px;
    width: 18px;
    height: 18px;
  }

  audio:focus {
    outline: 0;
  }

  .edit-file-input {
    max-height: 32px;
  }

  .edit-file-button {
    word-break: normal;
    white-space: nowrap;
  }
}
