@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
input[type='search'],
input[type='number'],
input[type='time'],
textarea,
select {
  display: block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 11px variables.$gap_xs #{variables.$gap_s * 0.5};
  border: 1px solid transparent;
  border-radius: variables.$radius_small;
  background: colors.$color_field;
  color: colors.$color-neutral-7;
  font-family: variables.$font_main;
  font-size: variables.$size_normal;
  font-weight: 400;
  line-height: 1.375;
  transition: 0.2s;
  z-index: 2;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  &:-webkit-autofill {
    -webkit-text-fill-color: colors.$color-neutral-7;
    -webkit-background-clip: text;
    box-shadow: 0 0 0 50px colors.$color_field inset;
  }

  &:-webkit-autofill:focus,
  &:-webkit-autofill:hover {
    -webkit-text-fill-color: colors.$color-neutral-7;
    -webkit-background-clip: text;
    box-shadow: 0 0 0 50px colors.$color_focused inset;
  }

  @include mixins.night {
    background: colors.$color-dark-2;
    color: colors.$night_primary;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 50px colors.$color-dark-2 inset;
      -webkit-text-fill-color: colors.$night_primary;
      border-color: colors.$color-dark-2;
    }

    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 50px colors.$night_dark inset;
      -webkit-text-fill-color: colors.$night_primary;
      border-color: colors.$color-support-3;
    }
  }

  &:focus {
    border-color: colors.$color_navigation;
    background-color: colors.$color_focused;
    outline: none;
  }

  &.focus-visible {
    &:focus-visible {
      background: colors.$color_field;
      outline: variables.$gap_xxxs solid colors.$blue-400;
      border-radius: variables.$radius_small;
      border-color: transparent;

      @include mixins.night {
        background: colors.$color-dark-2;
      }
    }
  }

  @include mixins.night {
    border-color: colors.$color-dark-2;
    background-color: colors.$color-dark-2;
  }

  &.small {
    padding: variables.$gap_xxs variables.$gap_xs;
    font-size: variables.$size_small;
  }
}

input[type='checkbox'] {
  &:focus-visible {
    position: relative;
    outline: none !important;
    margin: 0;

    & + label {
      outline: variables.$gap_xxxs solid colors.$blue-400;
      border-radius: variables.$radius_small;
    }
  }
}

input[type='search'],
input[type='search'] {
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
}

input,
textarea {
  @include mixins.placeholder {
    font-size: variables.$size_normal;
    color: colors.$color-neutral-7;
    font-style: normal;
    opacity: 1;

    @include mixins.phone_small {
      font-size: variables.$size_small;
    }

    @include mixins.night {
      color: colors.$night_main;
    }
  }
}

select:not([multiple]) {
  background: colors.$color_field url(/assets/images/ico_select.svg) right 10px top 50% no-repeat;

  &:focus {
    background-image: url(/assets/images/ico_select_hover.svg);
  }

  &::-ms-expand {
    display: none;
  }
}

textarea {
  overflow: auto;
  width: 100%;
  height: 112px;
  resize: none;
}

label {
  display: block;
  position: relative;
  margin-bottom: variables.$gap_xxs;
  color: colors.$color_primary;
  font-size: variables.$size_normal;
  font-weight: 700;
  line-height: 1.5;
  z-index: 3;

  @include mixins.night {
    color: colors.$night_primary;
  }

  .required {
    color: colors.$color_danger;
  }
}

.checkbox,
.radiobox {
  display: block;
  position: relative;
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  line-height: 20px;

  label,
  input + span {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 0;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: 20px;
    cursor: pointer;

    &:empty {
      height: 24px;
      padding-left: variables.$gap_xs;

      .checkbox-rounded & {
        height: 27px;
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 0;
      width: 16px;
      height: 16px;
      border-radius: variables.$radius_small;
      background-color: colors.$color_gray;
      transition: 0.2s;
      z-index: 1;

      @include mixins.night {
        background-color: colors.$neutral-800;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 7px;
      left: 4px;
      width: 8px;
      height: 7px;
      background: url(/assets/images/ico_checkbox.svg) 50% 50% no-repeat;
      background-size: contain;
      transition: 0.2s;
      opacity: 0;
      z-index: 2;
    }
  }

  input {
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    outline: none;

    &:checked + label,
    &:checked + span {
      &:before {
        background-color: colors.$color_navigation;

        @include mixins.night {
          background-color: colors.$blue-400;
        }
      }

      &:after {
        opacity: 1;
      }
    }
  }
}

.checkbox {
  input[type='checkbox'] {
    &:focus,
    &:focus-visible {
      outline-offset: 0px;
      margin: 0;
    }
  }

  &:has(.focus-visible) {
    &:focus-within {
      outline: variables.$gap_xxxs solid colors.$blue-400;
      border-radius: variables.$gap_xxxs;

      input[type='checkbox'] {
        &:focus-visible {
          & + label {
            outline: none;
            border-radius: unset;
          }
        }
      }
    }
  }
}

.checkbox_required {
  input ~ span {
    display: block;
    position: relative;
    padding-left: 28px;
    margin-bottom: 0;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: 20px;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 2px;
      left: 0;
      width: 16px;
      height: 16px;
      border-radius: variables.$radius_small;
      background-color: colors.$color_gray;
      transition: 0.2s;
      z-index: 1;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 7px;
      left: 4px;
      width: 8px;
      height: 7px;
      background: url(/assets/images/ico_checkbox.svg) 50% 50% no-repeat;
      background-size: contain;
      transition: 0.2s;
      opacity: 0;
      z-index: 2;
    }
  }

  input {
    &:checked ~ span {
      &:before {
        background-color: colors.$color_navigation;
      }

      &:after {
        opacity: 1;
      }
    }
  }

  .error {
    form.ng-invalid & {
      margin-top: 0;
    }
  }

  &.control-has-error {
    padding-bottom: variables.$gap_s;

    .error {
      position: absolute;
      bottom: 0;
    }
  }
}

.radiobox {
  display: inline-flex;

  label,
  input + span {
    &:after {
      top: 7px;
      left: 5px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: colors.$color_white;
    }

    &:before {
      border-radius: 50%;
    }
  }

  &:focus {
    outline: none;
  }

  &:has(.focus-visible) {
    &:focus-within {
      outline: variables.$gap_xxxs solid colors.$blue-400;
      border-radius: variables.$radius_small;
    }
  }
}

.switcher {
  display: inline-block;
  position: relative;
  overflow: hidden;

  label,
  input + span {
    display: block;
    position: relative;
    padding-left: 46px;
    margin-bottom: 0;
    color: colors.$color_main;
    font-size: variables.$size_small;
    font-weight: 400;
    line-height: 1.429;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 33px;
      height: 18px;
      margin-top: -#{variables.$gap_xxs};
      border-radius: 9px;
      background-color: colors.$color_gray;
      transition: 0.2s;
      z-index: 1;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 2px;
      width: 14px;
      height: 14px;
      margin-top: -6px;
      border-radius: 50%;
      background-color: colors.$color_navigation;
      transition: 0.2s;
      z-index: 2;
    }
  }

  input {
    display: block;
    position: absolute;
    width: 0;
    height: 0;

    &:checked + label,
    &:checked + span {
      &:before {
        background-color: colors.$color_navigation;
      }

      &:after {
        left: 17px;
        background-color: colors.$color_white;
      }
    }
  }

  span:empty {
    min-height: 20px;
    padding-left: 33px;
  }
}

.checkbox-rounded {
  display: block;
  position: relative;
  margin: 0;
  font-size: inherit;
  font-weight: 400;
  line-height: 20px;

  &:has(.focus-visible) {
    &:focus-within {
      outline: variables.$gap_xxxs solid colors.$blue-400;
      border-radius: variables.$radius_small;
    }
  }

  label,
  input + span {
    display: block;
    position: relative;
    padding-left: variables.$gap_l;
    margin-bottom: variables.$gap_s;
    font-weight: 400;
    line-height: 1.6;
    color: colors.$color-neutral-7;
    cursor: pointer;

    &:empty {
      height: 27px;
      padding-left: variables.$gap_l;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 27px;
      height: 27px;
      background: url(/assets/images/ico_checkmark.svg) 50% 50% no-repeat;
      background-size: contain;
      transition: 0.2s;
      z-index: 1;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 27px;
      height: 27px;
      background: url(/assets/images/ico_checkmark_checked.svg) 50% 50% no-repeat;
      background-size: contain;
      transition: 0.2s;
      opacity: 0;
      z-index: 2;
    }

    @include mixins.night {
      color: colors.$night_primary;
    }
  }

  input {
    display: none;
    position: absolute;
    width: 0;
    height: 0;
    outline: none;

    &:checked + label,
    &:checked + span {
      &:after {
        opacity: 1;
      }
    }
  }
}
