@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';
@use 'elements/card';

.text_card {
  overflow: visible;
  font-size: variables.$size_normal;
  line-height: 1.5;

  @extend %card !optional;

  &.line {
    border-top: 8px solid colors.$color_info;
  }

  &.compact {
    .heading {
      padding: variables.$gap_s variables.$gap;
      margin: -#{variables.$gap} -#{variables.$gap} variables.$gap;
    }

    .progress_diagram {
      &.number_only {
        margin-bottom: 16%;
        span {
          font-size: 45px;
        }
      }
    }
  }

  &.dark {
    background-color: colors.$color_primary;
    color: colors.$color_white;

    .title {
      color: colors.$color_white;
    }

    .button.link {
      color: colors.$orange-400;

      &:hover {
        color: colors.$color_white;
      }
    }
  }

  .heading {
    display: block;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    align-self: stretch;
    box-sizing: border-box;
    padding: variables.$gap_s variables.$gap_l;
    margin: -#{variables.$gap_l} -#{variables.$gap_l} variables.$gap_l;
    background-color: colors.$color_info;
    color: colors.$color_white;
    font-size: variables.$size_small;
    font-weight: 600;
    text-align: right;

    &.image {
      padding: 0;
    }
  }

  .badges {
    display: flex;
    flex-wrap: wrap;
    align-self: stretch;
    align-items: center;
    justify-content: space-between;

    .subtitle {
      margin-right: variables.$gap_xs;
      margin-left: variables.$gap_xxxs;
    }
  }

  .badge {
    margin-bottom: variables.$gap_xs;
  }

  .user {
    display: flex;
    align-items: center;
    margin-bottom: variables.$gap_xs;
    font-weight: 700;

    .title {
      margin: 0;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
    }

    .position {
      display: block;
      color: colors.$color_info;
      font-weight: 500;
    }

    .image {
      display: block;
      position: relative;
      flex-grow: 0;
      flex-shrink: 0;
      width: 50px;
      margin-right: variables.$gap_s;
    }

    img {
      border-radius: 50%;
    }
  }

  .name {
    display: block;
    color: colors.$color_primary;
    font-size: 18px;
    font-weight: 700;
  }

  .title {
    display: block;
    margin-bottom: variables.$gap_xs;
    color: colors.$color_primary;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;

    &.small {
      font-size: 18px;
    }

    .icon {
      display: inline-block;
      max-width: variables.$gap;
      height: auto;
      margin-right: 0.2em;
      vertical-align: middle;
    }

    img {
      display: inline-block;
    }
  }

  .subtitle {
    display: block;
    margin-bottom: variables.$gap_xs;
    color: colors.$color_info;
    font-size: variables.$size_small;
  }

  .text {
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: variables.$gap;
    font-size: 16px;
    line-height: 1.5;
  }

  .pricing {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .price {
    display: block;
    margin-bottom: variables.$gap_xs;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;
  }

  .brand {
    display: block;
    position: absolute;
    top: -#{variables.$gap_s};
    right: variables.$gap_s;
    max-width: 100px;
    height: auto;

    & + .title {
      padding-right: 100px;
    }
  }

  p,
  ul,
  ol {
    margin-bottom: variables.$gap_s;

    &:last-child {
      margin-bottom: 0;
    }
  }

  li {
    margin-bottom: variables.$gap_xxs;

    & > i {
      width: variables.$gap_s;
      margin-right: variables.$gap_xxxs;
      text-align: center;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & > .button:last-child,
  .progress_label,
  .progress_status {
    margin-top: auto;
  }

  .progress_diagram,
  .progress_number {
    margin: auto;

    &:last-child {
      margin-bottom: auto;
    }
  }

  .rating {
    margin-bottom: variables.$gap_xs;
  }

  .progress {
    margin-bottom: variables.$gap;
  }

  .course {
    display: flex;
    align-items: center;
    margin-bottom: variables.$gap_s;

    .icon {
      display: block;
      flex-grow: 0;
      flex-shrink: 0;
      width: 48px;
      margin-right: variables.$gap_xs;
    }
  }

  .achievement {
    display: flex;
    align-items: center;
    margin-bottom: variables.$gap;

    &.disabled {
      opacity: 0.3;
    }

    .icon {
      display: block;
      flex-grow: 0;
      flex-shrink: 0;
      width: 40px;
      margin-right: variables.$gap_s;
    }

    .caption {
      font-size: 12px;
    }
  }

  & > .buttons:last-child {
    margin-bottom: -#{variables.$gap_xs};
  }

  @include mixins.desktop_small {
    .heading,
    &.compact .heading {
      padding: #{variables.$gap_s - 4px} variables.$gap_s;
      margin: -#{variables.$gap_s} -#{variables.$gap_s} variables.$gap_s;
    }

    .title {
      font-size: 20px;
    }

    .text {
      margin-bottom: variables.$gap_s;
      font-size: 16px;
    }

    .user {
      .image {
        margin-right: variables.$gap_xs;
      }
    }

    .brand {
      display: block;
      position: absolute;
      top: -#{variables.$gap_xs};
      right: variables.$gap_xs;
      max-width: 64px;
      height: auto;

      & + .title {
        padding-right: 64px;
      }
    }

    p {
      margin-bottom: variables.$gap_xs;
    }
  }
}
