@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.sidebar_box {
  display: block;
  position: fixed;
  top: variables.$height_header;
  bottom: 0;
  left: 0;
  overflow: hidden;
  box-sizing: border-box;
  width: variables.$width_panel;
  background-color: colors.$color_primary;
  box-shadow: variables.$shadow_regular;
  color: colors.$color_white;
  z-index: 8;
  margin-right: variables.$gap_s;

  @include mixins.night_deep {
    box-shadow: variables.$shadow_regular_night;
  }

  &.dashboard {
    background-color: colors.$color_white;
    box-shadow: variables.$shadow_regular;
    color: colors.$color_primary;

    @include mixins.night {
      background: colors.$color-mirage;
      box-shadow: variables.$shadow_regular_night;
      color: colors.$color_white;
    }
  }

  .scrollbar {
    display: block;
    position: relative;
    overflow-x: hidden;
    height: 100%;
  }

  .wrapper {
    display: flex;
    position: relative;
    overflow: hidden;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    padding: 21px 18px;
  }

  .list_box {
    margin-bottom: variables.$gap;
    margin-left: -18px;

    &:last-child {
      margin-top: auto;
      margin-bottom: 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  @include mixins.desktop_small {
    overflow: hidden;
    width: 60px;
    transition: all 0.4s linear;

    .list_box {
      width: (variables.$width_panel - 18px);
    }

    .wrapper {
      padding-top: variables.$gap_xxxs;
      padding-bottom: variables.$gap_xs;
    }

    &.is_visible {
      width: variables.$width_panel;
    }
  }

  @include mixins.tablet_large {
    left: 0;
    width: 60px;

    &.is_visible {
      width: variables.$width_panel;
    }
  }

  @include mixins.phone_large {
    left: -#{variables.$width_panel};
    width: variables.$width_panel;

    &.is_visible {
      left: 0;
    }
  }
}
