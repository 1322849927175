@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';
@use 'elements/tabs';

.tabs_box {
  display: block;
  position: relative;
  margin: 0 0 variables.$gap_s;
  width: 100%;
  min-height: 52px;
  box-sizing: border-box;

  @include mixins.tablet_large {
    margin: 0 -#{variables.$gap_s} variables.$gap_s;
    padding-left: variables.$gap_s;
    padding-right: variables.$gap_s;
    width: calc(100% + #{variables.$gap_l});
  }

  [data-tab-sections] {
    display: block;
    position: relative;
    transition: height 0.2s;
    z-index: 1;

    & > .section {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      transition: 0.5s;
      visibility: hidden;
      opacity: 0;
      z-index: 1;

      &.active {
        visibility: visible;
        opacity: 1;
        z-index: 2;
      }
    }
  }

  [data-tabs] {
    @extend %tabs !optional;
  }

  .button {
    &.circle {
      position: absolute;
      top: 0;
      z-index: 4;

      &.next {
        right: variables.$gap;
      }

      &.prev {
        left: variables.$gap;
      }
    }
  }

  &.no-mobile-view-tabs {
    margin: 0 -#{variables.$gap_s} variables.$gap_s;
    padding: 0 variables.$gap_s;
    width: calc(100% + #{variables.$gap_l});

    [data-tabs] {
      position: relative;
      margin: -14px 0 variables.$gap;

      @include mixins.tablet_large {
        margin-bottom: variables.$gap_s;

        & > .tab {
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }

  &_full_height {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: auto;
    min-height: 100%;
  }
}

.tabs_box_full-width {
  display: block;
  position: relative;
  margin: 0;

  [data-tab-sections] {
    display: block;
    position: relative;
    transition: height 0.2s;
    z-index: 1;

    & > .section {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      transition: 0.5s;
      visibility: hidden;
      opacity: 0;
      z-index: 1;

      &.active {
        visibility: visible;
        opacity: 1;
        z-index: 2;
      }
    }
  }

  [data-tabs] {
    @extend %tabs_full-width !optional;
  }
}
