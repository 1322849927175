@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';
@use 'elements/card';

.chapter_card {
  box-sizing: border-box;
  display: block;
  position: relative;
  padding: 28px variables.$gap_xs;
  margin-bottom: variables.$gap_xs;
  min-width: 360px;
  @extend %card !optional;

  @include mixins.desktop_small {
    min-width: unset;
  }

  & > .drag-handle {
    position: absolute;
    top: variables.$gap_xs * 1.9;
    left: variables.$gap_xs;
    z-index: 2;

    & + * {
      position: relative;
      z-index: 1;

      .heading {
        padding-left: 28px;
      }
    }
  }

  .card {
    display: block;
    position: relative;

    &.is_expanded {
      .icon {
        transform: scaleY(-1);
      }

      .details {
        display: block;
      }
    }
  }

  .heading {
    display: flex;
    align-items: center;

    & > :last-child {
      margin-right: 0;
    }
  }

  .title {
    display: block;
    flex-grow: 1;
    margin-right: variables.$gap_xxs;
    color: colors.$color_primary;
    font-weight: 700;

    a {
      &:hover {
        text-decoration: none;
        color: colors.$color_navigation;
      }
    }
  }

  .drag-handle {
    margin-right: variables.$gap_xxs;
  }

  .icon {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      color: colors.$color_navigation;
    }
  }

  .details {
    display: none;
    position: relative;
    padding-top: variables.$gap_xs;
  }

  .lessons {
    display: block;
    position: relative;

    &:last-child {
      margin-bottom: -#{variables.$gap_xs};
    }
  }

  .lesson {
    display: flex;
    position: relative;
    align-items: center;
    padding: (variables.$gap_xs - variables.$gap_xxxs);
    margin-bottom: variables.$gap_xs;
    border: 1px solid colors.$color_gray;
    border-radius: variables.$radius_regular;

    &.is_active {
      border-color: colors.$color_navigation;
    }
  }

  .caption {
    position: relative;
    display: block;
    flex-grow: 1;
    color: inherit;
    font-weight: 600;

    a {
      display: block;

      &:hover {
        text-decoration: none;
        color: colors.$color_navigation;
      }
    }
  }

  .card {
    &.is_active {
      &:before {
        @include mixins.desktop_large {
          margin-left: 22px;
        }

        @include mixins.desktop_small {
          display: none;
        }
      }
    }
  }
}

.button_new_chapter {
  border: 2px dashed colors.$color_navigation;

  &:active {
    outline: 0;
  }

  &:hover {
    cursor: pointer;

    a,
    .icon {
      color: colors.$color_action;
    }
  }

  .heading {
    padding-left: 28px;
  }

  .icon {
    color: colors.$color_navigation;
  }
}
