@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.bottom_box {
  display: block;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 1188px;
  padding: variables.$gap 0 variables.$gap_s;
  margin: auto auto 0;
  font-size: variables.$size_small;
  line-height: 20px;
  z-index: 2;

  @include mixins.desktop_large {
    padding-left: 0;
  }

  .container {
    display: flex;
    align-items: center;

    @include mixins.desktop_large {
      flex-direction: column-reverse;
    }

    @include mixins.phone_large {
      align-items: flex-start;
    }
  }

  .copyright {
    margin-right: 20px;

    @include mixins.desktop_large {
      margin-right: 0;
    }
  }

  .menu {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;

    @include mixins.desktop_large {
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 0 variables.$gap_s;
    }

    @include mixins.phone_large {
      justify-content: flex-start;
    }

    li {
      display: block;
      position: relative;
      padding-right: 9px;
      margin-right: variables.$gap_xxs;
      text-align: center;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        width: 0;
        height: 14px;
        margin-top: -7px;
        border-right: 1px solid colors.$color_navigation;

        @include mixins.night {
          border-right: 1px solid colors.$color-danube;
        }

        @include mixins.tablet_large {
          margin-top: -5px;
        }

        @include mixins.phone_large {
          border-right: 1px solid colors.$color-neutral-7;
        }
      }

      &:last-child {
        padding-right: 0;
        margin-right: 0;

        &:after {
          display: none;
        }
      }
    }

    a {
      color: colors.$color_navigation;

      &:hover {
        color: colors.$color_navigation;
      }

      @include mixins.night {
        color: colors.$color-danube;
      }
    }
  }
}
