@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

html,
body {
  min-height: 100%;
  scroll-behavior: smooth;
}

.cdk-global-scrollblock {
  position: initial !important;
  width: initial !important;
  overflow: hidden !important;
}

#site {
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  justify-content: stretch;
  min-height: 100vh;
}

.main_box {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: stretch;
  width: 100%;
  background: colors.$color_light;
  z-index: 5;
}

section {
  display: block;
  position: relative;
  margin-bottom: variables.$gap_xl;
  z-index: 2;

  &:after {
    content: '';
    display: table;
    overflow: hidden;
    width: 100%;
    height: 0;
    visibility: hidden;
  }

  .header_box + & {
    margin-top: variables.$gap_l;
  }

  @include mixins.desktop_large {
    margin-bottom: variables.$gap_l;

    .header_box + & {
      margin-top: variables.$gap;
    }
  }

  @include mixins.desktop_small {
    margin-bottom: variables.$gap;

    .header_box + & {
      margin-top: variables.$gap_s;
    }
  }
}

.has_background {
  padding: variables.$gap_xl 0;
  background: colors.$gradient;
  color: colors.$color_white;

  &:last-child {
    margin-bottom: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: inherit;
  }

  .header_box + & {
    margin-top: 0;
  }

  .has_background + & {
    margin-top: -#{variables.$gap_xl};
  }

  @include mixins.desktop_large {
    padding: variables.$gap_l 0;

    .has_background + & {
      margin-top: -#{variables.$gap_l};
    }
  }

  @include mixins.desktop_small {
    padding: variables.$gap 0;

    .has_background + & {
      margin-top: -#{variables.$gap};
    }
  }
}

.grecaptcha-badge {
  display: none !important;
}

.hidden {
  display: none;

  &-large-desktop {
    @include mixins.desktop_large {
      display: none;
    }
  }

  &-tablet {
    @include mixins.tablet_large {
      display: none;
    }

    &-large {
      @include mixins.tablet_large {
        display: none;
      }
    }
  }

  &-small-phone {
    @include mixins.phone_large {
      display: none;
    }
  }
}

.visible {
  &-large-desktop {
    display: none;

    @include mixins.desktop_large {
      display: block;
    }
  }

  &-tablet {
    display: none;

    @include mixins.tablet_large {
      display: block;
    }

    &-large {
      display: none;

      @include mixins.tablet_large {
        display: block;
      }

      @include mixins.tablet_small {
        display: none;
      }
    }

    &-small {
      display: none;

      @include mixins.tablet_small {
        display: block;
      }
    }
  }

  &-phone {
    display: none;

    @include mixins.phone_large {
      display: block;
    }
  }
}

.sidebar-active {
  overflow: hidden;
}

.is-disabled-link {
  pointer-events: none;
}

#beacon-container {
  .hsds-beacon {
    .BeaconFabButtonFrame {
      margin-bottom: variables.$gap_xl;
    }

    .BeaconContainer {
      &:not(.is-mobile) {
        bottom: 180px;
        height: calc(100% - 190px);

        @media (max-height: 740px) {
          bottom: 150px;
          height: calc(100% - 160px);
        }
      }
    }
  }

  .helpscout-hidden & {
    display: none !important;
  }
}

.in_player {
  main + app-footer-box {
    display: none;
  }
}

a {
  cursor: pointer;
}

.scroll-disabled {
  overflow: hidden;
  height: auto;
}

.not-night-image {
  @include mixins.night {
    display: none;
  }
}

.night-image {
  display: none;

  @include mixins.night {
    display: block;
  }
}

.set_centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cky-btn-revisit-wrapper {
  position: fixed !important;
}
