@keyframes slideUPTextBefore {
  0% {
    top: 0;
  }
  100% {
    top: -100px;
  }
}

@keyframes slideUPTextAfter {
  0% {
    bottom: -100%;
  }
  100% {
    bottom: 0;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.85);
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.85);
    opacity: 0;
  }
}

@keyframes slideOneLeftActive {
  0% {
    opacity: 0;
    transform: translateX(110%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideOneLeftInactive {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-110%);
  }
}

@keyframes slideTwoLeftActive {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideTwoLeftInactive {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-110%);
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    left: 100%;
  }
  5% {
    opacity: 1;
    left: 50%;
  }
  95% {
    opacity: 1;
    left: 50%;
  }
  100% {
    opacity: 0;
    left: -100%;
  }
}

@keyframes IconChange {
  25% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
}
