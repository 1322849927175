@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.search_bar {
  display: block;
  position: relative;

  input[type='text'] {
    padding-left: variables.$gap_l;
    border-radius: 24px;
    background-color: colors.$color_white;
    box-shadow: variables.$shadow_small;

    @include mixins.desktop_small {
      padding-left: 40px;
    }

    &:focus {
      & ~ button {
        color: colors.$color_info;
      }
    }
  }

  button {
    display: block;
    position: absolute;
    top: 14px;
    left: 18px;
    width: 20px;
    height: 20px;
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 0;
    background: none;
    box-shadow: none;
    color: colors.$color_navigation;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    transition: 0.3s;
    z-index: 3;
    -webkit-appearance: none;

    &:hover {
      color: colors.$color_info;
    }

    @include mixins.desktop_small {
      top: 10px;
      left: 14px;
    }
  }
}
