@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  padding: 0;
  margin: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  display: block;
  position: relative;
  min-width: 320px;
  background: colors.$color-neutral-1;
  color: colors.$color-neutral-7;
  font-family: variables.$font_main;
  font-size: variables.$size_normal;
  font-weight: 400;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  &.is_night {
    background: colors.$night_background;
    color: colors.$night_main;
  }
}

sup {
  font-size: small;
  vertical-align: super;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

.cfi-lms-theme {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    position: relative;
    margin: 0 0 #{variables.$gap_xs + variables.$gap_xxxs};
    color: colors.$color_primary;
    font-family: variables.$font_title;
    font-weight: 700;
    line-height: 1.25;
    word-break: break-word;

    i {
      color: colors.$color_info;
    }

    &:last-child {
      margin-bottom: 0;
    }

    @include mixins.night {
      color: colors.$night_primary;
    }
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  @include mixins.desktop_small {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: variables.$gap_xs;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 18px;
    }

    h5 {
      font-size: 17px;
    }
  }
}

ol,
ul {
  list-style-position: inside;
}

p {
  margin: 0 0 variables.$gap_s;

  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: colors.$color_navigation;
  text-decoration: none;
  transition: 0.2s;

  &:hover {
    text-decoration: underline;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: variables.$gap_xxxs solid colors.$blue-400 !important;
    border-radius: variables.$radius_small;
    z-index: 2;
  }

  @include mixins.night {
    color: colors.$blue-400;
  }
}

b,
strong,
.bold {
  font-weight: 700;
}

audio {
  outline: 0;
}

em {
  background-color: colors.$yellow-200 !important;

  @include mixins.night {
    background-color: colors.$orange-800 !important;
  }
}
