@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.tooltip_box {
  display: block;
  position: relative;
  max-width: 460px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: colors.$color_white;
  @include mixins.shadow;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    color: colors.$color_white;
    z-index: 2;
  }

  &.top {
    &:before {
      left: 50%;
      margin-left: -#{variables.$gap_xxs};
      border-top: variables.$gap_xxs solid;
      border-right: variables.$gap_xxs solid transparent;
      border-left: variables.$gap_xxs solid transparent;
    }
  }

  &.bottom {
    &:before {
      top: -#{variables.$gap_xxs};
      left: 50%;
      margin-left: -#{variables.$gap_xxs};
      border-right: variables.$gap_xxs solid transparent;
      border-bottom: variables.$gap_xxs solid;
      border-left: variables.$gap_xxs solid transparent;
    }
  }

  &.left {
    &:before {
      top: 50%;
      left: -#{variables.$gap_xxs};
      margin-top: -#{variables.$gap_xxs};
      border-top: variables.$gap_xxs solid transparent;
      border-right: variables.$gap_xxs solid;
      border-bottom: variables.$gap_xxs solid transparent;
    }
  }

  &.right {
    &:before {
      top: 50%;
      right: -#{variables.$gap_xxs};
      margin-top: -#{variables.$gap_xxs};
      border-top: variables.$gap_xxs solid transparent;
      border-bottom: variables.$gap_xxs solid transparent;
      border-left: variables.$gap_xxs solid;
    }
  }

  .slider {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    font-size: 0;
    line-height: 0;
    white-space: nowrap;
  }

  .slide {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    padding: variables.$gap;
    font-size: 16px;
    line-height: 1.5;
    white-space: normal;
  }

  .heading {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: variables.$gap_xs;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .title {
    display: block;
    color: colors.$color_primary;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.25;
  }

  .close {
    display: block;
    margin-left: auto;
    color: colors.$color_text_2;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      color: colors.$color_action;
    }
  }

  @include mixins.desktop_small {
    .slide {
      padding: variables.$gap_s;
    }

    .title {
      font-size: 20px;
    }

    .close {
      font-size: 12px;
    }
  }
}
