@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.external_webhook_box {
  .webhook {
    display: flex;
    position: relative;
    align-items: center;
  }

  .info {
    display: block;
    position: relative;
    overflow: hidden;
    flex-grow: 1;

    & > :last-child {
      margin-bottom: 0;
    }
  }

  .content {
    font-size: variables.$size_normal;

    p {
      margin-bottom: variables.$gap_xxs;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .details {
    display: block;
    position: relative;
    margin-bottom: variables.$gap_xs;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .line {
    display: flex;
    align-items: flex-start;
    margin-bottom: variables.$gap_xs;

    &:last-child {
      margin-bottom: 0;
    }

    .label {
      display: block;
      flex-basis: 160px;
      margin-right: 10px;
      color: colors.$color_primary;
      font-weight: 700;
    }
  }

  @include mixins.desktop_small {
    .action,
    .line {
      margin-bottom: variables.$gap_xxs;
    }
  }

  @include mixins.tablet_large {
    .user {
      display: block;
    }

    .info {
      text-align: center;
    }

    .details {
      text-align: left;
    }

    .line {
      display: block;

      .label {
        margin: 0 0 2px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
