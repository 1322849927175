@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';

.step {
  max-width: 637px !important;
  margin-bottom: variables.$gap;
  animation-name: slideOneLeftActive;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;

  @include mixins.desktop_large {
    max-width: unset !important;
  }

  &_number {
    margin-bottom: variables.$gap_xxs;
    font-size: variables.$size_small;
    font-weight: bold;
  }

  &_title {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    @include mixins.tablet_small {
      flex-wrap: wrap;
      flex-direction: column;
    }

    h3 {
      display: flex;
      align-self: flex-start;
      margin-bottom: 0 !important;

      &:not(:only-child) {
        margin-right: variables.$gap_xxs;
      }
    }

    app-svg-icon[icon='course_complete'] {
      width: 20px;
      height: 20px;
      fill: colors.$color-accent-2;
    }

    .caption {
      margin-bottom: 0 !important;
      font-size: variables.$size_small;

      @include mixins.phone_large {
        margin-top: variables.$gap_xxs;
        text-align: left !important;
      }
    }

    .cards_wrapper {
      display: flex;
      align-items: center;

      img {
        margin: 0 variables.$gap_xxs variables.$gap_xxs 0;

        @include mixins.tablet_small {
          margin: variables.$gap_xs variables.$gap_xxs variables.$gap_xs 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &.not-wrapped {
      align-items: center;

      @include mixins.tablet_small {
        flex-wrap: nowrap;
        flex-direction: row;
      }
    }
  }
}
