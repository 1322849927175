@use 'includes/colors';
@use 'includes/variables';
@use 'includes/mixins';
@use 'elements/heading';
@use 'elements/menu';

.curriculum_box {
  margin-bottom: -#{variables.$gap_xl};
  padding-bottom: variables.$gap;
  box-sizing: border-box;

  @include mixins.desktop_large {
    margin-bottom: -#{variables.$gap_l};
  }

  @include mixins.desktop_small {
    margin-bottom: -#{variables.$gap};
    padding-bottom: variables.$gap_xs;
  }

  & > .wrapper {
    position: relative;
    display: flex;
    align-items: stretch;
  }

  .chapters {
    display: block;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    width: 360px;
    min-height: calc(100vh - 222px);
    margin-right: variables.$gap;
    z-index: 1;

    @include mixins.tablet_large {
      min-height: unset;
    }
  }

  .curriculum {
    display: block;
    position: relative;
    flex-grow: 1;
    min-width: 0;
    padding: variables.$gap;
    border-radius: variables.$radius_regular;
    background-color: colors.$color_white;
    box-shadow: variables.$shadow_regular;
    z-index: 1;

    @include mixins.night_deep {
      box-shadow: variables.$shadow_regular_night;
    }

    &_heading {
      @extend %heading !optional;
    }
  }

  .menu {
    @extend %menu !optional;
  }

  @include mixins.desktop_large {
    .curriculum {
      padding: variables.$gap_s;
    }
  }

  @include mixins.desktop_small {
    & > .wrapper {
      display: block;
    }

    .chapters {
      width: 100%;
      padding-right: 0;
      margin-right: 0;
      margin-bottom: variables.$gap_s;
      border-right-width: 0;
    }

    .curriculum {
      padding: variables.$gap_xs;
      margin-bottom: variables.$gap_xs;
    }
  }

  .drop-area {
    display: none;
    background-color: colors.$blue-100;
    border-radius: variables.$radius_regular;
    opacity: 0.4;
    position: absolute;
    right: 0;
    top: 0;
    width: 326px;
    height: 48px;
    &.cdk-drop-list-dragging {
      background: colors.$teal-700;
    }
    tr {
      border: 1px solid black;
      position: absolute;
    }
    .drop-icon {
      position: absolute;
      right: 5px;
      top: 16px;
      color: colors.$cyan-900;
    }
  }
}
